import { useState } from 'react'
import './css/alert.css'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import { ProgressBar } from 'react-bootstrap'
export function Info({ msg, progress }) {
    const [show, setShow] = useState(true)
    const close = () => {
        setShow(false)
    }
    const variant = () => {
        if (progress <= 50) {
            return 'warning'
        }
        if (progress >= 50 && progress <= 75) {
            return 'info'
        }
        else return 'success'
    }
    return (
        show && <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>
            <div className='alert-info-container'>
                <i onClick={close} className='alert-icon' ><FontAwesomeIcon size='sm' icon={faTimes} /></i>
                <div className='alert-msg'>
                    {msg}
                    {progress && <ProgressBar variant={variant()} style={{ margin: 10 }} now={progress} label={`${progress}%`} />}

                </div>
            </div>

        </div>
    )
}
export function Success({ msg }) {
    const [show, setShow] = useState(true)
    const close = () => {
        setShow(false)
    }
    return (
        show && <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>

            <div className='alert-success-container'>
                <i onClick={close} className='alert-icon' ><FontAwesomeIcon size='sm' icon={faTimes} /></i>

                <div className='alert-msg'>
                    {msg}
                </div>
            </div>
        </div>
    )
}
export function Warning({ msg }) {
    const [show, setShow] = useState(true)
    const close = () => {
        setShow(false)
    }
    return (
        show && <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>

            <div className='alert-warning-container'>
                <i onClick={close} className='alert-icon' ><FontAwesomeIcon size='sm' icon={faTimes} /></i>

                <div className='alert-msg'>
                    {msg}
                </div>
            </div>
        </div>
    )
}
export function Error({ msg }) {
    const [show, setShow] = useState(true)
    const close = () => {
        setShow(false)
    }
    return (
        show && <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>

            <div className='alert-danger-container'>
                <i onClick={close} className='alert-icon' ><FontAwesomeIcon size='sm' icon={faTimes} /></i>

                <div className='alert-msg'>
                    {msg}
                </div>
            </div>
        </div>
    )
}

export function Alert({ msg, type, toast }) {
    const Toast = Swal.mixin({
        customClass: {
            title: 'confirm-title'
        },
        toast: toast,
        position: toast ? 'top-end' : 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    return Toast.fire({
        icon: type,
        title: msg,

    })
}

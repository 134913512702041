import "./css/cards.css";
import { useState } from "react";
import { Row, Col, Container, Card, Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TextCardTitle,
  TextCardSubtitle,
  TextToolbar,
  TextRegSub,
  TextRF,
  TextRFSub,
} from "./index";
import {
  faDollarSign,
  faMapMarkerAlt,
  faDesktop,
  faUser,
  faCalendarDay,
  faCommentAlt,
  faDotCircle,
  faTimes,
  faDownload,
  faSyncAlt,
  faThLarge,
  faList,
  faCaretLeft,
  faBuilding,
  faCheckCircle,
  faTimesCircle,
  faGlobe,
  faDoorOpen,
  faBus,
  faCalendarWeek,
  faBriefcase,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { downloadFile } from "../Api/index";
import { Fade, Pages } from "../Components";
import {
  faFacebookF,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import AdminLogo from "../Assets/imgs/splachLogo.webp";
import Job from "../Assets/imgs/jobs.webp";
import Org from "../Assets/imgs/organization.webp";
import Company from "../Assets/imgs/company.webp";
import Article from "../Assets/imgs/default_article.webp";
import { Link, useNavigate } from "react-router-dom";

export function Carder(props) {
  const { onClick, data } = props;
  const { icon, title, subTitle } = data;
  return (
    <div className="card" onClick={onClick}>
      <i>
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: "34px", marginBottom: "7%" }}
        />
      </i>
      <h3 className="card-title">{title && title}</h3>
      <h5 className="card-subtitle">{subTitle && subTitle}</h5>
    </div>
  );
}
//was Card

export function ServicesCard(props) {
  const { onClick, data } = props;
  const { icon, title, subTitle } = data;

  return (
    <div className="serv-card" onClick={onClick}>
      <Row>
        <Col xs="2">
          <i>
            <FontAwesomeIcon icon={icon} style={{ marginBottom: 7 }} />
          </i>
        </Col>
        <Col>
          <div className="serv-card-title">{title && title}</div>

          <div className="serv-card-subtitle">{subTitle && subTitle}</div>
        </Col>
      </Row>
    </div>
  );
}

export function AdviceCard(props) {
  const { data, lang, page } = props;

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={page}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <Row>
          {data.map((advice, index) => {
            const { title, subtitle, img, name, date } = advice;
            return (
              <Col lg="4" md="12" xs="12" className="p-2" key={index}>
                <div className="advice-card-container">
                  <div className="advice-card-img">
                    <img src={img} alt={""} />
                  </div>
                  <div className="advice-card-content">
                    <Row>
                      <div xs="1" sm="1" md="1" lg="1">
                        <FontAwesomeIcon
                          style={{ marginRight: 10, marginLeft: 10 }}
                          color="#ea6969"
                          icon={faCalendarDay}
                        />
                      </div>
                      <div className="advice-card-date">
                        {lang.publishDate} {date}
                      </div>
                    </Row>
                    <div className="advice-card-title">{title}</div>
                    <div className="advice-card-subtitle">{subtitle}</div>
                  </div>
                  <div className="advice-card-footer">
                    <div className="d-flex align-items-center">
                      <img
                        className="advice-card-footer-image"
                        src={img}
                        alt={name}
                      />
                      {name}
                    </div>

                    <FontAwesomeIcon
                      icon={faCommentAlt}
                      color="#ea6969"
                      size="sm"
                    />
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </CSSTransition>
    </SwitchTransition>
  );
}
//Heba
export function JobCard(props) {
  const [view, setView] = useState("list");
  const [currentPage, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const { data, lang, userStatus, userType } = props;

  const Page = (data) => {
    setPage(data);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-sm-center justify-content-md-between align-items-center  justify-content-center job-card-header-panel mb-3">
        <Col xs="auto">
          <Row>
            <Col xs="6">
              <i>
                <FontAwesomeIcon
                  onClick={() => setView("grid")}
                  size="sm"
                  icon={faThLarge}
                  color={view === "grid" ? "#23c0e9" : "#797979"}
                />
              </i>
            </Col>
            <Col xs="6">
              <FontAwesomeIcon
                onClick={() => setView("list")}
                size="sm"
                icon={faList}
                color={view === "list" ? "#23c0e9" : "#797979"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Fade nav={currentPage}>
        <Row className="job-card-container">
          {data &&
            data.slice(start, end).map((job) => {
              const {
                ref,
                img,
                jobTitle,
                companyId,
                minSalary,
                maxSalary,
                workType,
                keywords,
                status,
                sourceId,
                createdAt,
              } = job;
              const ImgSrc =
                img && img.length > 0 && ref === "organization"
                  ? `${lang.baseUrl}/org/media/imgs/${img}`
                  : img && img.length > 0 && ref === "company"
                  ? `${lang.baseUrl}/co/media/imgs/${img}`
                  : Job;
              return (
                <Col
                  key={job._id}
                  xs="12"
                  sm="12"
                  md="12"
                  lg={view === "grid" ? 6 : 12}
                  className="job-card pb-4 "
                >
                  <Card className="w-100 h-100">
                    <Row className="d-flex align-items-right w-100">
                      {view === "list" && (
                        <>
                          <Col md="12" xs="12" sm="12" lg="3" className="pt-4">
                            <Card.Img
                              loading="lazy"
                              src={ImgSrc}
                              alt={"صورة خلفية للوظيفة"}
                              className="company-card-img"
                            />
                          </Col>
                          <Col lg="9">
                            <Row className="d-flex justify-content-between text-right">
                              <Col
                                lg="7"
                                md="12"
                                xs="12"
                                sm="12"
                                className="pt-4 "
                              >
                                <Card.Title>
                                  <Link
                                    // Conditional rendering of the link destination based on user profile status //Heba
                                    to={
                                      userStatus || userType != "pwd"
                                        ? `/home/jobs/${job._id}` // Link to job details when user profile status is true //Heba
                                        : "" // Empty string when user profile status is false, preventing navigation //Heba
                                    }
                                    aria-label={lang.jobTitle}
                                    className="text-decoration-none"
                                    style={{
                                      cursor:
                                        userStatus || userType != "pwd"
                                          ? "pointer"
                                          : "default", // Conditional cursor style based on user profile status //Heba
                                    }}
                                  >
                                    <h5 className="text-dark">
                                      {jobTitle.length > 35
                                        ? jobTitle.substr(0, 35) + "..."
                                        : jobTitle}
                                    </h5>
                                  </Link>
                                </Card.Title>
                                <Card.Text className="pt-3">
                                  <FontAwesomeIcon
                                    icon={faCalendarWeek}
                                    color="#ea6969"
                                  />{" "}
                                  &nbsp;
                                  <span>
                                    {lang.publishDate}{" "}
                                    {moment(createdAt && createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </Card.Text>
                                <Link
                                  // Conditional rendering of the link destination based on user profile status //Heba

                                  to={
                                    userStatus || userType !== "pwd"
                                      ? `/home/companies/${
                                          companyId !== null
                                            ? companyId._id
                                            : sourceId._id
                                        }`
                                      : ""
                                  }
                                  className="text-decoration-none"
                                  style={{
                                    cursor:
                                      userStatus || userType !== "pwd"
                                        ? "pointer"
                                        : "default",
                                  }}
                                >
                                  <p>
                                    {companyId !== null
                                      ? companyId.name
                                      : sourceId.name}
                                    {/* Render company name based on companyId or sourceId */}
                                  </p>
                                </Link>

                                <Card.Subtitle className="pt-4 pb-2 text-right">
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    size="sm"
                                    color="#ff4136"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {lang.experince} : {job.minExperience}{" "}
                                    {lang.to} {job.maxExperience} {lang.years}{" "}
                                  </span>
                                </Card.Subtitle>

                                <Card.Subtitle className="pt-4 pb-4 text-right">
                                  <FontAwesomeIcon
                                    icon={faDollarSign}
                                    size="sm"
                                    color="#ff4136"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {lang.salary} {minSalary} {":"} {maxSalary}{" "}
                                    {lang.pound}{" "}
                                  </span>
                                </Card.Subtitle>
                              </Col>
                              <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="5"
                                className="p-4"
                              >
                                {status ? (
                                  <>
                                    <Row className="pb-3">
                                      <Col>
                                        <Link
                                          to={`/home/jobs/${job._id}`}
                                          aria-label={lang.applied}
                                        >
                                          <Button
                                            variant="secondary"
                                            className="btn-job-card rounded-6 rounded-lg float-left"
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.applied}
                                            </p>
                                          </Button>
                                        </Link>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Button
                                          variant="success"
                                          className="btn-job-card rounded-lg float-left"
                                          disabled
                                          size="sm"
                                          aria-label={workType}
                                        >
                                          <p className="text-btn-job-card">
                                            {workType}
                                          </p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row className="pb-3">
                                      <Col>
                                        {/* Heba */}
                                        {userType == "pwd" && (
                                          <Link
                                            to={`/home/jobs/${job._id}`}
                                            aria-label={lang.apply}
                                          >
                                            <Button
                                              disabled={!userStatus} // Conditionally disable the Link component based on user profile status //Heba
                                              variant="secondary"
                                              className="btn-job-card rounded-6 rounded-lg float-left"
                                            >
                                              <p className="text-btn-job-card">
                                                {lang.apply}
                                              </p>
                                            </Button>
                                          </Link>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Button
                                          variant="success"
                                          className="btn-job-card rounded-lg float-left"
                                          disabled
                                          size="sm"
                                          aria-label={workType}
                                        >
                                          <p className="text-btn-job-card">
                                            {workType}
                                          </p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Card.Footer className=" w-100">
                            <Row>
                              <p className="job-card-keywords-title font-weight-bold pr-3">
                                {lang.keywords + ":"}
                              </p>
                              {keywords &&
                                keywords.map((key, i) => (
                                  <span
                                    className="job-card-keywords-sub"
                                    key={i}
                                  >
                                    {"\u00A0" +
                                      key +
                                      (i < keywords.length - 1
                                        ? ",\u00A0"
                                        : "")}
                                  </span>
                                ))}
                            </Row>
                          </Card.Footer>
                        </>
                      )}

                      {view === "grid" && (
                        <>
                          <Row>
                            <Col md="3" xs="12" sm="12" lg="12">
                              <Row>
                                <Col lg="3">
                                  <Card.Img
                                    src={ImgSrc}
                                    alt={"صورة خلفية للوظيفة"}
                                    className="pt-4 card-img-grid"
                                  />
                                </Col>
                                <Col lg="9" className="text-right">
                                  <Card.Body className="pt-4">
                                    <Card.Title>
                                      <Link
                                        className="text-decoration-none"
                                        // Conditional rendering of the link destination based on user profile status
                                        to={
                                          userStatus && userType == "pwd"
                                            ? `/home/jobs/${job._id}` // Link to job details when user profile status is true
                                            : "" // Empty string when user profile status is false, preventing navigation
                                        }
                                        aria-label={lang.jobTitle}
                                        style={{
                                          cursor:
                                            userStatus && userType == "pwd"
                                              ? "pointer"
                                              : "default", // Conditional cursor style based on user profile status
                                          textDecoration: "none", // Remove underline from the link
                                        }}
                                      >
                                        <h6 className="text-dark text-right">
                                          {jobTitle.length > 33
                                            ? jobTitle.substr(0, 33) + "..."
                                            : jobTitle}
                                        </h6>
                                      </Link>
                                    </Card.Title>
                                    <Link
                                      to={
                                        userStatus && userType == "pwd"
                                          ? `/home/companies/${
                                              companyId !== null
                                                ? companyId._id
                                                : sourceId._id
                                            }`
                                          : ""
                                      }
                                      // If userStatus is true, the destination will be determined based on companyId or sourceId
                                      // If userStatus is false, an empty string is provided as the destination, preventing navigation
                                      className="text-decoration-none"
                                      style={{
                                        cursor:
                                          userStatus && userType == "pwd"
                                            ? "pointer"
                                            : "default",
                                        // Cursor style is conditional based on userStatus
                                        // If userStatus is true, cursor will be "pointer", allowing click
                                        // If userStatus is false, cursor will be "default", indicating no interaction
                                      }}
                                    >
                                      <p>
                                        {companyId !== null &&
                                        companyId.name.length > 33
                                          ? companyId.name.substr(0, 33) + "..."
                                          : sourceId.name}
                                      </p>
                                    </Link>
                                    <Card.Subtitle className="pt-4 ">
                                      <FontAwesomeIcon
                                        icon={faStar}
                                        size="sm"
                                        color="#ff4136"
                                      />
                                      <span>
                                        {" "}
                                        {lang.experince} : {job.minExperience}{" "}
                                        {lang.to} {job.maxExperience}{" "}
                                        {lang.years}{" "}
                                      </span>
                                    </Card.Subtitle>
                                    <Card.Subtitle className="pt-4">
                                      <FontAwesomeIcon
                                        icon={faDollarSign}
                                        size="sm"
                                        color="#ff4136"
                                      />
                                      <span>
                                        {" "}
                                        {lang.salary} {minSalary} {":"}{" "}
                                        {maxSalary} {lang.pound}
                                      </span>
                                    </Card.Subtitle>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Col>

                            {status ? (
                              <>
                                <Row className="w-100 px-5 pb-4">
                                  <Col lg="12">
                                    <Link
                                      to={`/home/jobs/${job._id}`}
                                      className=" pl-3 pr-0"
                                      aria-label={lang.applied}
                                    >
                                      <Button
                                        size="sm"
                                        className="btn-job-card-grid rounded-6 rounded-lg"
                                        variant="secondary"
                                      >
                                        <p className="text-btn-job-card">
                                          {lang.applied}
                                        </p>
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="success"
                                      disabled
                                      className="btn-job-card rounded-6 rounded-lg"
                                      size="sm"
                                      aria-label={workType}
                                    >
                                      <p className="text-btn-job-card">
                                        {workType}
                                      </p>
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row className="w-100 px-5 pb-4">
                                  <Col lg="12">
                                    {userType == "pwd" && (
                                      <Link
                                        to={`/home/jobs/${job._id}`}
                                        className=" pl-3 pr-0"
                                        aria-label={lang.apply}
                                      >
                                        <Button
                                          disabled={!userStatus} // Conditionally disable the Link component based on user profile status //Heba
                                          size="sm"
                                          className="btn-job-card-grid rounded-6 rounded-lg"
                                          variant="secondary"
                                        >
                                          <p className="text-btn-job-card">
                                            {lang.apply}
                                          </p>
                                        </Button>
                                      </Link>
                                    )}
                                    <Button
                                      variant="success"
                                      disabled
                                      className="btn-job-card rounded-6 rounded-lg"
                                      size="sm"
                                      aria-label={workType}
                                    >
                                      <p className="text-btn-job-card">
                                        {workType}
                                      </p>
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Row>

                          <Card.Footer className=" w-100 ">
                            <Row>
                              <p className="job-card-keywords-title font-weight-bold pr-3">
                                {lang.keywords + ":"}
                              </p>
                              {keywords &&
                                keywords.slice(0, 2).map((key, i) => (
                                  <span
                                    className="job-card-keywords-sub"
                                    key={i}
                                  >
                                    {"\u00A0" +
                                      key +
                                      (i < keywords.length - 1
                                        ? ",\u00A0"
                                        : "")}
                                  </span>
                                ))}
                            </Row>
                          </Card.Footer>
                        </>
                      )}
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Fade>
      {/* {data.length > jobs.length && (
                <Button fullWidth onClick={loadMore} size='sm' text={lang.more} />
            )} */}
      <Row className="d-flex justify-content-sm-center justify-content-md-center align-items-center  justify-content-center mb-3">
        <Pages
          pageSize={10}
          maxPages={3}
          total={data && data.length}
          setPage={Page}
          currentPage={currentPage}
          start={setStart}
          end={setEnd}
        />
      </Row>
    </Container>
  );
} //new edit by shrouk

function GoogleMapsURLToEmbedURL(GoogleMapsURL) {
  var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(GoogleMapsURL);
  if (coords != null) {
    var coordsArray = coords[1].split(",");
    return (
      "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" +
      coordsArray[1] +
      "!3d" +
      coordsArray[0] +
      "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098"
    );
  }
}

export function JobDetailsCard(props) {
  const { job, lang } = props;
  return (
    <Row className="w-100">
      <Card className="w-100">
        <Col xs="12" className="job-card">
          <div className="job-details-card-label">
            <span>{job.workType}</span>
          </div>
          <Row className="job-details-card-header">
            <Col className="text-right">
              <h4 className="text-dark font-weight-bold pt-2">
                {lang.aboutJob}
              </h4>
              <p className="text-right text-muted pt-2">
                {job.about || lang.noData}
              </p>
              <h4 className="text-dark font-weight-bold pt-5">
                {lang.responsibilities}
              </h4>
              {job.responsibilities.length > 0 ? (
                job.responsibilities.map((value, index) => (
                  <div key={index} className="row align-items-center pt-2 px-2">
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCaretLeft}
                      color="#ea6969"
                    />
                    <span className="text-right text-muted pr-2 ">{value}</span>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}

              <h4 className="text-dark font-weight-bold pt-5">
                {lang.reqSkills}
              </h4>
              {job.skills && job.skills.length > 0 ? (
                job.skills.map((value, index) => (
                  <div key={index} className="row align-items-center pt-2 px-2">
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCaretLeft}
                      color="#ea6969"
                    />
                    <span className="text-right text-muted pr-2 ">
                      {value.skillId && value.skillId.name}
                    </span>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}
              <h4 className="text-dark font-weight-bold pt-5">
                {lang.appSteps}
              </h4>
              {job.procedures.length > 0 ? (
                job.procedures.map((value, index) => (
                  <div className="row align-items-center pt-2 px-2" key={index}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCaretLeft}
                      color="#ea6969"
                    />
                    <span className="text-right text-muted pr-2 ">{value}</span>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}
            </Col>
          </Row>

          <Row className="job-card-footer">
            <p className="job-card-keywords-title font-weight-bold">
              {lang.keywords + ":"}
            </p>
            {job.keywords &&
              job.keywords.map((key, i) => (
                <span className="job-card-keywords-sub" key={i}>
                  {"\u00A0" +
                    key +
                    (i < job.keywords.length - 1 ? ",\u00A0" : "")}
                </span>
              ))}
          </Row>
        </Col>
      </Card>
    </Row>
  );
}
export function ServicesPageCard(props) {
  const [view, setView] = useState("list");
  const [currentPage, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const navigate = useNavigate();
  const { data, lang, userStatus, userType } = props;
  const Page = (data) => {
    setPage(data);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-sm-center justify-content-md-between align-items-center  justify-content-center job-card-header-panel mb-3">
        <Col xs="auto">
          <Row>
            <Col xs="6">
              <i>
                <FontAwesomeIcon
                  onClick={() => setView("grid")}
                  size="sm"
                  icon={faThLarge}
                  color={view === "grid" ? "#23c0e9" : "#797979"}
                />
              </i>
            </Col>
            <Col xs="6">
              <FontAwesomeIcon
                onClick={() => setView("list")}
                size="sm"
                icon={faList}
                color={view === "list" ? "#23c0e9" : "#797979"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Fade nav={currentPage}>
        <Row className="job-card-container">
          {data.length > 0 &&
            data.slice(start, end).map((service) => {
              const {
                img,
                name,
                organizationId,
                amount,
                type,
                status,
                keywords,
                description,
                createdAt,
              } = service;
              const ImgSrc =
                img && img.length > 0
                  ? `${lang.baseUrl}/org/media/imgs/${img}`
                  : Job;
              return (
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg={view === "grid" ? 6 : 12}
                  className=" job-card p-3 "
                  key={service._id}
                >
                  <Card className="w-100 h-100">
                    <Row className="d-flex align-items-right w-100">
                      {view === "list" && (
                        <>
                          <Col md="12" xs="12" sm="12" lg="3" className="pt-3">
                            <Card.Img
                              loading="lazy"
                              src={ImgSrc}
                              alt={"صورة خلفية للخدمة"}
                              className="org-card-img"
                            />
                          </Col>
                          <Col lg="9">
                            <Row className="d-flex justify-content-between">
                              <Col
                                lg="7"
                                md="12"
                                xs="12"
                                sm="12"
                                className="pt-4 px-4"
                              >
                                <Card.Title>
                                  <Link
                                    // Conditional rendering of the link destination based on user profile status //Heba

                                    to={
                                      userStatus || userType != "pwd"
                                        ? `/home/services/${service._id}` // Link to job details when user profile status is true //Heba
                                        : "" // Empty string when user profile status is false, preventing navigation //Heba
                                    } // Conditional rendering of the link destination based on user profile status //Heba
                                    aria-label={name}
                                    className="text-decoration-none"
                                    style={{
                                      cursor:
                                        userStatus || userType != "pwd"
                                          ? "pointer"
                                          : "default", // Conditional cursor style based on user profile status //Heba
                                    }}
                                  >
                                    <h5 className="text-dark ">
                                      {name.length > 35
                                        ? name.substr(0, 35) + "..."
                                        : name}
                                    </h5>
                                  </Link>
                                </Card.Title>
                                <Card.Text className="pt-3">
                                  <FontAwesomeIcon
                                    icon={faCalendarWeek}
                                    color="#ea6969"
                                  />{" "}
                                  &nbsp;
                                  <span>
                                    {lang.publishDate}{" "}
                                    {moment(createdAt && createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </Card.Text>
                                <Card.Subtitle className="pt-4">
                                  <Link
                                    // Conditional rendering of the link destination based on user profile status //Heba

                                    to={
                                      userStatus || userType !== "pwd"
                                        ? `/home/organizations/${organizationId._id}` // Link to organisation profile when user profile status is true //Heba
                                        : "" // Empty string when user profile status is false, preventing navigation //Heba
                                    }
                                    aria-label={organizationId.name}
                                    className="text-decoration-none"
                                    style={{
                                      cursor:
                                        userStatus || userType !== "pwd"
                                          ? "pointer"
                                          : "default", // Conditional cursor style based on user profile status //Heba
                                    }}
                                  >
                                    <p>
                                      {organizationId.name.length > 35
                                        ? organizationId.name.substr(0, 35)
                                        : organizationId.name}
                                    </p>
                                  </Link>
                                </Card.Subtitle>
                                <Card.Subtitle className="pt-4 pb-4">
                                  <i>
                                    <FontAwesomeIcon
                                      icon={faDollarSign}
                                      size="sm"
                                      color="#ff4136"
                                    />
                                  </i>{" "}
                                  <span>
                                    {lang.salary} {amount} {":"} {amount}{" "}
                                    {lang.pound}
                                  </span>
                                </Card.Subtitle>
                              </Col>
                              <Col
                                xs="12"
                                sm="12"
                                md="6"
                                lg="5"
                                className="p-4"
                              >
                                {status ? (
                                  <>
                                    <Row className="pb-3">
                                      <Col>
                                        <Link
                                          to={`/home/services/${service._id}`}
                                          aria-label={lang.applied}
                                        >
                                          <Button
                                            variant="secondary"
                                            className="btn-job-card rounded-6 rounded-lg float-left"
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.applied}
                                            </p>
                                          </Button>
                                        </Link>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Button
                                          variant="success"
                                          disabled
                                          className="btn-job-card rounded-6 rounded-lg float-left"
                                          size="sm"
                                          aria-label={type}
                                        >
                                          <p className="text-btn-job-card">
                                            {type}
                                          </p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <Row className="pb-3">
                                      <Col>
                                        {/* Heba */}
                                        {userType == "pwd" && (
                                          <Link
                                            to={`/home/services/${service._id}`}
                                            aria-label={lang.apply}
                                          >
                                            <Button
                                              disabled={!userStatus} // Conditionally disable the Link component based on user profile status //Heba
                                              variant="secondary"
                                              className="btn-job-card rounded-6 rounded-lg float-left"
                                            >
                                              <p className="text-btn-job-card">
                                                {lang.apply}
                                              </p>
                                            </Button>
                                          </Link>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Button
                                          variant="success"
                                          disabled
                                          className="btn-job-card rounded-6 rounded-lg float-left"
                                          size="sm"
                                          aria-label={type}
                                        >
                                          <p className="text-btn-job-card">
                                            {type}
                                          </p>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Card.Footer className=" w-100">
                            <Row>
                              <p className="job-card-keywords-title font-weight-bold pr-3">
                                {lang.keywords + ":"}
                              </p>
                              {keywords &&
                                keywords.map((key, i) => (
                                  <span
                                    className="job-card-keywords-sub"
                                    key={i}
                                  >
                                    {"\u00A0" +
                                      key +
                                      (i < keywords.length - 1
                                        ? ",\u00A0"
                                        : "")}
                                  </span>
                                ))}
                            </Row>
                          </Card.Footer>
                        </>
                      )}

                      {view === "grid" && (
                        <>
                          <Row>
                            <Col md="3" xs="12" sm="12" lg="12">
                              <Row>
                                <Col lg="4">
                                  <Card.Img
                                    src={ImgSrc}
                                    alt={"صورة خلفية للخدمة"}
                                    className="pt-2 card-img-grid"
                                  />
                                </Col>
                                <Col lg="8">
                                  <Card.Body className="pt-4">
                                    <Card.Title>
                                      {/*  Conditional rendering of the link  destination based on user profile status //Heba */}
                                      <Link
                                        to={
                                          userStatus && userType == "pwd"
                                            ? `/home/services/${service._id}` // Link to job details when user profile status is true //Heba
                                            : "" // Empty string when user profile status is false, preventing navigation //Heba
                                        } // Conditional rendering of the link destination based on user profile status //Heba
                                        className="text-decoration-none"
                                        style={{
                                          cursor:
                                            userStatus && userType == "pwd"
                                              ? "pointer"
                                              : "default", // Conditional cursor style based on user profile status //Heba
                                        }}
                                        aria-label={name}
                                      >
                                        <h6 className="text-dark">
                                          {name.length > 30
                                            ? name.substr(0, 30) + "..."
                                            : name}
                                        </h6>
                                      </Link>
                                    </Card.Title>
                                    <Card.Subtitle className="pt-3">
                                      <Link
                                        to={
                                          userStatus && userType == "pwd"
                                            ? `/home/organizations/${organizationId._id}` // Link to organisation profile when user profile status is true //Heba
                                            : "" // Empty string when user profile status is false, preventing navigation //Heba
                                        } // Conditional rendering of the link destination based on user profile status //Heba
                                        aria-label={organizationId.name}
                                        className="text-decoration-none"
                                        style={{
                                          cursor:
                                            userStatus && userType == "pwd"
                                              ? "pointer"
                                              : "default", // Conditional cursor style based on user profile status //Heba
                                        }}
                                      >
                                        <p>
                                          {" "}
                                          {organizationId.name.length > 25
                                            ? organizationId.name.substr(
                                                0,
                                                25
                                              ) + "..."
                                            : organizationId.name}
                                        </p>
                                      </Link>
                                    </Card.Subtitle>
                                    <Card.Subtitle className="pt-3 pb-3 ">
                                      <i>
                                        <FontAwesomeIcon
                                          icon={faDollarSign}
                                          size="sm"
                                          color="#ff4136"
                                        />
                                      </i>{" "}
                                      {lang.salary} {amount} {":"} {amount}{" "}
                                      {lang.pound}
                                    </Card.Subtitle>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Col>

                            {status ? (
                              <>
                                <Row className="w-100 px-5 pb-4">
                                  <Col lg="12">
                                    <Link
                                      to={`/home/services/${service._id}`}
                                      className=" pl-3 pr-0"
                                    >
                                      <Button
                                        size="sm"
                                        className="btn-job-card-grid rounded-6 rounded-lg"
                                        variant="secondary"
                                      >
                                        <p className="text-btn-job-card">
                                          {lang.applied}
                                        </p>
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="success"
                                      disabled
                                      className="btn-job-card rounded-6 rounded-lg"
                                      size="sm"
                                    >
                                      <p className="text-btn-job-card">
                                        {type}
                                      </p>
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row className="w-100 px-5 pb-4">
                                  <Col lg="12">
                                    {userType == "pwd" && (
                                      <Link
                                        to={`/home/services/${service._id}`}
                                        className=" pl-3 pr-0"
                                      >
                                        <Button
                                          disabled={!userStatus} // Conditionally disable the Link component based on user profile status //Heba
                                          size="sm"
                                          className="btn-job-card-grid rounded-6 rounded-lg"
                                          variant="secondary"
                                        >
                                          <p className="text-btn-job-card">
                                            {lang.apply}
                                          </p>
                                        </Button>
                                      </Link>
                                    )}
                                    <Button
                                      variant="success"
                                      disabled
                                      className="btn-job-card rounded-6 rounded-lg"
                                      size="sm"
                                    >
                                      <p className="text-btn-job-card">
                                        {type}
                                      </p>
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Row>
                          <Card.Footer className=" w-100 ">
                            <Row>
                              <p className=" job-card-keywords-title font-weight-bold pr-3">
                                {lang.keywords + ":"}
                              </p>
                              {keywords &&
                                keywords.slice(0, 1).map((key, i) => (
                                  <span
                                    className="job-card-keywords-sub"
                                    key={i}
                                  >
                                    {"\u00A0" +
                                      key +
                                      (i < keywords.length - 1
                                        ? ",\u00A0"
                                        : "")}
                                  </span>
                                ))}
                            </Row>
                          </Card.Footer>
                        </>
                      )}
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Fade>
      {/* {data.length > jobs.length && (
                <Button fullWidth onClick={loadMore} size='sm' text={lang.more} />
            )} */}
      <Row className="d-flex justify-content-sm-center justify-content-md-center align-items-center  justify-content-center mb-3">
        <Pages
          pageSize={10}
          maxPages={3}
          total={data && data.length}
          setPage={Page}
          currentPage={currentPage}
          start={setStart}
          end={setEnd}
        />
      </Row>
    </Container>
  );
} //new edit by shrouk

export function ServiceDetailsCard(props) {
  const { service, lang } = props;
  return (
    <Container>
      <Row className="job-card-container">
        <Col xs="12" className="job-card">
          <div className="job-details-card-label">
            <span>{service.type}</span>
          </div>
          <Row className="job-details-card-header">
            <Col>
              <h4 className="text-right text-dark font-weight-bold pt-2">
                {lang.orgName}
              </h4>
              <p className="text-right text-muted pt-2">
                {service.organizationId.name}
              </p>
              <h4 className="text-right text-dark font-weight-bold pt-5">
                {lang.aboutService}
              </h4>
              <p className="text-right text-muted pt-2">
                {service.description}
              </p>
              <h4 className="text-right text-dark font-weight-bold pt-5">
                {lang.branches}
              </h4>
              {service.branchId.length > 0 ? (
                service.branchId.map((value, index) => (
                  <div className="row align-items-center pt-2 px-2" key={index}>
                    <FontAwesomeIcon
                      size="sm"
                      icon={faMapMarkerAlt}
                      color="#ea6969"
                    />

                    <a
                      href={value.location}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-right text-muted pr-2 ">
                        {value.name}
                      </span>
                    </a>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}

              <h4 className="text-right text-dark font-weight-bold pt-5">
                {lang.serviceProgreses}
              </h4>
              {service.process.length > 0 ? (
                service.process.map((value, index) => (
                  <div className="row align-items-center pt-2 px-2" key={index}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCaretLeft}
                      color="#ea6969"
                    />
                    <span className="text-right text-muted pr-2 ">{value}</span>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}
              <h4 className="text-right text-dark font-weight-bold pt-5">
                {lang.paperNedded}
              </h4>
              {service.documentsRequired.length > 0 ? (
                service.documentsRequired.map((value, index) => (
                  <div className="row align-items-center pt-2 px-2" key={index}>
                    <FontAwesomeIcon
                      size="2x"
                      icon={faCaretLeft}
                      color="#ea6969"
                    />
                    <span className="text-right text-muted pr-2 ">{value}</span>
                  </div>
                ))
              ) : (
                <p className="text-right text-muted pt-2">{lang.notFound}</p>
              )}
            </Col>
          </Row>
          <Row className="job-card-footer">
            <p className="job-card-keywords-title font-weight-bold">
              {lang.keywords + ":"}
            </p>
            {service.keywords &&
              service.keywords.map((key, i) => (
                <span className="job-card-keywords-sub" key={i}>
                  {"\u00A0" +
                    key +
                    (i < service.keywords.length - 1 ? ",\u00A0" : "")}
                </span>
              ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export function OrganizationsPageCard(props) {
  const [currentPage, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const { data, lang } = props;
  const [view, setView] = useState("list");

  const Page = (value) => {
    setPage(value);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-sm-center justify-content-md-between align-items-center  justify-content-center job-card-header-panel mb-3">
        <Col xs="auto">
          <Row>
            <Col xs="6">
              <i>
                <FontAwesomeIcon
                  onClick={() => setView("grid")}
                  size="sm"
                  icon={faThLarge}
                  color={view === "grid" ? "#23c0e9" : "#797979"}
                />
              </i>
            </Col>
            <Col xs="6">
              <FontAwesomeIcon
                onClick={() => setView("list")}
                size="sm"
                icon={faList}
                color={view === "list" ? "#23c0e9" : "#797979"}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Fade nav={currentPage}>
        <Row className="no-gutters">
          {data.length > 0 &&
            data.slice(start, end).map((org) => {
              const { avatar, name, address, jobs, services, brief } = org;
              const ImgSrc = avatar
                ? lang.baseUrl + "/org/media/imgs/" + avatar
                : Org;
              return (
                <Col
                  key={org._id}
                  xs="12"
                  sm="12"
                  md="12"
                  lg={view === "grid" ? 4 : 12}
                  className="pb-4 px-2 "
                >
                  <Card className="w-100 h-100  ">
                    <Row className="w-100 d-flex align-items-center pt-2">
                      {view === "list" && (
                        <>
                          <Col md="3" xs="12" sm="12" lg="2">
                            <Card.Img
                              src={ImgSrc}
                              alt={"صورة خلفية للمؤسسة"}
                              className="center org-card-img"
                            />
                          </Col>

                          <Col lg="10" md="12" xs="12" sm="12" className="ps-0">
                            <Card.Body>
                              <Row className="w-100">
                                <Col lg="10">
                                  <Col lg="12">
                                    <Card.Title>
                                      <Link
                                        to={`/home/organizations/${org._id}`}
                                        className="text-decoration-none"
                                        aria-label={name}
                                      >
                                        <h5 className="pt-4 text-dark ">
                                          {" "}
                                          {name}
                                        </h5>
                                      </Link>
                                    </Card.Title>
                                    {org.location ? (
                                      <a
                                        href={org.location && org.location}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-decoration-none text-black-70"
                                        aria-label={address}
                                      >
                                        <FontAwesomeIcon
                                          icon={faMapMarkerAlt}
                                          size="sm"
                                          color="#ff4136"
                                        />{" "}
                                        <span>{address && address}</span>
                                      </a>
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        size="sm"
                                        color="#ff4136"
                                        hidden={true}
                                      />
                                    )}
                                  </Col>
                                  <Col lg="10" className="pt-3">
                                    <Row className="w-100">
                                      <Col lg="3">
                                        {services ? (
                                          <Button
                                            variant="outline-primary"
                                            className="btn-job-card rounded-6 rounded-lg"
                                            aria-label={lang.service}
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.service} {services}
                                            </p>
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="outline-primary"
                                            className="btn-job-card rounded-6 rounded-lg"
                                            disabled
                                            aria-label={lang.zeroServices}
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.zeroServices}
                                            </p>
                                          </Button>
                                        )}
                                      </Col>
                                      <Col lg="3">
                                        {jobs ? (
                                          <Button
                                            variant="outline-success"
                                            className="btn-job-card rounded-6 rounded-lg"
                                            aria-label={lang.jobs}
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.jobs} {jobs}
                                            </p>
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="outline-success"
                                            className="btn-job-card rounded-6 rounded-lg"
                                            disabled
                                            aria-label={lang.zeroJobs}
                                          >
                                            <p className="text-btn-job-card">
                                              {lang.zeroJobs}
                                            </p>
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Col>

                                <Col lg="2" className="pl-0">
                                  <Row className="w-100 align-items-center pt-5 float-left">
                                    <Link
                                      to={`/home/organizations/${org._id}`}
                                      aria-label={lang.orgProfile}
                                    >
                                      <Button
                                        variant="secondary"
                                        className="btn-job-card rounded-6 rounded-lg"
                                      >
                                        <p className="text-btn-job-card">
                                          {lang.orgProfile}
                                        </p>
                                      </Button>
                                    </Link>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Col>
                        </>
                      )}

                      {view === "grid" && (
                        <>
                          <Row>
                            <Col md="3" xs="12" sm="12" lg="12">
                              <Row>
                                <Col lg="4">
                                  <Card.Img
                                    src={ImgSrc}
                                    alt={"صورة خلفية للمؤسسة"}
                                    className="mx-4 pt-4"
                                    align="right"
                                  />
                                </Col>

                                <Col lg="8">
                                  <Card.Body>
                                    <Card.Title>
                                      <Link
                                        to={`/home/organizations/${org._id}`}
                                        className="text-decoration-none"
                                        aria-label={name}
                                      >
                                        <h6 className="text-dark">
                                          {name.length > 30
                                            ? name.substr(0, 30) + "..."
                                            : name}
                                        </h6>
                                      </Link>
                                    </Card.Title>
                                    <Card.Subtitle className="pt-3">
                                      {org.location ? (
                                        <a
                                          href={org.location && org.location}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-decoration-none text-black-70"
                                          aria-label={address}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            size="sm"
                                            color="#ff4136"
                                          />{" "}
                                          <span>
                                            {address.length
                                              ? address.substr(0, 40) + "..."
                                              : address}
                                          </span>
                                        </a>
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faMapMarkerAlt}
                                          size="sm"
                                          color="#ff4136"
                                          hidden={true}
                                        />
                                      )}
                                    </Card.Subtitle>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg="12" className="pt-4 pb-3 pr-5">
                              <Row>
                                <div className="pl-1">
                                  <Link to={`/home/organizations/${org._id}`}>
                                    <Button
                                      variant="secondary"
                                      className="btn-org-card-grid rounded-6 rounded-lg "
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.orgProfile}
                                      </p>
                                    </Button>
                                  </Link>
                                </div>
                                <div className="pl-1">
                                  {services ? (
                                    <Button
                                      variant="outline-primary"
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.service} {services}
                                      </p>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline-primary"
                                      disabled
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.zeroServices}
                                      </p>
                                    </Button>
                                  )}
                                </div>
                                <div className="pl-1">
                                  {jobs ? (
                                    <Button
                                      variant="outline-success"
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.jobs}
                                        {jobs}
                                      </p>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline-success"
                                      disabled
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.zeroJobs}
                                      </p>
                                    </Button>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Fade>
      {/* {data.length > jobs.length && (
            <Button fullWidth onClick={loadMore} size='sm' text={lang.more} />
        )} */}
      <Row className="d-flex justify-content-sm-center justify-content-md-center align-items-center  justify-content-center mb-3">
        <Pages
          pageSize={9}
          maxPages={3}
          total={data && data.length}
          setPage={Page}
          currentPage={currentPage}
          start={setStart}
          end={setEnd}
        />
      </Row>
    </Container>
  );
}
///new by shrouk

export function OrgDetailsCard(props) {
  const { org, lang } = props;
  return (
    <Container>
      <Row className="org-profile-card-container w-100">
        <Col xs="12" className="job-card">
          <Row className="job-details-card-header">
            <Col>
              <h4 className="text-dark font-weight-bold pt-2">
                {lang.aboutOrg}
              </h4>
              <p className="text-right text-muted pt-2">{org.brief}</p>
              <h4 className="text-dark font-weight-bold pt-5">
                {lang.siteServices}
              </h4>
              {org.consultingServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.consultingServices}
                  </span>
                </div>
              )}
              {org.trainingServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.trainingServices}
                  </span>
                </div>
              )}
              {org.assessmentServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.assessmentServices}
                  </span>
                </div>
              )}
              {org.empowermentServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.empowermentServices}
                  </span>
                </div>
              )}
              {org.employmentServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.employmentServices}
                  </span>
                </div>
              )}
              {org.financingServices && (
                <div className="row align-items-center pt-2 px-2">
                  <FontAwesomeIcon
                    size="sm"
                    icon={faCheckCircle}
                    color={"#5fc0e9"}
                  />
                  <span className="text-right text-muted pr-2 ">
                    {lang.financingServices}
                  </span>
                </div>
              )}
              ‫
              {/* {org.location ?
                                <div>
                                    <h3 className='font-weight-bold'>{lang.location}</h3>
                                    <Row className='pt-3'>
                                        <iframe src={org.location} height="300px" width="100%" allowfullscreen="" loading="lazy"></iframe>
                                    </Row>
                                </div>
                                :
                            } */}
              <hr className="w-100"></hr>
              <Row>
                <Col xs="12">
                  <Row className="p-4 align-items-center ">
                    <h5 className="font-weight-bold p-3"> للتواصل : </h5>
                    {org.website ? (
                      <a
                        className="text-decoration-none mx-4 "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={org.website}
                      >
                        <FontAwesomeIcon size="lg" icon={faGlobe} />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faGlobe}
                        className="mx-4"
                      />
                    )}
                    {org.linkedin ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={org.linkedin}
                        className="text-decoration-none mx-4"
                      >
                        <FontAwesomeIcon size="lg" icon={faLinkedin} />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faLinkedin}
                        className="mx-4"
                      />
                    )}
                    {org.facebook ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={org.facebook}
                        className="text-decoration-none mx-4"
                      >
                        <FontAwesomeIcon size="lg" icon={faFacebookF} />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faFacebookF}
                        className="mx-4"
                      />
                    )}
                    {org.twitter ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={org.twitter}
                        className="text-decoration-none mx-4"
                      >
                        <FontAwesomeIcon size="lg" icon={faTwitter} />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faTwitter}
                        className="mx-4"
                      />
                    )}
                    {org.youtube ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={org.youtube}
                        className="text-decoration-none mx-4"
                      >
                        <FontAwesomeIcon size="lg" icon={faYoutube} />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faYoutube}
                        className="mx-4"
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
//new design by shrouk

export function CoPageCard(props) {
  const [currentPage, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const { data, lang } = props;
  const [view, setView] = useState("list");
  const Page = (value) => {
    setPage(value);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-sm-center justify-content-md-between align-items-center  justify-content-center job-card-header-panel mb-3">
        <Col xs="auto">
          <Row>
            <Col xs="6">
              <i>
                <FontAwesomeIcon
                  onClick={() => setView("grid")}
                  size="sm"
                  icon={faThLarge}
                  color={view === "grid" ? "#23c0e9" : "#797979"}
                />
              </i>
            </Col>
            <Col xs="6">
              <FontAwesomeIcon
                onClick={() => setView("list")}
                size="sm"
                icon={faList}
                color={view === "list" ? "#23c0e9" : "#797979"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Fade nav={currentPage}>
        <Row className="p-1">
          {data.length > 0 &&
            data.slice(start, end).map((co) => {
              const { avatar, name, address, jobs } = co;
              const ImgSrc = avatar
                ? lang.baseUrl + "/co/media/imgs/" + avatar
                : Company;

              return (
                <Col
                  key={co._id}
                  xs="12"
                  sm="12"
                  md="12"
                  lg={view === "grid" ? 4 : 12}
                  className="pb-4 px-2 "
                >
                  <Card className="w-100 h-100  ">
                    <Row className="w-100 d-flex align-items-center pt-2">
                      {view === "list" && (
                        <>
                          <Col md="3" xs="12" sm="12" lg="2">
                            <Card.Img
                              src={ImgSrc}
                              alt={"صورة خلفية للشركة"}
                              className="center company-card-img"
                            />
                          </Col>

                          <Col lg="10" md="12" xs="12" sm="12" className="ps-0">
                            <Card.Body>
                              <Row className="w-100">
                                <Col lg="10">
                                  <Card.Title>
                                    <Link
                                      to={`/home/companies/${co._id}`}
                                      className="text-decoration-none"
                                      aria-label={name}
                                    >
                                      <h5 className="text-dark pt-4">{name}</h5>
                                    </Link>
                                  </Card.Title>
                                  {co.location ? (
                                    <a
                                      href={co.location && co.location}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none text-black-70"
                                      aria-label={address}
                                    >
                                      <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        size="sm"
                                        color="#ff4136"
                                      />{" "}
                                      <span>{address && address}</span>
                                    </a>
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faMapMarkerAlt}
                                      size="sm"
                                      color="#ff4136"
                                      hidden={true}
                                    />
                                  )}
                                  <Row className="w-100 pt-3">
                                    <Col lg="3">
                                      {jobs ? (
                                        <Button
                                          variant="outline-success"
                                          className="btn-job-card rounded-6 rounded-lg"
                                          aria-label={lang.jobs}
                                        >
                                          <p className="text-btn-job-card">
                                            {lang.jobs} {jobs}
                                          </p>
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outline-success"
                                          className="btn-job-card rounded-6 rounded-lg"
                                          disabled
                                          aria-label={lang.zeroJobs}
                                        >
                                          <p className="text-btn-job-card">
                                            {lang.zeroJobs}
                                          </p>
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>

                                <Col lg="2" className="pl-0">
                                  <Row className="w-100 align-items-center pt-5 float-left">
                                    <Link
                                      to={`/home/companies/${co._id}`}
                                      aria-label={lang.coProfile}
                                    >
                                      <Button
                                        variant="secondary"
                                        className="btn-job-card rounded-6 rounded-lg"
                                      >
                                        <p className="text-btn-job-card">
                                          {lang.coProfile}
                                        </p>
                                      </Button>
                                    </Link>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Col>
                        </>
                      )}

                      {view === "grid" && (
                        <>
                          <Row>
                            <Col md="3" xs="12" sm="12" lg="12">
                              <Row>
                                <Col lg="4">
                                  <Card.Img
                                    src={ImgSrc}
                                    alt={"صورة خلفية للشركة"}
                                    className="mx-4 pt-4"
                                  />
                                </Col>
                                <Col lg="8">
                                  <Card.Body>
                                    <Card.Title>
                                      <Link
                                        to={`/home/companies/${co._id}`}
                                        className="text-decoration-none"
                                        aria-label={name}
                                      >
                                        <h6 className="text-dark">
                                          {name.length > 30
                                            ? name.substr(0, 30) + "..."
                                            : name}
                                        </h6>
                                      </Link>
                                    </Card.Title>
                                    <Card.Subtitle>
                                      {co.location ? (
                                        <a
                                          href={co.location && co.location}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-decoration-none text-black-70"
                                          aria-label={address}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            size="sm"
                                            color="#ff4136"
                                          />{" "}
                                          <span>
                                            {address.length > 40
                                              ? address.substr(0, 40) + "..."
                                              : address}{" "}
                                          </span>
                                        </a>
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faMapMarkerAlt}
                                          size="sm"
                                          color="#ff4136"
                                          hidden={true}
                                        />
                                      )}
                                    </Card.Subtitle>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg="12" className="pt-4 pb-3 pr-5">
                              <Row>
                                <div className="pl-1">
                                  <Link to={`/home/companies/${co._id}`}>
                                    <Button
                                      variant="secondary"
                                      className="btn-org-card-grid rounded-6 rounded-lg "
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.coProfile}
                                      </p>
                                    </Button>
                                  </Link>
                                </div>
                                <div className="pr-2">
                                  {jobs ? (
                                    <Button
                                      variant="outline-success"
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.jobs} {jobs}
                                      </p>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline-success"
                                      disabled
                                      className="btn-org-card-grid rounded-6 rounded-lg"
                                    >
                                      <p className="text-btn-job-card">
                                        {lang.zeroJobs}
                                      </p>
                                    </Button>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Row>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Fade>
      <Row className="d-flex justify-content-sm-center justify-content-md-center align-items-center  justify-content-center mb-3">
        <Pages
          pageSize={9}
          maxPages={3}
          total={data && data.length}
          setPage={Page}
          currentPage={currentPage}
          start={setStart}
          end={setEnd}
        />
      </Row>
    </Container>
  );
}
//neww design by shrouk

export function ArticlesCard(props) {
  const [currentPage, setPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const { data, lang } = props;
  const Page = (value) => {
    setPage(value);
  };

  return (
    <>
      <Fade nav={currentPage}>
        <div className="row">
          {data.length > 0 &&
            data.slice(start, end).map((article) => {
              const {
                sourceId,
                ref,
                img,
                youtube,
                video,
                body,
                title,
                imageDescription,
                createdAt,
              } = article;
              const avatar =
                ref === "admin"
                  ? AdminLogo
                  : sourceId &&
                    sourceId.avatar &&
                    sourceId.avatar.length > 0 &&
                    ref === "organization"
                  ? `${lang.baseUrl}/org/media/imgs/${sourceId &&
                      sourceId.avatar}`
                  : `${lang.baseUrl}/co/media/imgs/${sourceId &&
                      sourceId.avatar}`;
              const SrcImg =
                img && img.length > 0 && ref === "admin"
                  ? `${lang.baseUrl}/admin/media/imgs/${img}`
                  : img && img.length > 0 && ref === "organization"
                  ? `${lang.baseUrl}/org/media/imgs/${img}`
                  : img && img.length > 0 && ref === "company"
                  ? `${lang.baseUrl}/co/media/imgs/${img}`
                  : Article;

              return (
                <div
                  className="col-md-6 col-xs-12 col-lg-4 col-xl-4 col-sm-12 mt-4 "
                  key={article._id}
                >
                  <Card className="h-100 img-responsive articleCard">
                    <Link
                      to={`/home/articles/${article._id}/${article.title}`}
                      className="text-decoration-none w-100"
                    >
                      {!youtube && img && img.length > 0 ? (
                        <Card.Img
                          variant="top"
                          loading="lazy"
                          className="img-responsive h-100"
                          width="100%"
                          alt={imageDescription && imageDescription}
                          src={SrcImg}
                        />
                      ) : (
                        !youtube && (
                          <Card.Img
                            variant="top"
                            loading="lazy"
                            className="img-responsive h-100"
                            width="100%"
                            alt={"صورة ثابتة للمقالة"}
                            src={Article}
                          />
                        )
                      )}
                      {!youtube && video && (
                        <video
                          src={lang.baseUrl + "/media/videos/" + video}
                          title={title}
                        />
                      )}
                      {youtube && (
                        <iframe
                          loading="lazy"
                          width="100%"
                          className="card-iframe"
                          alt={"فيديو"}
                          src={youtube}
                        />
                      )}
                    </Link>
                    <Card.Body className="cardBody">
                      <Link
                        to={`/home/articles/${article._id}/${article.title}`}
                        className="text-decoration-none"
                      >
                        <Card.Title className="text-dark font-weight-bold">
                          <span>
                            {" "}
                            {title && title.length > 30
                              ? title.substr(0, 30) + "..."
                              : title}{" "}
                          </span>
                        </Card.Title>
                      </Link>
                      <p className="py-3 card-date">
                        <FontAwesomeIcon
                          icon={faCalendarWeek}
                          color="#ea6969"
                        />{" "}
                        <span>
                          {" "}
                          {lang.publishDate}{" "}
                          {moment(createdAt).format("DD-MM-YYYY")}
                        </span>
                      </p>

                      <Card.Text className="text-black-70">
                        {body && body.substr(0, 100)}
                        <Link
                          to={`/home/articles/${article._id}/${article.title}`}
                          className="text-decoration-none"
                        >
                          <span className="text-primary">
                            {"...اقرأ المزيد"}
                          </span>
                        </Link>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="jp_career_slider_bottom_cont">
                      <img
                        loading="lazy"
                        alt={"صورة شخصية"}
                        className="rounded-circle"
                        width="40"
                        height="40"
                        src={avatar}
                      />
                      <span className="px-2">
                        {" "}
                        {ref === "admin"
                          ? lang.admin
                          : sourceId &&
                            sourceId.name &&
                            sourceId.name.length > 30
                          ? "..." + sourceId &&
                            sourceId.name &&
                            sourceId.name.substr(0, 30)
                          : sourceId && sourceId.name}
                      </span>
                    </Card.Footer>
                  </Card>
                </div>
              );
            })}
        </div>
      </Fade>
      <Col
        className="mt-3 d-flex justify-content-center align-items-center"
        xs="auto"
      >
        <Pages
          pageSize={6}
          maxPages={3}
          total={data && data.length}
          setPage={Page}
          currentPage={currentPage}
          start={setStart}
          end={setEnd}
        />
      </Col>
    </>
  );
}

export function HomeArticlesCarouselCard(props) {
  const { lang, data } = props;
  const {
    ref,
    img,
    youtube,
    video,
    imageDescription,
    createdAt,
    sourceId,
  } = data;
  const avatar =
    ref === "admin"
      ? AdminLogo
      : ref === "organization"
      ? `${lang.baseUrl}/org/media/imgs/${sourceId && sourceId.avatar}`
      : `${lang.baseUrl}/co/media/imgs/${sourceId && sourceId.avatar}`;
  const SrcImg =
    img && img.length > 0 && ref === "admin"
      ? `${lang.baseUrl}/admin/media/imgs/${img}`
      : img && img.length > 0 && ref === "organization"
      ? `${lang.baseUrl}/org/media/imgs/${img}`
      : img && img.length > 0 && ref === "company"
      ? `${lang.baseUrl}/co/media/imgs/${img}`
      : Article;

  return (
    <Card className="h-100 img-resonsive p-0 spacing articleCard">
      <Link
        to={`/home/articles/${data._id}/${data.title}`}
        className="text-decoration-none cardBody"
      >
        {!youtube && img && img.length > 0 ? (
          <Card.Img
            variant="top"
            loading="lazy"
            className="img-responsive"
            width="100%"
            alt={imageDescription && imageDescription}
            src={SrcImg}
          />
        ) : (
          !youtube && (
            <Card.Img
              variant="top"
              loading="lazy"
              className="img-responsive"
              width="100%"
              alt={"صورة ثابتة للمقالة"}
              src={Article}
            />
          )
        )}
        {!youtube && video && (
          <video
            src={lang.baseUrl + "/media/videos/" + video}
            title={data.title}
          />
        )}
      </Link>
      <Card.Body className="cardBody">
        <Link
          to={`/home/articles/${data._id}/${data.title}`}
          className="text-decoration-none"
        >
          <Card.Title className="text-dark font-weight-bold text-right">
            <h4> {data.title && data.title.substr(0, 20)}</h4>
          </Card.Title>
        </Link>
        <p className="card-date">
          {lang.publishDate} {moment(createdAt).format("DD-MM-YYYY")}
          <FontAwesomeIcon
            icon={faCalendarWeek}
            color="#ea6969"
            className="mx-1"
          />
        </p>

        <Card.Text className="text-black-70 text-right">
          {data.body && data.body.substr(0, 100)}
          <Link
            to={`/home/articles/${data._id}/${data.title}`}
            className="text-decoration-none"
          >
            <span className="text-primary">{"...اقرأ المزيد"}</span>
          </Link>
        </Card.Text>
      </Card.Body>

      <Card.Footer className="jp_career_slider_bottom_cont">
        <span className="px-2">
          {" "}
          {ref === "admin"
            ? lang.admin
            : sourceId && sourceId.name && sourceId.name.length > 30
            ? "..." + sourceId && sourceId.name && sourceId.name.substr(0, 30)
            : sourceId && sourceId.name}
        </span>
        <img
          loading="lazy"
          alt={"صورة شخصية"}
          className="rounded-circle"
          width="40"
          height="40"
          src={avatar}
        />
      </Card.Footer>
    </Card>
  );
} ///neww by shrouk

export function CoDetailsCard(props) {
  const { co, lang } = props;

  return (
    <Container>
      <Row className="org-profile-card-container w-100">
        <Col xs="12" className="job-card">
          <Row className="job-details-card-header">
            <Col>
              <h4 className="text-dark font-weight-bold pt-2">
                {lang.aboutCo}
              </h4>
              <p className="text-right text-muted pt-2">{co.brief}</p>

              <h4 className="text-dark font-weight-bold pt-5">
                {lang.siteAvailability}
              </h4>
              <div className="row align-items-center pt-2 px-2">
                <FontAwesomeIcon
                  size="sm"
                  icon={faDoorOpen}
                  color={"#5fc0e9"}
                />
                <span className="text-right text-muted pr-2 ">
                  {lang.companyEntrance}
                  {":"} {co.companyEntrance}
                </span>
              </div>
              <div className="row align-items-center pt-2 px-2">
                <FontAwesomeIcon
                  size="sm"
                  icon={co.wheelchairElevator ? faCheckCircle : faTimesCircle}
                  color={co.wheelchairElevator ? "#5fc0e9" : "#ea6969"}
                />
                <span className="text-right text-muted pr-2 ">
                  {lang.chairElevator}
                </span>
              </div>
              <div className="row align-items-center pt-2 px-2">
                <FontAwesomeIcon
                  size="sm"
                  icon={co.wheelchairOffice ? faCheckCircle : faTimesCircle}
                  color={co.wheelchairOffice ? "#5fc0e9" : "#ea6969"}
                />
                <span className="text-right text-muted pr-2 ">
                  {lang.chairOffice}
                </span>
              </div>
              <div className="row align-items-center pt-2 px-2">
                <FontAwesomeIcon
                  size="sm"
                  icon={co.wheelchairBathroom ? faCheckCircle : faTimesCircle}
                  color={co.wheelchairBathroom ? "#5fc0e9" : "#ea6969"}
                />
                <span className="text-right text-muted pr-2 ">
                  {lang.chairBathroom}
                </span>
              </div>

              <h4 className="text-dark font-weight-bold pt-5">
                {lang.transportation}
              </h4>
              <div className="row align-items-center pt-2 px-2">
                <FontAwesomeIcon size="sm" icon={faBus} color={"#5fc0e9"} />
                <span className="text-right text-muted pr-2 ">
                  {co.transportationType
                    ? lang.transportationType + ": " + co.transportationType
                    : lang.noTransportation}
                </span>
              </div>
              {/* {co.location ?
                                <div>
                                    <h3 className='font-weight-bold'>{lang.location}</h3>
                                    <Row className='pt-3'>
                                        <iframe src={co.location} height="300px" className="w-100" allowfullscreen="" loading="lazy"></iframe>
                                    </Row>
                                </div>
                                :
                                
                            } */}
              <hr className="w-100"></hr>
              <Row>
                <Col xs="12">
                  <Row className="p-4 align-items-center">
                    <h5 className="font-weight-bold p-3"> للتواصل : </h5>
                    {co.website ? (
                      <a
                        className="text-decoration-none "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={co.website}
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faGlobe}
                          className="mx-4"
                        />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faGlobe}
                        className="mx-4"
                      />
                    )}
                    {co.linkedin ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={co.linkedin}
                        className="text-decoration-none "
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faLinkedin}
                          className="mx-4"
                        />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faLinkedin}
                        className="mx-4"
                      />
                    )}
                    {co.facebook ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={co.facebook}
                        className="text-decoration-none "
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faFacebookF}
                          className="mx-4"
                        />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faFacebookF}
                        className="mx-4"
                      />
                    )}
                    {co.twitter ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={co.twitter}
                        className="text-decoration-none "
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faTwitter}
                          className="mx-4"
                        />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faTwitter}
                        className="mx-4"
                      />
                    )}
                    {co.youtube ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={co.youtube}
                        className="text-decoration-none"
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faYoutube}
                          className="mx-4"
                        />
                      </a>
                    ) : (
                      <FontAwesomeIcon
                        size="lg"
                        color="#797979"
                        icon={faYoutube}
                        className="mx-4"
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export function ComCard(props) {
  const { onClick, data, page, lang } = props;

  return (
    <Container fluid>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={page}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <Row>
            {data &&
              data.map((card) => {
                const { img, title, location, jobs } = card;
                return (
                  <Col
                    sm="12"
                    md="6"
                    lg="6"
                    xl="3"
                    style={{ paddingRight: "7px", paddingLeft: "7px" }}
                    key={card._id}
                  >
                    <div className="card-co" onClick={onClick}>
                      <Col>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "15px",
                            backgroundColor: "#0e132726",
                            marginBottom: 10,
                          }}
                          src={img}
                          alt={""}
                        />
                      </Col>
                      <Col>
                        <TextCardTitle>{TextCardTitle && title}</TextCardTitle>
                      </Col>
                      <Col>
                        <TextCardSubtitle>
                          {location && location}
                        </TextCardSubtitle>
                      </Col>
                      <Col>
                        <Button
                          fullWidth
                          type="dark"
                          text={
                            lang.exist + " " + jobs + " " + lang.availableJobs
                          }
                        />
                      </Col>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
}

export function RegisterCard(props) {
  const { lang } = props;

  return (
    <div className="card-register-container">
      <div className="card-register-right">
        <div className="card-register-left-box">
          <i style={{ fontSize: "30px" }}>
            <FontAwesomeIcon icon={faUser} color="#5fc0e9" />
          </i>
          <TextToolbar center black>
            {lang.registerEmployeer}
          </TextToolbar>
          <TextRegSub>{lang.regDesc}</TextRegSub>
          <div style={{ paddingTop: "3%" }}>
            <Button fullWidth text={lang.regEmpBtn} />
          </div>
        </div>
      </div>
      <div className="card-register-left">
        <div className="card-register-right-box ">
          <i style={{ fontSize: "30px" }}>
            <FontAwesomeIcon icon={faDesktop} color="#5fc0e9" />
          </i>
          <TextToolbar center>{lang.registerCompany}</TextToolbar>
          <TextRegSub>{lang.regDesc}</TextRegSub>
          <div style={{ paddingTop: "3%" }}>
            <Button type="secondary" fullWidth text={lang.regCoBtn} />
          </div>
        </div>
        <div className="card-register-tag">
          <p>{lang.or}</p>
        </div>
      </div>
    </div>
  );
}
export function RegisterFooter(props) {
  const { data } = props;
  return (
    <Container fluid>
      <Row>
        <Col sm="12" md="12" lg="3" xl="3" className="register-footer">
          <TextRF>
            <CountUp suffix="+" end={data[0].number} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </TextRF>
          <TextRFSub>
            {data[0].name}
            <Col>
              <FontAwesomeIcon
                style={{ color: "#ffff" }}
                variant="primary"
                size="sm"
                icon={faBriefcase}
              />
            </Col>
          </TextRFSub>
        </Col>
        <Col sm="12" md="12" lg="3" xl="3" className="register-footer-b">
          <TextRF>
            <CountUp suffix="+" end={data[1].number} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </TextRF>
          <TextRFSub>
            {data[1].name}
            <Col xs="4">
              <FontAwesomeIcon
                style={{ color: "#ffff" }}
                variant="primary"
                size="sm"
                icon={faUser}
              />
            </Col>
          </TextRFSub>
        </Col>
        <Col sm="12" md="12" lg="3" xl="3" className="register-footer">
          <TextRF>
            <CountUp suffix="+" end={data[2].number} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </TextRF>
          <TextRFSub>
            {data[2].name}
            <Col>
              <FontAwesomeIcon
                style={{ color: "#ffff" }}
                variant="primary"
                size="sm"
                icon={faBuilding}
              />
            </Col>
          </TextRFSub>
        </Col>
        <Col sm="12" md="12" lg="3" xl="3" className="register-footer-b">
          <TextRF>
            <CountUp suffix="+" end={data[3].number} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </TextRF>
          <TextRFSub>
            {data[3].name}
            <Col>
              <FontAwesomeIcon
                style={{ color: "#ffff" }}
                variant="primary"
                size="sm"
                icon={faThLarge}
              />
            </Col>
          </TextRFSub>
        </Col>
      </Row>
    </Container>
  );
}

export function RecomCard(props) {
  const { onClick, data, lang } = props;
  return (
    <Container>
      <Row>
        {data.map((rec, index) => {
          const { name, jobTitle, img } = rec;
          return (
            <Col xs="12" sm="12" md="12" lg="6" xl="6" key={index}>
              <div style={{ margin: "5px" }}>
                <Row
                  className="recom-card d-flex align-items-center"
                  onClick={onClick}
                >
                  <Col xs="3" sm="3" md="2" lg="2" xl="2">
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "15px",
                      }}
                      src={img}
                      alt={""}
                    />
                  </Col>
                  <Col xs="9" sm="9" md="6" lg="6" xl="6">
                    <div className="serv-card-title">{name}</div>
                    <div className="d-flex align-items-center">
                      <i style={{ paddingLeft: 10 }}>
                        <FontAwesomeIcon icon={faDotCircle} color="#ea6969" />
                      </i>

                      <div className="serv-card-subtitle">{jobTitle}</div>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <div style={{ paddingTop: 10 }}>
                      <Button
                        size="sm"
                        fullWidth
                        type="dark"
                        text={lang.profile}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export function DocsCard(props) {
  const { data, remove, lang, reUpload, loading, alert } = props;
  return (
    <Container className="overflow-h mt-3 docs-card-container">
      {data.map((doc, i) => {
        return (
          <div className="docs-card" key={i}>
            {doc.name}
            <div style={{ fontSize: "18px" }}>
              {doc.status === "uploading" || doc.status === "reuploading" ? (
                <Spinner
                  style={{ marginBottom: 10 }}
                  size="sm"
                  animation="border"
                  variant="primary"
                  className="docs-card-icon"
                />
              ) : doc.status === "fail" ? (
                <FontAwesomeIcon
                  onClick={() => reUpload({ index: i, newDocs: data })}
                  icon={faSyncAlt}
                  color="#ea6969"
                  className="docs-card-icon"
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    onClick={() => remove(i)}
                    icon={faTimes}
                    color="#ea6969"
                    className="docs-card-icon"
                  />
                  <i
                    style={{ marginRight: "10px" }}
                    onClick={() =>
                      downloadFile({
                        url: "/org/api/files/" + doc.url,
                        alert,
                        lang,
                        name: doc.name,
                      })
                    }
                    title="يمكنك تنزيل الملف من هنا"
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faDownload}
                      color={loading ? "#797979" : "#5fc0e9"}
                      className="docs-card-icon"
                    />
                  </i>
                </>
              )}
            </div>
          </div>
        );
      })}
    </Container>
  );
}

import { request } from './index'


export const getStatisticsApi = async () => {
    return request({ url: "/home/statistics", method: 'get' })
}
export const getPartnersApi = async () => {
    return request({ url: "/home/partners", method: 'get' })
}
export const SendMessageApi = async (data) => {
    return request({ url: "/home/contactus", data, method: 'post', noToast: true })
}
// new by dalia // tech problems and suggestions page
export const SendTechAndProblemMessageApi = async (data) => {
    return request({ url: "/home/send-problems-suggestions", data, method: 'post', noToast: true })
}
export const EmailSubscribeApi = async (data) => {
    return request({ url: "/home/email-subscribe", data, method: 'post' })
}
// articles 

export const ArticlesApi = async () => {
    return request({ url: "/articles", method: 'get' })
}

// new by dalia // article detail page
export function ArticleApi(id) {
    return request({ url: '/articles/' + id, method: 'get' })
}
export function ArticleSearchApi(data) {
    return request({ url: '/articles/search', method: 'post', data, mute: true })
}
export function ArticleCommentsApi(data) {
    return request({ url: '/articles/comments', method: 'post', data, mute: true })
}
export function ArticleCommentApi(data) {
    return request({ url: '/pwd/api/article/comment', method: 'post', data, mute: true })
}


export const MainInfoApi = async () => {
    return request({ url: "/data/maininfo", method: 'get' })
}
export const CountriesApi = async () => {
    return request({ url: "/data/countries", method: 'get' })
}
export const DisabilitiesApi = async () => {
    return request({ url: "/data/disabilities", method: 'get' })
}
export const EducationsApi = async () => {
    return request({ url: "/data/educations", method: 'get' })
}
export const TrainingsApi = async () => {
    return request({ url: "/data/trainingsectors", method: 'get' })
}

export const OrganizationFieldsApi = async () => {
    return request({ url: "/data/organizationfields", method: 'get' })
}
export const CompanyFieldsApi = async () => {
    return request({ url: "/data/companyfields", method: 'get' })
}

export const OrganizationTypesApi = async () => {
    return request({ url: "/data/organizationtypes", method: 'get' })
}
//
export const WorkfieldsApi = async () => {
    return request({ url: "/data/workfields", method: 'get' })
}

export const JobsInfoApi = async () => {
    return request({ url: "/data/jobsinfo", method: 'get' })
}

export const SkillsApi = async () => {
    return request({ url: "/data/skills", method: 'get' })
}


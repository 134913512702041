import { request } from './index'
// helpers

export async function OrgUploadImgsApi({ file, }, onUploadProgress) {
    let data = new FormData();
    data.append("img", file);
    return await request({ data, url: '/org/api/upload-img', method: 'post', onUploadProgress })
}

export async function OrgAvatarApi(file, onUploadProgress) {
    let data = new FormData();
    data.append("img", file.file);
    return await request({ data, url: '/org/api/upload-avatar', method: 'post', onUploadProgress })
}
export function OrgProfileUpdate({ data }) {
    request({ data, url: '/org/api/edit-profile', method: 'post' })
}
export async function OrgFileUpdateApi({ file, type }) {
    const url = type === 'remove' ? '/org/api/remove-doc' : '/org/api/upload-doc'
    if (type === 'remove') {
        var data = { name: file }
    } else {
        data = new FormData();
        data.append("doc", file);
    }
    return await request({ data, url: url, method: 'post' })
}
export async function OrgUploadVideoApi({ file }, onUploadProgress) {
    let data = new FormData();
    data.append("video", file);
    return await request({ data, url: '/org/api/upload-video', method: 'post', onUploadProgress })
}
// services
export function AddServiceApi({ data, tab }) {
    request({ data, url: '/org/api/add-service', method: 'post', next: tab(2) })
}
export function removeService({ id, initServices }) {
    const data = { id: id }
    request({ data, url: '/org/api/remove-service', method: 'post', next: initServices() })

}
export function getServices(type) {
    const url = type === 'onhold' ? '/org/api/onhold-services' : '/org/api/active-services'
    return request({ url: url, method: 'get' }) || []
}
export function EditServiceApi({ data, }) {
    request({ data, url: '/org/api/edit-service', method: 'post' })
}
export function holdService({ id, initServices, hold }) {
    const data = { id: id }
    const url = hold ? '/org/api/hold-service' : '/org/api/activate-service'
    request({ data, url: url, method: 'post', next: initServices })
}

//branches

export function AddBranchApi({ data, initData }) {
    request({ data, url: '/org/api/add-branch', method: 'post', next: initData })
}
export function removeBranch({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/org/api/remove-branch', method: 'post', next: initData })

}
export function EditBranchApi({ data, initData }) {
    request({ data, url: '/org/api/edit-branch', method: 'post', next: initData })
}
export function getBranches() {
    return request({ url: '/org/api/branches', method: 'get' })
}
export function getCompanies() {
    return request({ url: '/org/api/companies', method: 'get' })
}
export function getReadyCompanies() {
    return request({ url: '/org/api/companies/ready', method: 'get' })
}
export function OrgCompaniesLinkedApi() {
    return request({ url: '/org/api/companies/linked', method: 'get' })
}
export function RemoveCompanyApi({ id, initData }) {
    const data = { companyId: id }
    return request({ url: '/org/api/companies/unlink', data, method: 'post', next: initData })
}
export function OrgCompaniesLinkApi({ data, initData }) {
    return request({ url: '/org/api/companies/link', data, method: 'post', next: initData })
}
// employments 

export function AddJobApi({ data, tab }) {
    request({ data, url: '/org/api/add-job', method: 'post', next: tab(2) })
}
export function removeJobApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/org/api/remove-job', method: 'post', next: initData })
}
export function getJobsApi(type) {
    const url = type === 'terminated' ? '/org/api/terminated-jobs' : '/org/api/jobs'
    return request({ url: url, method: 'get' })
}
export function editJobApi({ data }) {
    request({ data, url: '/org/api/edit-job', method: 'post' })
}
export function terminateJobApi({ id, initData,terminate }) {
    const data = { id: id }
    const url = terminate ? '/org/api/terminate-job' :  '/org/api/activate-job'
    request({ data, url: url , method: 'post', next: initData })
} 
//added condition to activate job after termination

export function applicantAcceptApi(data) {
    const type = data.company ? '/co' : '/org'
    const url = data.service ? type + '/api/service/accepted' : type + '/api/job/accepted'
    request({ data, url: url, method: 'post' })
}
export function applicantSuitableApi(data) {
    const type = data.company ? '/co' : '/org'
    const url = data.service ? type + '/api/service/suitable' : type + '/api/job/suitable'

    request({ data, url: url, method: 'post' })
}
export function applicantProfileSeenApi(data) {
    const type = data.company ? '/co' : '/org'

    const url = data.service ? type + '/api/service/profile-seen' : type + '/api/job/profile-seen'

    return request({ data, url: url, method: 'post', mute: true })
}

// new // BY DALIA
export function applicantHireApi(data) {
    const type = data.company ? '/co' : '/org'
    const url = data.service ? type + '/api/service/hired' : type + '/api/job/hired'
    request({ data, url: url, method: 'post' })
}

// posts
export function AddArticleApi({ data, tab }) {
    request({ data, url: '/org/api/add-article', method: 'post', next: tab(3) })
}
export function AddVideoApi({ data, tab }) {
    request({ data, url: '/org/api/add-article-video', method: 'post', next: tab(3) })
}
export function EditPostApi({ data, initData }) {
    request({ data, url: '/org/api/edit-article', method: 'post', next: initData })
}

export function RemovePostApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/org/api/remove-article', method: 'post', next: initData })
}

export function getPostsApi(id) {
    return request({ url: '/org/api/articles/' + id, method: 'get' })
}
export function orgArticleCommentApi(data) {
    return request({ url: '/org/api/article/comment', method: 'post', data, mute: true })
}
// push notifications
export function AddNotificaionApi({ data, tab }) {
    const t = data.active ? 3 : 2
    request({ data, url: '/org/api/add-notification', method: 'post', next: tab(t) })
}
export function getNotificationsApi(type) {
    const url = type === 'hold' ? '/org/api/hold-notifications' : '/org/api/push-notifications'
    return request({ url: url, method: 'get' })
}
export function removeNotificationApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/org/api/remove-notification', method: 'post', next: initData })

}
export function EditNotificationApi({ data, closeModal }) {
    request({ data, url: '/org/api/edit-notification', method: 'post', next: closeModal })
}
// notifications
export function OrgNotificationsApi() {
    return request({ url: '/org/api/notifications', method: 'get' })
}
export function OrgNSeenApi(id) {
    const data = { notificatonId: id }
    return request({ url: '/org/api/notification-seen', method: 'post', data, mute: true })
}
// settings
export function resetPassowrdApi(data) {
    return request({ url: '/org/api/reset-password', method: 'post', data })
}

// pwd contact 
export function PwdSendMessageApi(data) {
    return request({ url: '/org/api/messages/send', method: 'post', data, mute: true })
}

export function PwdMessageApi(data) {
    return request({ url: '/org/api/messages', method: 'post', data, mute: true })
}

export function PwdVCallApi(data) {
    return request({ url: '/org/api/messages/call', method: 'post', data, muteSuccess: true })
}
export function OrgReplayMessageApi(data) {
    return request({ url: '/org/api/messages/replay', method: 'post', data, mute: true })
}
export function OrgMsgsContactsApi() {
    return request({ url: '/org/api/messages/contacts', method: 'get', mute: true })
}
export function OrgMsgSeenApi(data) {
    return request({ url: '/org/api/message/seen', method: 'post', data, mute: true })
}
export function GetOrgMessagesApi() {
    return request({ url: '/org/api/messages', method: 'get', mute: true })
}
// ----------------------------------------------------------------------------



import {
    Route, Routes,
    useNavigate
} from 'react-router-dom';
import React, { lazy, Suspense } from 'react'
import { ErrorPage, SplachPage } from './Components'
import useAuth, { AuthProvider } from "./Api/auth";

// home
const Home = lazy(() => import('./Home/index'));
const HomeMain = lazy(() => import('./Home/Main'));
const HomeJobs = lazy(() => import('./Home/Jobs/main'));
const Job = lazy(() => import('./Home/Jobs/job'));
const HomeServices = lazy(() => import('./Home/Services/main'));
const Service = lazy(() => import('./Home/Services/service'));
const HomeOrg = lazy(() => import('./Home/Organizations/main'));
const Organization = lazy(() => import('./Home/Organizations/organization'));
const HomeCom = lazy(() => import('./Home/Companies/main'));
const Company = lazy(() => import('./Home/Companies/company'));
const HomeArticles = lazy(() => import('./Home/Articles/articles'));
const Article = lazy(() => import('./Home/Articles/article'));
const ContactUs = lazy(() => import('./Home/ContactUs'));
const TechProblemsAndSuggestions = lazy(() => import('./Home/TechProblemsAndSuggestions'));

const CoLogin = lazy(() => import('./LoginRegister/coLogin'));
const OrgLogin = lazy(() => import('./LoginRegister/orgLogin'));
const PWDLogin = lazy(() => import('./LoginRegister/pwdLogin'));

const CoRegister = lazy(() => import('./LoginRegister/coRegister'));
const OrgRegister = lazy(() => import('./LoginRegister/orgRegister'));
const PwdRegister = lazy(() => import('./LoginRegister/pwdRegister'));

// org
const Org = lazy(() => import('./Organizations/index'));
// co
const Co = lazy(() => import('./Companies/index'));
// pwd
const Pwd = lazy(() => import('./PWD/index'));

// forget password
const RequestLinkForm = lazy(() => import('./ForgetPassword/requestLinkForm'))
const Confirmation = lazy(() => import('./ForgetPassword/confirmation'))
const ResetPasswordForm = lazy(() => import('./ForgetPassword/resetPasswordForm'))
const Success = lazy(() => import('./ForgetPassword/success'))
const LinkExpired = lazy(() => import('./ForgetPassword/linkExpired'))

function Router(props) {
    const { user, statistics, mainInfo, partners } = useAuth()
    const navigate = useNavigate()
    return (
        <Routes>
            {/** Authenticated Routes - update routes // new // BY SHROUK*/}
            <Route path="/org" element={<Org mainInfo={mainInfo} user={user} {...props} />} />
            <Route path="/pwd" element={<Pwd navigate={navigate} mainInfo={mainInfo} user={user} {...props} />} />
            <Route path="/co" element={<Co mainInfo={mainInfo} user={user} {...props} />} />

             <Route  path="/" element={<Home navigate={navigate} mainInfo={mainInfo} user={user} {...props} />}>
                <Route path="/home" element={<HomeMain navigate={navigate} mainInfo={mainInfo} partners={partners} statistics={statistics}{...props} />} />
                <Route path="/home/jobs" element={<HomeJobs navigate={navigate} {...props} />} />
                <Route path="/home/jobs/:id" element={<Job navigate={navigate} {...props} />} />
                <Route path="/home/services" element={<HomeServices navigate={navigate} {...props} />} />
                <Route path="/home/services/:id" element={<Service navigate={navigate} {...props} />} />
                <Route path="/home/organizations" element={<HomeOrg navigate={navigate} {...props} />} />
                <Route path="/home/organizations/:id" element={<Organization navigate={navigate} {...props} />} />
                <Route path="/home/companies" element={<HomeCom navigate={navigate} {...props} />} />
                <Route path="/home/companies/:id" element={<Company navigate={navigate} {...props} />} />
                <Route path="/home/articles" element={<HomeArticles navigate={navigate} {...props} />} />
                <Route path="/home/articles/:id/:title" element={<Article navigate={navigate} {...props} />} />
                <Route path="/home/ContactUs" element={<ContactUs mainInfo={mainInfo} {...props} />} />
                <Route path="/home/tech-problems-and-suggestions" element={<TechProblemsAndSuggestions mainInfo={mainInfo} {...props} />} />
                <Route path='' element={<HomeMain navigate={navigate} mainInfo={mainInfo} partners={partners} statistics={statistics}{...props} />} />
            </Route>

            <Route path="/CoLogin" element={<CoLogin mainInfo={mainInfo} {...props} />} />
            <Route path="/OrgLogin" element={<OrgLogin mainInfo={mainInfo} {...props} />} />
            <Route path="/PwdLogin" element={<PWDLogin mainInfo={mainInfo} {...props} />} />
            <Route path="/CoRegister" element={<CoRegister mainInfo={mainInfo} {...props} />} />
            <Route path="/OrgRegister" element={<OrgRegister mainInfo={mainInfo} {...props} />} />
            <Route path="/PwdRegister" element={<PwdRegister mainInfo={mainInfo} {...props} />} />
            <Route path='/pass-request/:userType' element={<RequestLinkForm mainInfo={mainInfo} {...props} />}/>
            <Route path="/:input" element={<Confirmation {...props} />}/>
            <Route path='/reset/:userType/:userId/:token' element={<ResetPasswordForm {...props} />} />
            <Route path="/pass-reset-success/:userType" element={<Success {...props} />}/>
            <Route path="/link-expired/:userType" element={<LinkExpired {...props} />}/>
            <Route path="/network-error" element={<ErrorPage {...props} />} />
            <Route path="*" element={<Home navigate={navigate} mainInfo={mainInfo} partners={partners} statistics={statistics} user={user} {...props} />} />
        </Routes>
    );
}


export default function Routers(props) {

    return (
        <Suspense fallback={<SplachPage />}>
            <AuthProvider {...props}>
                <Router {...props} fallback={<SplachPage />} />
            </AuthProvider>
        </Suspense>
    );
}
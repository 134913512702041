import axios from "axios";
import { Alert } from "../Components";

export function logout() {
  localStorage.clear();
  // need to check
}

export const request = async ({
  url,
  data,
  method,
  onUploadProgress,
  next,
  mute,
  muteSuccess,
  onDownloadProgress,
  noToast,
}) => {
  let req = await axios({
    method: method,
    url: url,
    data: data,
    onUploadProgress,
    onDownloadProgress,
    timeout: 10000,
  })
    .then((response) => {
      if (response.data.success) {
        !mute &&
          !muteSuccess &&
          Alert({
            msg: response.data.msg,
            type: "success",
            toast: noToast ? false : true,
          });
        next && next();
        if (response.data.data) return response.data.data;
        else return response.data;
      }
      if (!response.data.success) {
        if (method === "post" && !mute)
          Alert({ msg: response.data.msg, type: "warning" });
      }
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) return logout();
      else return { success: false, error: true, msg: error.message };
    });
  return req;
};
// assets functions
export const getOrgUser = async (token) => {
  axios.defaults.headers.common["x-access-token"] = token || false;
  return request({ url: "/org/api/profile", method: "get" });
};
export const getCoUser = async (token) => {
  axios.defaults.headers.common["x-access-token"] = token || false;
  return request({ url: "/co/api/profile", method: "get" });
};
export const getPwdUser = async (token) => {
  axios.defaults.headers.common["x-access-token"] = token || false;
  return request({ url: "/pwd/api/profile", method: "get" });
};
export const downloadFile = async ({ url, name, lang }) => {
  axios({
    method: "get",
    url: url,
    responseType: "arraybuffer",
  })
    .then((response) => {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      link.download = name;
      document.body.appendChild(link);

      link.click();
      setTimeout(function() {
        window.URL.revokeObjectURL(link);
      }, 200);
    })
    .catch((error) => {
      if (error.response.status === 580)
        return Alert({ type: "warning", msg: lang.notAllowed });
    });
};
export async function UploadImgsApi({ file }, onUploadProgress) {
  let data = new FormData();
  data.append("img", file);
  return await request({
    data,
    url: "/upload-img",
    method: "post",
    onUploadProgress,
  });
}

export * from "./home";
export * from "./organizations";
export * from "./companies";
export * from "./pwd";
export * from "./login";
export * from "./register";
export * from "./forgetPassword";

import { request } from './index'

export function OrgLoginApi(data) {
    return request({ data, url: '/org/login', method: 'post' })
}

export function PwdLoginApi(data) {
    return request({ data, url: '/pwd/login', method: 'post' })
}

export function CoLoginApi(data) {
    return request({ data, url: '/co/login', method: 'post' })
}
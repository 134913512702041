import { request } from './index'

// To send email request to reset password
export function SendRequestLinkApi(data, userType) {
    return request({ url: `/forgot_password/${userType}`, method: 'post', data })
}

/****************************************************** */

// To check if Token exists with this pwdId
export function PwdGetTokenByID(id) {
    return request({ url: '/pwd/tokens/' + id, method: 'get' })
}

// To reset password for pwd
export function PwdForgetAndResetPassowrdApi(data) {
    return request({ url: `/pwd/reset_password/${data.token}`, method: 'post', data })
}

/****************************************************** */

// To check if Token exists with this orgId
export function OrgGetTokenByID(id) {
    return request({ url: '/org/tokens/' + id, method: 'get' })
}

// To reset password for org
export function OrgForgetAndResetPassowrdApi(data) {
    return request({ url: `/org/reset_password/${data.token}`, method: 'post', data })
}

/****************************************************** */

// To check if Token exists with this coId
export function CoGetTokenByID(id) {
    return request({ url: '/co/tokens/' + id, method: 'get' })
}

// To reset password for co
export function CoForgetAndResetPassowrdApi(data) {
    return request({ url: `/co/reset_password/${data.token}`, method: 'post', data })
}
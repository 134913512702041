import { request } from './index'

export async function OrgRegisterApi(data) {
    return request({ data, url: '/org/register', method: 'post' })
}
export async function CoRegisterApi(data) {
    return request({ data, url: '/co/register', method: 'post' })
}
export async function PwdRegisterApi(data) {
    console.log(data)
    return request({ data, url: '/pwd/register', method: 'post' })
}
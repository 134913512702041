
export const workTypes = [
    { _id: '--', name: '--' },
    { _id: 'دوام كامل', name: 'دوام كامل', },
    { _id: 'دوام جزئي', name: 'دوام جزئي', },
]
export const serviceTypes = [
    { value: 'دورات تدريبية', name: 'دورات تدريبية' },
    { value: 'ورش', name: 'ورش' },
    { value: 'توظيف', name: 'توظيف' },

]
export const sex = [
    { _id: '--', name: '--' },
    { _id: 'ذكر', name: 'ذكر' },
    { _id: 'أنثى', name: 'أنثى' },
]
export const maritalStatus = [
    {_id: '--', name: '--'},
    { _id: 'أعزب', name: 'أعزب' },
    { _id: 'متزوج', name: 'متزوج' },
    { _id: 'أرمل', name: 'أرمل' },
    { _id: 'مطلق', name: 'مطلق' },
]
export const nationalities = [
    { _id: 'مصري', name: 'مصري' },
]
export const martialServiceStatus = [
    { _id: 'معاف', name: 'معاف' },
    { _id: 'أديت الخدمة', name: 'أديت الخدمة' },
]
export const socialInsuranceStatus = [
    { _id: 'مؤمن علية', name: 'مؤمن علية' },
    { _id: 'غير مؤمن عليه', name: 'غير مؤمن علية' },
]
export const contactRelations = [
    { _id: 'أب', name: 'أب' },
    { _id: 'أم', name: 'أم' },
    { _id: 'أخ', name: 'أخ' },
    { _id: 'أخت', name: 'أخت' },
    { _id: 'عم', name: 'عم' },
    { _id: 'عمة', name: 'عمة' },
    { _id: 'خال', name: 'خال' },
    { _id: 'خالة', name: 'خالة' },
    { _id: 'ابن خالة', name: 'ابن خالة' },
    { _id: 'بنت خالة', name: 'بنت خالة' },
    { _id: 'بنت عمة', name: 'بنت عمة' },
    { _id: 'ابن عم', name: 'ابن عم' },
    { _id: 'بنت عم', name: 'بنت عم' },

]
export const disEnabled = [
    { _id: 'إتاحة بصرية', name: 'إتاحة بصرية' },
    { _id: 'إتاحة سمعية', name: 'إتاحة سمعية' },
    { _id: 'إتاحة حركية', name: 'إتاحة حركية' },
    { _id: 'إتاحة كلامية', name: 'إتاحة كلامية' },
    { _id: 'إتاحة ذهنية', name: 'إتاحة ذهنية' },
]
export const certificateTypes = [
    { _id: 'دبلومة', name: 'دبلومة' },
    { _id: 'شهاده حضور', name: 'شهاده حضور' },
]
export const salary = [
    { _id: 3000, name: 'من 1500 إلى 3000 ج.م' },
    { _id: 5000, name: 'من 3000 إلى 5000 ج.م' },
    { _id: 'more', name: 'اكثر من 5000 ج.م' },
]
export const experinces = [
    { value: '1', name: 'خريج' },
    { value: '2', name: 'مبتدئ' },
    { value: '4', name: 'متوسط' },
    { value: '5', name: 'متقدم' },
    { value: 'more', name: 'خبير' },

]
export const pwdSuitables = [
    { value: true, name: 'تصلح له', color: '#63d09c' },
    { value: false, name: 'لا تصلح له', color: '#ea6969' }
]
export const pwdStatus = [
    { value: true, name: 'قبول', color: '#5fc0e9' },
    { value: false, name: 'رفض', color: '#ea6969' }
]
export const status = [
    { value: true, name: 'نعم', color: '#5fc0e9' },
    { value: false, name: 'لا', color: '#ea6969' }
]

export const pwdHire = [
    { value: true, name: 'يتم تعيينه', color: '#5fc0e9' },
    { value: false, name: 'لايتم تعيينه', color: '#ea6969' }
]

import './css/progress.css'

export function JobSteps({ lang, jobStatus }) {
    const { profileSeen, suitable, accepted, hired } = jobStatus
    const s1 = profileSeen ? 'active' : ''
    const s2 = typeof suitable === 'undefined' ? '' : (suitable ? 'suitable' : 'notSuitable')
    const s3 = typeof accepted === 'undefined' ? '' : (accepted ? 'suitable' : 'notSuitable')
    const s4 = typeof hired === 'undefined' ? '' : (hired ? 'suitable': 'notSuitable')
    return (
        <div className="progressbar-wrapper">
            <ul className="progressbar" >
                <li className={s1}>{lang.profile}<br />({typeof profileSeen === 'undefined' ? lang.pwdProfileNotSeen : lang.pwdProfileSeen})</li>
                <li className={s2}>{lang.pwdJobSuitableStatus}<br />({typeof suitable === 'undefined' ? lang.pwdJobPending : (suitable ? lang.pwdJobSuitable : lang.pwdJobNotSuitable)})</li>
                <li className={s3}>{lang.pwdJobAcceptanceStatus}<br />({typeof accepted === 'undefined' ? lang.pwdJobPending : (accepted ? lang.pwdJobAccepted : lang.pwdJobRejected)})</li>
                <li className={s4}>{lang.employment}<br />({typeof hired === 'undefined' ? lang.pwdJobPending : (hired ? lang.pwdJobHired : lang.pwdJobNotHired)})</li>
            </ul>
        </div>
    )
}

export function ServiceSteps({ lang, serviceStatus }) {
    const { profileSeen, suitable, accepted } = serviceStatus
    const s1 = profileSeen ? 'active' : ''
    const s2 = typeof suitable === 'undefined' ? '' : (suitable ? 'suitable' : 'notSuitable')
    const s3 = typeof accepted === 'undefined' ? '' : (accepted ? 'suitable' : 'notSuitable')
    return (

        <div className="progressbar-wrapper">
            <ul className="service-progressbar" >
                <li className={s1}>{lang.profile}<br />({typeof profileSeen === 'undefined' ? lang.pwdProfileNotSeen : lang.pwdProfileSeen})</li>
                <li className={s2}>{lang.pwdServiceSuitableStatus}<br />({typeof suitable === 'undefined' ? lang.pwdServicePending : (suitable ? lang.pwdServiceMatch : lang.pwdServiceNotMatch)})</li>
                <li className={s3}>{lang.pwdServiceStatus}<br />({typeof accepted === 'undefined' ? lang.pwdServicePending : (accepted ? lang.pwdServiceAccepted : lang.pwdServiceRejected)})</li>

            </ul>
        </div>

    )
}
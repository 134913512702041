import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App/Assets/theme/app.scss';
import { BrowserRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css";//neww
import "slick-carousel/slick/slick-theme.css";//neww 
import reportWebVitals from './reportWebVitals'


// use BrowserRouter // new // BY SHROUK
ReactDOM.render(
  <BrowserRouter>
    <div className='main-root'>
        <App />
    </div>
    </BrowserRouter>,
  document.getElementById('root')
);
document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();

import { Col, Row } from 'react-bootstrap'
import './css/pwdCards.css'
import { TextCardTitle, JobSteps, ServiceSteps, TextCardSubtitle, TextRegSub, Button } from '../Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Job from '../Assets/imgs/jobs.webp'

export function PwdContactCard({ lang, data, edit, remove }) {
    const { contactRelation, contactMobile, contactEmail, contactName } = data
    return (
        <div className='pwd-contact-container'>
            <TextCardTitle align='right'>
                {contactName}
            </TextCardTitle>
            <TextRegSub right size='16px' >
                {lang.pwdContactRelation} <span style={{ color: 'black' }} >{contactRelation}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.mobileNumber}{': '}<span style={{ color: 'black' }} >{contactMobile}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.email}{': '}<span style={{ color: 'black' }} >{contactEmail}</span>
            </TextRegSub>

            <Row className='pt-3' >
                <Col>
                    <Button onClick={() => edit(data)} fullWidth text={lang.edit} size='sm' />
                </Col>
                <Col>
                    <Button onClick={() => remove(data)} fullWidth type='outline-secondary' text={lang.delete} size='sm' />
                </Col>
            </Row>
        </div>

    )

}
export function PwdEduCard({ lang, data, edit, remove }) {
    const { educationLevel, educationCertificate, educationCertType, educationDate, educationPlace } = data
    const certType = educationLevel.type && educationLevel.type.find(t => t._id === educationCertType)
    const cert = certType && certType.cert.find(c => c._id === educationCertificate)
    return (
        <div className='pwd-contact-container'>
            <TextCardTitle align='right'>
                {cert && cert.name}
            </TextCardTitle>
            <TextRegSub right size='16px' >
                {lang.pwdEdu} <span style={{ color: 'black' }} >{educationLevel.name}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdTrainingCertType}{': '}<span style={{ color: 'black' }} >{certType && certType.name}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdEduPlace}{': '}<span style={{ color: 'black' }} >{educationPlace}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdGradDate}{': '}<span style={{ color: 'black' }} >{moment(educationDate).format('YYYY/MM/DD')}</span>
            </TextRegSub>
            <Row className='pt-3' >
                <Col>
                    <Button onClick={() => edit(data)} fullWidth text={lang.edit} size='sm' />
                </Col>
                <Col>
                    <Button onClick={() => remove(data)} fullWidth type='outline-secondary' text={lang.delete} size='sm' />
                </Col>
            </Row>
        </div>

    )

}
export function PwdDisabilityCard({ lang, data, edit, remove }) {
    const { disabilityType, disabilityDescription, disabilityReason, disabilityReasonDescription } = data
    const dis = disabilityType.type && disabilityType.type.find(value => value._id === disabilityDescription)
    const disType = dis && dis.name || ''
    return (
        <div className='pwd-contact-container'>
            <TextCardTitle align='right'>
                {disabilityType.name}
            </TextCardTitle>
            <TextRegSub right size='16px' >
                {lang.pwdDisabilityType}{': '}<span style={{ color: 'black' }} >{disType}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdDisabilityReason}{': '}<span style={{ color: 'black' }} >{disabilityReason}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdReasonDescription}{': '}<span style={{ color: 'black' }} >{disabilityReasonDescription}</span>
            </TextRegSub>

            <Row className='pt-3' >
                <Col>
                    <Button onClick={() => edit(data)} fullWidth text={lang.edit} size='sm' />
                </Col>
                <Col>
                    <Button onClick={() => remove(data)} fullWidth type='outline-secondary' text={lang.delete} size='sm' />
                </Col>
            </Row>
        </div>

    )

}
export function PwdTrainingCard({ lang, data, edit, remove }) {
    const { trainingName, trainingSector, speciality, trainingCode, certificateType, certificateDate } = data
    const trSector = trainingSector.type && trainingSector.type.find(t => t._id === speciality)
    return (
        <div className='pwd-contact-container'>
            <TextCardTitle align='right'>
                {trainingName}
            </TextCardTitle>
            <Row>
                <Col xs='12' sm='12' md='4' lg='4' xl='4'  >
                    <TextRegSub right size='16px' >
                        {lang.pwdTrainingSector}{': '}<span style={{ color: 'black' }} >{trainingSector.name}</span>
                    </TextRegSub>
                </Col>
                <Col xs='12' sm='12' md='4' lg='4' xl='4'  >
                    <TextRegSub right size='16px'>
                        {lang.pwdTrainingSpeciality}{': '}<span style={{ color: 'black' }} >{trSector && trSector.name}</span>
                    </TextRegSub>
                </Col>
                <Col xs='12' sm='12' md='4' lg='4' xl='4'  >

                    <TextRegSub right size='16px'>
                        {lang.pwdTrainingCode}{': '}<span style={{ color: 'black' }} >{trainingCode}</span>
                    </TextRegSub>
                </Col>
            </Row>

            <Row>
                <Col xs='12' sm='12' md='4' lg='4' xl='4'  >
                    <TextRegSub right size='16px' >
                        {lang.pwdTrainingCertType}{': '}<span style={{ color: 'black' }} >{certificateType}</span>
                    </TextRegSub>
                </Col>
                <Col xs='12' sm='12' md='4' lg='4' xl='4'  >
                    <TextRegSub right size='16px'>
                        {lang.pwdTrainingDate}{': '}<span style={{ color: 'black' }} >{moment(certificateDate).format('YYYY/MM/DD')}</span>
                    </TextRegSub>
                </Col>
            </Row>


            <Row className='pt-3' >
                <Col>
                    <Button onClick={() => edit(data)} fullWidth text={lang.edit} size='sm' />
                </Col>
                <Col>
                    <Button onClick={() => remove(data)} fullWidth type='outline-secondary' text={lang.delete} size='sm' />
                </Col>
            </Row>
        </div>

    )

}
export function PwdExperienceCard({ lang, data, edit, remove }) {
    const { jobTitle, companyName, startDate, endDate, jobDescription, skillsTags, paidSalary } = data
    return (
        <div className='pwd-contact-container'>
            <TextCardTitle align='right'>
                {jobTitle.name}
            </TextCardTitle>
            <Row>
                <Col xs='12' sm='12' md='6' lg='6' xl='6'  >
                    <TextRegSub right size='16px' >
                        {lang.pwdExpCompany}{': '}<span style={{ color: 'black' }} >{companyName}</span>
                    </TextRegSub>
                </Col>
                <Col xs='12' sm='12' md='6' lg='6' xl='6'  >
                    <TextRegSub right size='16px'>
                        {lang.pwdExpSalary}{': '}<span style={{ color: 'black' }} >{paidSalary}</span>
                    </TextRegSub>
                </Col>
            </Row>
            <Row>
                <Col xs='12' sm='12' md='6' lg='6' xl='6'  >
                    <TextRegSub right size='16px' >
                        {lang.pwdExpStartDate}{': '}<span style={{ color: 'black' }} >{moment(startDate).format('YYYY/MM/DD')}</span>
                    </TextRegSub>
                </Col>
                <Col xs='12' sm='12' md='6' lg='6' xl='6'  >
                    <TextRegSub right size='16px'>
                        {lang.pwdExpEndDate}{': '}<span style={{ color: 'black' }} >{moment(endDate).format('YYYY/MM/DD')}</span>
                    </TextRegSub>
                </Col>
            </Row>

            <TextRegSub right size='16px'>
                {lang.pwdExpDesc}{': '}<span style={{ color: 'black' }} >{jobDescription}</span>
            </TextRegSub>
            <TextRegSub right size='16px'>
                {lang.pwdSkills}{': '}<span style={{ color: 'black' }} >{skillsTags.map(t => t.skillId.name).join(', ')}</span>
            </TextRegSub>

            <Row className='pt-3' >
                <Col>
                    <Button onClick={() => edit(data)} fullWidth text={lang.edit} size='sm' />
                </Col>
                <Col>
                    <Button onClick={() => remove(data)} fullWidth type='outline-secondary' text={lang.delete} size='sm' />
                </Col>
            </Row>
        </div>

    )

}
export function PwdJobCard({ data, lang, showJob }) {

    const { img, jobTitle, companyId, minSalary, maxSalary, workType, status, ref, sourceId } = data
    const srcImg = img && img.length > 0 && ref === 'organization' ? `${lang.baseUrl}/org/media/imgs/${img}` : img && img.length > 0 && ref === 'company' ? `${lang.baseUrl}/co/media/imgs/${img}` : Job

    return (
        <div className='pwd-job-container'>
            <div className='pwd-job-label'>
                {workType}
            </div>
            <Row>
                <Col md='auto' xs='12' sm='12'>
                    <img style={{
                        width: '110px',
                        height: '110px',
                        borderRadius: '15px',
                    }} src={srcImg} alt={'صورة خلفية للوظيفة'} loading="lazy"/>
                </Col>
                <Col md='9' xs='12' sm='12' >
                    <Row>
                        <Col xs='9' sm='9' md='9' lg='10' xl='10' >
                            <TextCardTitle align="right" >
                                {jobTitle}
                            </TextCardTitle>
                        </Col>
                    </Row>
                    <TextCardSubtitle color='#5fc0e9' align="right">
                        {companyId !== null ? companyId.name : sourceId.name}
                    </TextCardSubtitle>
                    <Row >
                        <Col xs='12' sm='12' md='6' lg='auto' xl='auto' className='pt-2' >
                            <TextCardSubtitle color='#000000' align="right">
                                <i><FontAwesomeIcon icon={faDollarSign} size='sm' color='#ff4136' /></i> {lang.salary} {minSalary} {':'} {maxSalary} {lang.pound}
                            </TextCardSubtitle>
                        </Col>
                        <Col xs='12' sm='12' md='6' lg='auto' xl='auto' className='pt-2' >
                            <TextCardSubtitle color='#000000' align="right">
                                <a href={companyId !== null ? companyId.location : sourceId.location} target="_blank" rel="noopener noreferrer">
                                    <i ><FontAwesomeIcon icon={faMapMarkerAlt} size='sm' color='#ff4136' /></i> {companyId !== null ? companyId.address : sourceId.address}
                                </a>
                            </TextCardSubtitle>
                        </Col>
                        <Col xs='12' sm='12' md='12' lg='3' className='pt-2'  >
                            <Button onClick={() => showJob(data)} type='outline-primary' size='sm' fullWidth text={lang.pwdShowJob} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '4%' }}>
                <JobSteps lang={lang} jobStatus={status} />
            </div>
        </div>
    )
}
export function PwdSerCard({ data, lang, showService }) {

    const { img, name, branchId, status, amount } = data
    const srcImg = img && img.length > 0 ? `${lang.baseUrl}/org/media/imgs/${img}` : Job

    return (
        <div className='pwd-job-container'>
            <Row className='d-flex align-items-center'>
                <Col md='auto' xs='12' sm='12'>
                    <img style={{
                        width: '70px',
                        height: '70px',
                        borderRadius: '15px',
                    }} src={srcImg} alt={'صورة خلفية للخدمة'} loading="lazy"/>
                </Col>
                <Col md='9' xs='12' sm='12' >
                    <Row className='align-items-center '   >
                        <Col  >
                            <TextCardTitle lineHeight='2.5' align="right" >
                                {name}
                            </TextCardTitle>
                            <TextCardSubtitle color='#5fc0e9' align="right">
                                {branchId.name}
                            </TextCardSubtitle>
                            <TextCardSubtitle color='#000000' align="right">
                                <i><FontAwesomeIcon icon={faDollarSign} size='sm' color='#ff4136' /></i> {lang.salary} {amount}  {lang.pound}
                            </TextCardSubtitle>
                        </Col>
                        <Col xs='12' sm='12' md='4'>

                            <Button onClick={() => showService(data)} margin type='outline-primary' size='sm' fullWidth text={lang.pwdShowServiceBtn} />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '4%' }}>
                <ServiceSteps lang={lang} serviceStatus={status} />
            </div>
        </div>
    )
}

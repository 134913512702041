import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faBell, faChevronLeft, faEnvelope, faCog, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { TextHeader, TextLinks, DashTitle, DashSub } from './index'
import { Navbar, Nav, Image, Container, Row, Col, Dropdown } from 'react-bootstrap'
import { useNavigate} from 'react-router-dom';
import Logo from '../Assets/imgs/logo.webp'
import useAuth from "../Api/auth";
import '../Components/css/header.css'
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


export function Header(props) {
    const { logout, notifications, perNotifications, messages } = useAuth()
    const type = '/' + localStorage.getItem('userType') || false
    let navigate = useNavigate();
    const { lang, user, navLinks, nav, setNav, dashboard, currentNav, mainInfo } = props
    const handleNav = (nav) => {
        setNav(nav)
        navigate(nav.href)
    }
    const PwdLogin = () => {
        navigate('/PwdLogin')

    }

    const CoLogin = () => {
        navigate('/CoLogin')

    }

    const OrgLogin = () => {
        navigate('/OrgLogin')

    }

    const msgs = () => {
        var ms = 0
        messages && messages.map(el => {
            return el.messages && el.messages.map(e => ms = (!e.owner && !e.seen) ? ms + 1 : ms)
        })
        return ms
    }

    // check for all notifications that pwds received // new // BY DALIA
    const pushNots = notifications && notifications.length > 0 && notifications.filter(v => !v.seen).length > 0 && notifications.filter(v => !v.seen).length
    const perNots = perNotifications && perNotifications.length > 0 && perNotifications.filter(v => !v.seen).length > 0 && perNotifications.filter(v => !v.seen).length

    const userAvatar = ({ type, avatar }) => {
        switch (type) {
            case '/pwd': return lang.baseUrl + '/pwd/media/imgs/' + avatar
            case '/org': return lang.baseUrl + '/org/media/imgs/' + avatar
            case '/co': return lang.baseUrl + '/co/media/imgs/' + avatar
            default:
                break;
        }
    }//neww

    return (
        <div>
            <div className='nabbar-top w-100'>
                <Navbar expand="lg" bg="primary">
                    <Col className='text-right pr-5'>
                        <Row>
                            <Nav.Link aria-label={lang.linkedin} target="_blank" href={mainInfo && mainInfo.linkedin} title="لينكدان">
                                <FontAwesomeIcon color='#ffffff' size='lg' icon={faLinkedin} />
                            </Nav.Link>
                            <Nav.Link aria-label={lang.youtube} target="_blank" href={mainInfo && mainInfo.youtube} title="يوتيوب">
                                <FontAwesomeIcon color='#ffffff' size='lg' icon={faYoutube} />
                            </Nav.Link>

                            <Nav.Link aria-label={lang.twitter} target="_blank" href={mainInfo && mainInfo.twitter} title="تويتر">
                                <FontAwesomeIcon color='#ffffff' size='lg' icon={faTwitter} />
                            </Nav.Link>

                            <Nav.Link aria-label={lang.facebook} target="_blank" href={mainInfo && mainInfo.facebook} title="فيسبوك">
                                <FontAwesomeIcon color='#ffffff' size='lg' icon={faFacebookF} />
                            </Nav.Link>
                        </Row>
                    </Col>
                   
                    {user ?
                        <Col className="text-left">
                            <Navbar.Brand className="text-left">
                                <Dropdown >
                                    <Dropdown.Toggle className="text-white" >
                                        {user.username && user.username}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="text-center dropdown">
                                        <Dropdown.Item onClick={() => navigate(type, { state: { tab: lang.profile } })} >
                                            <Col>
                                                <Row ><img loading="lazy" alt={'صورة شخصية'} className="dropdown-image mx-auto" src={userAvatar({ type: type, avatar: user.avatar })} /></Row>
                                                <Row ><span className="text-muted text-center mx-auto">{(user.email ? user.email : "example@gmail.com") + ' '}</span></Row>
                                                <Row ><span className="text-wrap mx-auto">{(user.name ? user.name.substr(0, 75) : user.username.substr(0, 75)) + ' '}</span></Row>
                                            </Col>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => navigate(type, { state: { tab: lang.contact } })} className="dropdown-item">
                                            <Row className="align-items-center px-3">
                                                <sup>
                                                    <Badge className="bg-danger rounded-circle badge-drop"
                                                            onClick={() => user ? navigate(type, { state: { tab: lang.contact } }) : PwdLogin()}>{msgs() > 0 && msgs() || null }
                                                    </Badge>
                                                </sup>   
                                                <FontAwesomeIcon className="FontAwesomeIcon nabbar-icon ml-2" size="lg" icon={faEnvelope} />
                                                {lang.contact}
                                            </Row> 
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={() => navigate(type, { state: { tab: lang.notifications } })} className="dropdown-item">
                                            <Row className="align-items-center px-3">
                                            <sup><Badge className="bg-danger rounded-circle badge-drop" onClick={() => user ? navigate(type, { state: { tab: lang.notifications } }) : PwdLogin()}>{user ? pushNots === undefined ? perNots || null : perNots === undefined ? pushNots || null  : pushNots !== undefined && perNots !== undefined ? pushNots + perNots || null : null: 0}</Badge></sup>
                                                <FontAwesomeIcon style={{ color: '#2ac3e9' }} size="lg" className='nabbar-icon FontAwesomeIcon ml-2' icon={faBell} />
                                                {lang.notifications}
                                            </Row>
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={() => navigate(type, { state: { tab: lang.settings } })} className="dropdown-item">
                                            <Row className='align-items-center px-3'>
                                                <FontAwesomeIcon size="lg" style={{ color: '#2ac3e9' }} variant="primary" className="FontAwesomeIcon ml-2" icon={faCog} />{lang.Accountsettings}
                                            </Row>
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={logout} className="dropdown-item">
                                            <Row className='align-items-center px-3'>
                                                <FontAwesomeIcon size="lg" style={{ color: '#2ac3e9' }} variant="primary" className="FontAwesomeIcon ml-2" icon={faArrowRight} />
                                                {lang.logout}
                                            </Row>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Navbar.Brand>   
                        </Col>                         
                        :
                        <Col className="text-left">
                            <Container >
                                <Nav className="ml-auto justify-content-end w-100">
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{lang.login}</Tooltip>}>
                                        <Nav.Link href="#" aria-label={lang.applicant} onClick={PwdLogin}>{lang.applicant} </Nav.Link>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{lang.co}</Tooltip>}>
                                        <Nav.Link href="#" aria-label={lang.co} onClick={CoLogin} >{lang.co} </Nav.Link>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{lang.org}</Tooltip>}>
                                        <Nav.Link href="#" aria-label={lang.org} onClick={OrgLogin} >{lang.org} </Nav.Link>
                                    </OverlayTrigger>
                                </Nav>    
                            </Container>
                        </Col>}
                </Navbar >

            </div >
            {navLinks &&
                <Navbar collapseOnSelect expand="lg" className='navbar-container'   >
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Row className='d-flex justify-content-center align-items-center ' >

                                <Col xs='12' sm='12' md='12' lg='3' xl='3' >
                                    <Image alt={lang.homeDescription} src={Logo} rounded style={{ width: '100%', height: '100%', padding: 10 }} />
                                </Col>
                                <Col xs='12' sm='12' md='12' lg='9' xl='9'>
                                    <Row xs='12' >
                                        {navLinks && navLinks.map(n => (
                                            <Col sm='12' xs='12' md='12' lg='auto' xl='auto' key={n.key} style={{padding: 10}}>
                                                <Nav.Link
                                                    className='p-2'
                                                    onClick={() => !n.disabled && handleNav(n)}
                                                    eventKey={n.key}
                                                >
                                                    <TextLinks disabled={n.disabled} active={n.key && n.key === nav.key} text={n.name} />
                                                </Nav.Link>
                                            </Col>

                                        ))}
                                        <div className='mobile-nav-container'>

                                            {user ? <>
                                                <Col sm='12' xs='12' md='12' lg='auto' xl='auto' >
                                                    <Nav.Link
                                                        aria-label={lang.profile}
                                                        className='p-2'
                                                        onClick={() => navigate(type)}
                                                        eventKey={1}
                                                    >
                                                        <TextLinks text={lang.profile} />
                                                    </Nav.Link>
                                                </Col>
                                                <Col>
                                                    <Nav.Link
                                                        aria-label={lang.logout}
                                                        className='p-2'
                                                        onClick={logout}
                                                        eventKey={1}
                                                    >
                                                        <TextLinks text={lang.logout} />
                                                    </Nav.Link>
                                                </Col>
                                            </>
                                                :
                                                <>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.pwdRegister}
                                                            className='p-2'
                                                            onClick={() => navigate('/PwdRegister')}
                                                            eventKey={2}>
                                                            <TextLinks text={lang.pwdRegister} />
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.pwdLogin}
                                                            className='p-2'
                                                            onClick={() => navigate('/PwdLogin')}
                                                            eventKey={3}>
                                                            <TextLinks text={lang.pwdLogin} />
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.coRegister}
                                                            className='p-2'
                                                            onClick={() => navigate('/CoRegister')}
                                                            eventKey={2}>
                                                            <TextLinks text={lang.coRegister} />
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.coLogin}
                                                            className='p-2'
                                                            onClick={() => navigate('/CoLogin')}
                                                            eventKey={3}>
                                                            <TextLinks text={lang.coLogin} />
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.orgRegister}
                                                            className='p-2'
                                                            onClick={() => navigate('/OrgRegister')}
                                                            eventKey={2}>
                                                            <TextLinks text={lang.orgRegister} />
                                                        </Nav.Link>
                                                    </Col>
                                                    <Col>
                                                        <Nav.Link
                                                            aria-label={lang.orgLogin}
                                                            className='p-2'
                                                            onClick={() => navigate('/OrgLogin')}
                                                            eventKey={3}>
                                                            <TextLinks text={lang.orgLogin} />
                                                        </Nav.Link>
                                                    </Col>
                                                </>
                                            }
                                        </div>
                                    </Row>
                                </Col>

                            </Row>

                        </Navbar.Collapse>
                        <div className='header-title'  >
                            {dashboard ? dashboard.title : nav.name}
                        </div>
                    </Container>
                </Navbar>
            }
            {
                dashboard && (
                    <Container fluid style={{ backgroundColor: '#303546' }} className='dashboard-container'>
                        <Container style={{ padding: '1%' }}>
                            <DashTitle >
                                {dashboard.title}
                            </DashTitle>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <div onClick={() => navigate(dashboard.nav)} style={{ padding: 5 }}>
                                    <DashSub >
                                        {lang.home}
                                    </DashSub>
                                </div>
                                <div style={{ padding: 5 }}>
                                    <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                                </div>
                                {currentNav &&
                                    <div style={{ padding: 5 }}>
                                        <DashSub sub>
                                            {currentNav}
                                        </DashSub>
                                    </div>}
                                {(dashboard.job || dashboard.service) &&
                                    <>
                                        <div onClick={dashboard.goBack}>
                                            <DashSub sub>
                                                {dashboard.service ? lang.services : lang.jobs}
                                            </DashSub>
                                        </div>
                                        <div style={{ padding: 5 }}>
                                            <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                                        </div>
                                        <div style={{ padding: 5 }}>
                                            <DashSub sub>
                                                {dashboard.service || dashboard.job}
                                            </DashSub>
                                        </div>
                                    </>

                                }
                            </div>
                        </Container>

                    </Container>
                )
            }
        </div >
    );
}
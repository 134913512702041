import "./css/sidenav.css";
import Badge from "react-bootstrap/Badge";
import { useEffect, useState } from "react";
import { CatCheckBox, CSelect } from "./inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faDollarSign,
  faMapMarkerAlt,
  faStar,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Org from "../Assets/imgs/organization.webp";
import Company from "../Assets/imgs/company.webp";
import Job from "../Assets/imgs/jobs.webp";

export function SideNav({ navs, nav, currentNav }) {
  const [active, setActive] = useState(currentNav ? currentNav : navs[0]);
  const [value, setValue] = useState(
    currentNav
      ? { _id: currentNav.tab, name: currentNav.name }
      : navs.map((n) => {
          return { _idvalue: n.tab, name: n.name };
        })[0]
  );
  const location = useLocation();

  useEffect(() => {
    if (currentNav) setActive(currentNav);
  }, [currentNav]);
  const setNav = (tab) => {
    setActive(tab);
    nav(tab);
  };
  const setSelect = (v) => {
    setValue(v);
    nav(v);
  };

  return (
    <div>
      <div className="nav-container">
        {navs.map((nav, i) => {
          const a = nav.disabled
            ? "-disabled"
            : nav.tab === active.tab
            ? "-active" +
              (i === 0 ? "-first" : i === navs.length - 1 ? "-last" : "")
            : "";

          // check for all notifications that pwds received // new // BY DALIA
          const notifications =
            nav.notifications &&
            nav.notifications.length > 0 &&
            nav.notifications.filter((v) => !v.seen).length > 0 &&
            nav.notifications.filter((v) => !v.seen).length;
          const perNotifications =
            nav.perNotifications &&
            nav.perNotifications.length > 0 &&
            nav.perNotifications.filter((v) => !v.seen).length > 0 &&
            nav.perNotifications.filter((v) => !v.seen).length;

          return (
            <div
              key={i}
              onClick={() => {
                if (!nav.disabled) setNav(nav);
              }}
              className={"nav-box" + a}
            >
              {nav.name}{" "}
              <Badge variant="primary">
                {(location && location.pathname === "/co") ||
                (location && location.pathname === "/org")
                  ? nav.badges > 0 && nav.badges
                  : location && location.pathname === "/pwd"
                  ? notifications === undefined
                    ? perNotifications || null
                    : perNotifications === undefined
                    ? notifications || null
                    : notifications !== undefined &&
                      perNotifications !== undefined
                    ? notifications + perNotifications || null
                    : null
                  : null}
                {}
                {location &&
                  location.pathname === "/pwd" &&
                  nav.name == "التواصل" &&
                  nav.badges > 0 &&
                  nav.badges}
              </Badge>
            </div>
          );
        })}
      </div>
      <div className="mobile-nav-container">
        <CSelect
          isSearchable={false}
          isClearable={false}
          menu
          value={value}
          isOptionDisabled={(option) => option.disabled}
          onChange={setSelect}
          options={navs.map((n) => {
            return { _id: n.tab, name: n.name, ...n };
          })}
          margin
          defaultValue={value}
        />
      </div>
    </div>
  );
}

export function CategoryNav({ title, options, sort, lang, type }) {
  const [active, setActive] = useState([]);
  const [size, setSize] = useState(5);
  const Select = (type) => {
    if (!active.find((value) => value === type)) setActive([...active, type]);
    else {
      const newActive = [...active];
      const index = newActive.indexOf(type);
      if (index > -1) {
        newActive.splice(index, 1);
      }
      setActive(newActive);
    }
  };
  useEffect(() => {
    sort({ type: type, value: active });
  }, [active]);
  return (
    <div className="category-nav-container mb-4">
      <div className="category-nav-title py-3">{title}</div>
      <div className="category-nav-options">
        {options &&
          options.slice(0, size).map((type, index) => (
            <div key={index}>
              <CatCheckBox
                onClick={() => Select(type.name)}
                active={active.find((value) => value === type.name)}
              >
                <Link
                  to=""
                  className="text-muted text-link"
                  aria-label={type.name}
                >
                  <div>
                    {type.name}{" "}
                    <span style={{ color: "#5fc0e9" }}>
                      ({type.jobNumbers})
                    </span>{" "}
                  </div>
                </Link>
              </CatCheckBox>
            </div>
          ))}
        {options && options.length > 5 && (
          <div
            className="category-nav-more"
            onClick={() => setSize(options.length)}
          >
            {size > 5 ? "" : lang.showMore}
          </div>
        )}
      </div>
    </div>
  );
}

export function JobDetails({
  job,
  lang,
  onClick,
  success,
  userStatus,
  userType,
}) {
  const ImgSrc =
    job.img && job.img.length > 0 && job.ref === "organization"
      ? `${lang.baseUrl}/org/media/imgs/${job.img}`
      : job.img && job.img.length > 0 && job.ref === "company"
      ? `${lang.baseUrl}/co/media/imgs/${job.img}`
      : Job;
  return (
    <Container>
      <Card>
        <Row className="category-nav-title w-100 text-white text-center">
          <Col lg="12">{lang.jobTitle}</Col>
        </Row>
        <Row className="category-nav-options">
          <Card.Img
            className="w-100 h-100"
            src={ImgSrc}
            alt={"صورة خلفية للوظيفة"}
          />
        </Row>
        <Row>
          <Col lg="12">
            <Card.Title>
              <h6 className="pt-2 text-dark text-center px-3">
                {job.jobTitle}
              </h6>
            </Card.Title>
          </Col>
          <Col lg="12">
            <Card.Subtitle className="pt-3 text-center px-3">
              {job && job.ref === "organization" && (
                // Conditional rendering of the link destination based on user profile status //Heba

                <Link
                  to={
                    userStatus || userType != "pwd"
                      ? `/home/organizations/${
                          job.companyId !== null
                            ? job.companyId._id
                            : job.sourceId._id
                        }`
                      : ""
                  }
                  className="text-decoration-none"
                  style={{
                    cursor:
                      userStatus || userType != "pwd" ? "pointer" : "default", // Conditional cursor style based on user profile status //Heba
                  }}
                  aria-label={
                    job.companyId !== null
                      ? job.companyId.name
                      : job.sourceId.name
                  }
                >
                  <p>
                    {job.companyId !== null
                      ? job.companyId.name
                      : job.sourceId.name}
                  </p>
                </Link>
              )}
              {job && job.ref === "company" && (
                // Conditional rendering of the link destination based on user profile status //Heba

                <Link
                  to={
                    userStatus
                      ? `/home/companies/${
                          job.companyId !== null
                            ? job.companyId._id
                            : job.sourceId._id
                        }`
                      : ""
                  }
                  className="text-decoration-none"
                  style={{
                    cursor: userStatus ? "pointer" : "default", // Conditional cursor style based on user profile status //Heba
                  }}
                  aria-label={
                    job.companyId !== null
                      ? job.companyId.name
                      : job.sourceId.name
                  }
                >
                  <p>
                    {job.companyId !== null
                      ? job.companyId.name
                      : job.sourceId.name}
                  </p>
                </Link>
              )}
            </Card.Subtitle>
          </Col>
        </Row>
        <Row className="p-4">
          <Button
            disabled
            size="sm"
            text={job.workType}
            variant="success"
            className="btn-job-card rounded-6 rounded-lg"
            aria-label={job.workType}
          >
            <p className="text-btn-job-card">{job.workType}</p>
          </Button>
        </Row>
        <Row className="p-3">
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faDollarSign} size="sm" color="#ff4136" />
            <span className="font-weight-bold text-dark "> {lang.salary} </span>
            <span>
              {job.minSalary} {" : "} {job.maxSalary} {lang.pound}{" "}
            </span>
          </Card.Subtitle>
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faStar} size="sm" color="#ff4136" />{" "}
            <span className="font-weight-bold text-dark">
              {" "}
              {lang.experince} :
            </span>
            <span>
              {job.minExperience} {lang.to} {job.maxExperience} {lang.years}
            </span>
          </Card.Subtitle>
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faThLarge} size="sm" color="#ff4136" />{" "}
            <span className="font-weight-bold text-dark pb-4">
              {" "}
              {lang.jobType} :
            </span>
            <span>{job.workField && job.workField.name}</span>
          </Card.Subtitle>
        </Row>
        <Row className="p-4">
          {success || job.status ? (
            <Button
              disabled
              size="sm"
              variant="outline-primary"
              className="btn-job-card rounded-6 rounded-lg"
            >
              <p className="text-btn-job-card">{lang.jobApplied}</p>
            </Button>
          ) : (
            <>
              {userType == "pwd" && (
                <Button
                  onClick={onClick}
                  size="sm"
                  variant="secondary"
                  className="btn-job-card rounded-6 rounded-lg"
                >
                  <p className="text-btn-job-card">{lang.apply}</p>
                </Button>
              )}
            </>
          )}
        </Row>
      </Card>
    </Container>
  );
}
//neww design

export function ServiceDetails({
  service,
  lang,
  onClick,
  success,
  userStatus,
  userType,
}) {
  const ImgSrc =
    service.img && service.img.length > 0
      ? `${lang.baseUrl}/org/media/imgs/${service.img}`
      : Job;
  return (
    <Container>
      <Card>
        <Row className="category-nav-title w-100 text-white text-center">
          <Col lg="12">{lang.service}</Col>
        </Row>
        <Row className="category-nav-options">
          <Card.Img className="w-100" src={ImgSrc} alt={"صورة خلفية للخدمة"} />
        </Row>
        <Row>
          <Col lg="12">
            <Card.Title>
              <h6 className="pt-2 text-dark text-center">{service.name}</h6>
            </Card.Title>
          </Col>
          <Col lg="12">
            <Card.Subtitle className="pt-3 px-3 text-center">
              <Link
                to={
                  userStatus || userType != "pwd"
                    ? `/home/organizations/${service.organizationId._id}`
                    : ""
                }
                className="text-decoration-none"
                style={{
                  cursor:
                    userStatus || userType != "pwd" ? "pointer" : "default", // Conditional cursor style based on user profile status //Heba
                }}
                aria-label={
                  service.organizationId.name && service.organizationId.name
                }
              >
                <span>
                  {service.organizationId.name && service.organizationId.name}{" "}
                </span>
              </Link>
            </Card.Subtitle>
          </Col>
        </Row>
        <Row className="p-4">
          <Button
            disabled
            size="sm"
            variant="success"
            className="btn-job-card rounded-6 rounded-lg"
          >
            <p className="text-btn-job-card">{service.type}</p>
          </Button>
        </Row>
        <Row className="p-3">
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faDollarSign} size="sm" color="#ff4136" />
            <span className="font-weight-bold text-dark "> {lang.salary} </span>
            <span>
              {service.amount} {" : "} {service.amount} {lang.pound}
            </span>
          </Card.Subtitle>
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faStar} size="sm" color="#ff4136" />
            <span className="font-weight-bold text-dark">
              {" "}
              {lang.experince} :
            </span>
            <span>
              {service.minExperience} {lang.to} {service.maxExperience}{" "}
              {lang.years}
            </span>
          </Card.Subtitle>
          <Card.Subtitle className="p-3">
            <FontAwesomeIcon icon={faThLarge} size="sm" color="#ff4136" />
            <span className="font-weight-bold text-dark pb-4">
              {" "}
              {lang.serviceType} :
            </span>
            <span>{service.type && service.type}</span>
          </Card.Subtitle>
        </Row>
        <Row className="p-4">
          {success || service.status ? (
            <Button
              disabled
              size="sm"
              variant="outline-primary"
              className="btn-job-card rounded-6 rounded-lg"
            >
              <p className="text-btn-job-card">{lang.jobApplied}</p>
            </Button>
          ) : (
            <>
              {userType == "pwd" && (
                <Button
                  onClick={onClick}
                  size="sm"
                  variant="secondary"
                  className="btn-job-card rounded-6 rounded-lg"
                >
                  <p className="text-btn-job-card">{lang.apply}</p>
                </Button>
              )}
            </>
          )}
        </Row>
      </Card>
    </Container>
  );
}
//new design
export function OrgDetails({ org, lang }) {
  const ImgSrc = org.avatar
    ? lang.baseUrl + "/org/media/imgs/" + org.avatar
    : Org;
  return (
    <Card className="org-profile-card-container">
      <Row className="category-nav-title w-100 text-white">
        <span>{lang.org}</span>
      </Row>
      <Row className="category-nav-options">
        <Card.Img className="w-100" src={ImgSrc} alt={"صورة خلفية للمؤسسة"} />
      </Row>
      <Row>
        <Col lg="12">
          <Card.Title>
            <h6 className="pt-2 text-center text-dark px-3">{org.name} </h6>
          </Card.Title>
        </Col>
        <Col lg="12">
          <Card.Subtitle>
            <p className="pt-3 text-center px-3">
              {org.brief && org.brief.length > 100
                ? org.brief.substr(0, 100) + "..."
                : org.brief}
            </p>
          </Card.Subtitle>
        </Col>
      </Row>

      <Row className="p-4">
        <Col xs="12">
          {org.location || org.address ? (
            <>
              <Card.Subtitle className="p-3 text-right">
                <Row>
                  <a
                    href={org.location && org.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-right"
                  >
                    <i>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        size="sm"
                        color="#ff4136"
                      />
                    </i>{" "}
                    <span className="font-weight-bold text-dark">
                      {" "}
                      {lang.location}{" "}
                    </span>
                    <span> {org && org.address}</span>
                  </a>
                </Row>
              </Card.Subtitle>
              {/* <div className='align-items-center'>
                                <iframe src={org.location} />
                            </div> */}
            </>
          ) : (
            <FontAwesomeIcon display="none" />
          )}
        </Col>

        <Col xs="12">
          <Card.Subtitle className="p-3">
            {org.services ? (
              <Row>
                <FontAwesomeIcon
                  icon={faStar}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.services} :{" "}
                </span>
                <span>{org.services}</span>
              </Row>
            ) : (
              <Row>
                <FontAwesomeIcon
                  icon={faStar}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.services} :{" "}
                </span>
                <span>{lang.zeroServices}</span>
              </Row>
            )}
          </Card.Subtitle>
        </Col>
        <Col xs="12">
          <Card.Subtitle className="p-3">
            {org.jobs ? (
              <Row>
                <FontAwesomeIcon
                  icon={faBriefcase}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.jobs} :{" "}
                </span>
                <span> {org.jobs}</span>
              </Row>
            ) : (
              <Row>
                <FontAwesomeIcon
                  icon={faStar}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.jobs} :{" "}
                </span>
                <p> {lang.zeroJobs}</p>
              </Row>
            )}
          </Card.Subtitle>
        </Col>
      </Row>
    </Card>
  );
}
export function CoDetails({ co, lang }) {
  const ImgSrc = co.avatar
    ? lang.baseUrl + "/co/media/imgs/" + co.avatar
    : Company;

  return (
    <Card className="org-profile-card-container">
      <Row className="category-nav-title w-100 text-white">
        <span> {lang.co}</span>
      </Row>
      <Row className="category-nav-options ">
        <Card.Img className="w-100 " src={ImgSrc} alt={"صورة خلفية للشركة"} />
      </Row>
      <Row>
        <Col lg="12">
          <Card.Title>
            <h6 className="pt-2 text-center text-dark px-3">{co.name}</h6>
          </Card.Title>
        </Col>
        <Col lg="12">
          <Card.Subtitle>
            <p className="pt-3 text-center px-3">
              {co.brief && co.brief.length > 100
                ? co.brief.substr(0, 100) + "..."
                : co.brief}
            </p>
          </Card.Subtitle>
        </Col>
      </Row>

      <Row className="p-4">
        <Col xs="12">
          {co.location || co.address ? (
            <>
              <Card.Subtitle className="p-3">
                <Row>
                  <a
                    href={co.location && co.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="sm"
                      color="#ff4136"
                    />{" "}
                    <span className="font-weight-bold text-dark pt-2">
                      {" "}
                      {lang.location}{" "}
                    </span>
                    <span> {co && co.address}</span>
                  </a>
                </Row>
              </Card.Subtitle>
              {/* <div className='align-items-center'>
                                <iframe src={co.location} />
                            </div> */}
            </>
          ) : (
            <FontAwesomeIcon display="none" />
          )}
        </Col>

        <Col xs="12">
          <Card.Subtitle className="p-3">
            {co.jobs ? (
              <Row>
                <FontAwesomeIcon
                  icon={faBriefcase}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.jobs} :
                </span>
                <span>{co.jobs}</span>
              </Row>
            ) : (
              <Row>
                <FontAwesomeIcon
                  icon={faBriefcase}
                  size="sm"
                  color="#ff4136"
                  className="ml-1"
                />{" "}
                <span className="font-weight-bold text-dark">
                  {" "}
                  {lang.jobs} :
                </span>
                <span>{lang.zeroJobs}</span>
              </Row>
            )}
          </Card.Subtitle>
        </Col>
      </Row>
    </Card>
  );
}

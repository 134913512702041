import { Spinner } from "react-bootstrap";
import logo from "../Assets/imgs/splachLogo.webp";
import { Button } from "./button";
import { TextSubtitle } from "./text";
import "./css/loading.css";

export function Loading() {
  return (
    <div className="loadingWrapper">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export function PageLoading() {
  return (
    <div className="pageLoadingWrapper">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}
export function SplachPage() {
  return (
    <div className="spalshPageWrapper">
      <img loading="lazy" src={logo} alt="برجاء الإنتظار" />
      <Spinner
        className="spinner mr-2 ml-2"
        animation="grow"
        variant="secondary"
      />
      <TextSubtitle>{"برجاء الإنتظار...."}</TextSubtitle>
    </div>
  );
}
export function ErrorPage({ lang }) {
  return (
    <div className="errorPageWrapper">
      <img loading="lazy" src={logo} alt={lang.netwrorkError} />
      <TextSubtitle center bold>
        {lang.netwrorkError}
      </TextSubtitle>
      {/* make the button to backward to the previous page //HEBA */}

      <Button onClick={() => window.history.back()} margin text={lang.update} />
    </div>
  );
}

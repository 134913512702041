let testing = false;
 const testingUrl = 'https://be.npd-egypt.net'
const baseUrl = 'https://be.npd-egypt.net'
// const testingUrl = 'https://127.0.0.1:3000'
// const baseUrl = 'https://backend.npd-egypt.net'
//const testingUrl = "https://127.0.0.1:3000";
//const baseUrl = "https://backend.npd-egypt.net";
// const baseUrl = "https://127.0.0.1:3000";

export const ar = {
  baseUrl: testing ? testingUrl : baseUrl,
  // home
  update: "تحديث الصفحة",
  login: "تسجيل الدخول",
  coLogin: "تسجيل دخول لحساب شركة",
  orgLogin: "تسجيل دخول لمؤسسة",
  pwdLogin: "تسجيل دخول لباحث عن وظيفة",
  register: "إنشاء حساب",
  coRegister: "إنشاء حساب لشركة",
  orgRegister: "إنشاء حساب لمؤسسة",
  pwdRegister: "إنشاء حساب لباحث عن وظيفة",
  headerNote: "يمكنك إضافة رقم الهاتف والبريد الإلكتروني حين الحاجة",
  logout: "تسجيل خروج",
  goTop: "الصعود لأعلى الصفحة",
  sendMailRequest: "طلب إرسال تحديث كلمة السر",
  home: "الرئيسية",
  jobs: "الوظائف",
  services: "الخدمات",
  applicants: "المتقدمين للوظائف",
  applicantsTab: "المتقدمين للوظائف والخدمات",
  co: "الشركات",
  org: "مقدمو الخدمات",
  pwd: "الباحثين عن عمل",
  articleSearchBtn: "ابحث عن مقالة الآن",

  pages: "الصفحات",
  articls: "المقالات",
  more: "المزيد",
  showMore: "عرض المزيد",
  training: "التدريبات",
  AddJobBtn: "أضف وظيفة",
  search: "بحث",
  jobDes: "عن أي وظيفة تبحث؟",
  jobLoc: "أين تحب أن تعمل؟",
  jobRank: " مجال الوظيفة أو مجال العمل",
  jobExp: "ما مدى خبرتك في هذا النوع من الوظائف؟",
  jobExpRequired: "برجاء تحديد مدى خبرتك",
  jobSalary: "ما هو الراتب المتوقع والمجزي بالنسبة لك؟",
  jobSearchBtn: "ابحث عن وظيفة الآن",
  startNow: "ابدأ الآن",
  findJob: "ابحث عن وظيفة",
  jobRankTitle: "تصنيفات الوظائف الأكثر شهرة",
  medical: "المجال الطبي",
  acounting: "المحاسبة",
  tech: "التكنولوجيا",
  developers: "التطوير",
  government: "حكومي",
  media: "الإعلام",
  restaurants: "المطاعم",
  allJobs: "كل الوظائف",
  job: "وظيفة",
  recentJobs: "الوظائف المضافة حديثاً",
  featured: "اخترنا لك",
  remotely: "عن بعد",
  partTime: "دوام جزئي",
  fullTime: "دوام كامل",
  keywords: "الكلمات المفتاحية",
  addKeyword: "اضافة كلمة جديدة",
  apply: "تقدم الآن",
  salary: "الراتب:",
  location: "الموقع:",
  topCompanies: "الشركات الأكثر تعييناً",
  exist: "يوجد",
  availableJobs: "وظائف متاحة",
  or: "أو",
  registerEmployeer: "أنا أبحث عن وظيفة",
  registerCompany: "أنا أبحث عن موظفين",
  regDesc:
    "محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة، محتوى نصي يمكن تغييره على حسب الحاجة.",
  regCoBtn: "أنشئ حساب كشركة",
  regEmpBtn: "أنشئ حساب كمتقدم للوظيفة",
  servicesTitle: "نقدم لك أفضل الخدمات",
  serSearch: "البحث عن الوظائف",
  serApply: "التقديم على الوظائف",
  serSecurity: "أمان وخصوصية",
  serAlerts: "إشعارات بالوظائف",
  serPay: "الدفع بسهولة",
  serCall: "خدمة عملاء",
  serDeleteMsg: "هل تريد حذف هذه الخدمة؟",
  serHoldMsg: "هل تريد تعليق هذه الخدمة؟",
  editService: "تعديل بيانات خدمة",
  hold: "تعليق",
  serActivateMsg: "هل تريد استئناف هذه الخدمة؟",
  activate: "استئناف",
  cancel: "إلغاء",
  serStay: "الإبقاء عليها",
  advicesTtile: "نصائح مهنية",
  publishDate: "نشر بتاريخ ",
  availableJob: "وظيفة متاحة",
  member: "عضو",
  resume: "سيرة ذاتية",
  company: "شركة",
  bestEmp: "لدينا أفضل الموظفين المحتملين",
  profileInfo: "البيانات الأساسية",
  profile: "الملف الشخصي",
  showAllBtn: "عرض الكل",
  clients: "ماذا يقول عملاؤنا؟",
  details: "التفاصيل",
  footerEmail: "كن على إطلاع داائم بأحدث أخبارنا وأحدث الوظائف",
  footerEmailInput: "اكتب البريد الإلكتروني الخاص بك",
  footerEmailBtn: "الانضمام للقائمة البريدية",
  footerAboutUs: "اعرف المزيد عنّا",
  footerAppliers: "للباحثين عن وظيفة",
  footerPart: "للشركات والمُعيّنين",
  footerMore: "معلومات أكثر",
  footerMoreBtn: "اعرف المزيد ...",
  copyright: "حقوق الملكية",
  homeDescription: "الشبكة القومية لخدمات الأشخاص ذوي الإعاقة",
  searchJobs: "البحث عن الوظائف",
  searchServices: "البحث عن الخدمات",
  searchOrgs: "البحث عن المؤسسات الخدمية",
  searchCos: "البحث عن الشركات",
  searchArticles: "البحث عن المقالات",
  // organizations //
  // nav links
  companies: "الشركات",
  organizations: "المؤسسات",
  o_applicants: "المتقدمين",
  ctrPanel: "لوحة التحكم",
  proNotActive: "يرجى إكمال وحفظ ملفك الشخصي",
  profileReview: "ملفك الشخصي قيد المراجعة",
  profileRejected: "نأسف! لم يتم قبول ملفك الشخصي",
  imgBtn: "استعراض الصور",
  branches: "الفروع / الهيئات التابعة",
  employment: "التوظيف",
  contact: "التواصل",
  content: "المحتوى",
  updates: "إرسال التحديثات",
  notifications: "الإشعارات",
  favorite: "المفضلة",
  settings: "إعدادات الحساب",
  Accountsettings: "إعدادات", //new
  ViewAll: "كل المقالات", //neww

  // profile
  pwdAvatarRequired: "برجاء إدخال صورة شخصية",
  terminated: "نأسف لقد تم تعليق حسابك",
  active: "تمت مراجعة ملفك الشخصي، يمكنك الآن الدخول لكافة مميزات المنصة",
  basicInfo: "المعلومات الأساسية",
  orgName: "اسم المؤسسة",
  uniqueCode: "كود المؤسسة",
  orgNameRequired: "برجاء إدخال اسم المؤسسة",
  orgNameError:
    "اسم المؤسسة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  orgNameLength: "اسم المؤسسة يجب ان لا يقل عن مقطع ولا يزيد عن 50 حرف",
  country: "البلد",
  countryRequired: "برجاء إدخال البلد ",
  province: "المحافظة",
  provinceRequired: "برجاء إدخال المحافظة",
  statesRequired: "برجاء إدخال المحافظات",
  city: "المدينة",
  cityError:
    "اسم المدينة يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  cityLength: "اسم المدينة يجب ان لا يقل عن 4 حروف وان لا يزيد عن 20 حرف",
  state: "الحي",
  stateError:
    "اسم الحي يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  stateLength: "اسم الحي يجب ان لا يقل عن 4 حروف وان لا يزيد عن 20 حرف",
  address: "العنوان بالتفصيل",
  fullLocation: "العنوان بالخريطة",
  fullLocationRequired: "برجاء إدخال لينك الخريطة",
  orgFullLocationDefined:
    "برجاء وضع رابط الإحداثيات الخاص بموقع المؤسسة من موقع خرائط جوجل Google Maps في المكان المخصص له",
  urlError: "العنوان غير صحيح",
  mapBtn: "تعيين على الخريطة",
  field: "المجال",
  fieldRequired: "برجاء تحديد المجال ",
  entity: "نوع الجهة",
  entityRequired: "برجاء تحديد نوع الجهة",
  orgDocuments: "وثائق المنظمة",
  orgDocBtn: "قم برفع وثائق المنظمة",
  mobile: "الموبايل",
  fax: "الفاكس",
  siteServices: "الخدمات المقدمة",
  consultingServices: "خدمات استشارية",
  trainingServices: "خدمات تدريب",
  assessmentServices: "خدمات تقييم",
  empowermentServices: "خدمات تأهيل",
  employmentServices: "خدمات توظيف",
  financingServices: "خدمات تمويل",
  digital: "التواجد الرقمي",
  linkedin: "لينكد ان",
  facebook: "فيسبوك",
  twitter: "تويتر",
  youtube: "يوتيوب",
  instagram: "انستجرام",
  save: "حفظ كل التغييرات",
  sendRequest: "إرسال طلب مراجعة للملف الشخصي",
  saveRequest: "حفظ كل التغييرات وإرسال طلب للمراجعة تلقائياً",
  requestDone: "تم إرسال طلب المراجعة بنجاح",
  bigSize: "اقصى حجم 2MB",
  bigSizeFile: "اقصى حجم 10",
  approvedExtensions:
    "إمتدادات الصور المتاحة هي [jpg|JPG|jpeg|JPEG|png|PNG|JFIF]",
  found: "الملف موجود بالفعل",
  // services
  addService: "إضافة خدمة جديدة",
  providedServices: "الخدمات المقدمة",
  holdedServices: "الخدمات المعلقة",
  serviceName: "اسم الخدمة",
  serviceNameRequired: "برجاء إدخال اسم الخدمة",
  serviceNameError:
    "اسم الخدمة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  serviceNameLength: "اسم الخدمة يجب ان لا يقل عن مقطع ولا يزيد عن 30 حرف",
  serviceFees: "مقابل الخدمة",
  serviceFeesRequired: "برجاء تحديد مقابل الخدمة",
  serviceFeesError: "الرقم غير صحيح",
  serviceFeesSizeError: "القيمة يجب ألا تتعدى 6 أرقام",
  serviceType: "نوع الخدمة",
  serviceTypeRequired: "برجاء تحديد نوع الخدمات",
  availability: "الإتاحة",
  serviceInfo: "الوصف",
  dsServiceType: "أنواع الإعاقات المقدم لها الخدمة",
  addDs: "أضف إعاقة جديدة",
  serviceProgreses: "إجراءات الحصول على الخدمة",
  addResponsibility: "أضف مسئولية جديدة",
  addProcess: "أضف إجراء جديد",
  showService: "بيانات الخدمة",
  paperNedded: "الأوراق المطلوبة",
  addPaper: "أضف ورقة جديدة",
  add: "إضافة",
  branch: "فرع ",
  applicantsN: "تقدم للخدمة ",
  person: "شخص",
  tsBtn: "تعليق الخدمة",
  actBtn: "استئناف الخدمة",

  edit: "تعديل",
  delete: "حذف",
  showApplicants: "عرض المتقدمين",
  hiddeApplicants: "إخفاء المتقدمين",

  // branches
  addNewBranch: "إضافة فرع جديد",
  addNewCompany: "إضافة شركة جديدة",
  currentBranches: "الفروع المضافة",
  currentCompanies: "الشركات المضافة",
  addBranch: "إضافة فرع / هيئة تابعة",
  branchName: "اسم الفرع/الهيئة",
  branchNameRequired: "برجاء إدخال اسم الفرع/الهيئة",
  branchNameError:
    "اسم الفرع/الهيئة يجب ان لا يقل عن حرف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  branchNameLength: "اسم الفرع/الهيئة يجب ان لا يقل عن حرف ولا يزيد عن 30 حرف",
  editBranch: "تعديل بيانات فرع",
  saveEdit: "حفظ التعديل",
  branchDeleteMsg: "هل تريد حذف هذا الفرع؟",
  removeCompany: "إلغاء الربط",
  cancelCompany: "إلغاء الطلب",
  companyRequest: "إرسال الطلب مرة اخرى",
  companyAccepted: "تم الربط",
  companyPending: "انتظار الموافقة",
  companyCanceld: "تم الغاء الربط",
  companyRejected: "تم رفض الربط",

  deleteCompanyMsg: "هل تريد إلغاء الربط مع",
  link: "ربط",
  back: "العودة",

  //employment
  jobf: "الوظيفة",
  jobfRequired: "برجاء إختيار الوظيفة",
  JobactBtn: "استئناف الوظيفة", //new by shrouk
  JobtsBtn: "تعليق الوظيفة", //new by shrouk
  jobActivateMsg: "هل تريد استئناف هذه الوظيفة؟", //new by shrouk

  // tabs
  addNewJob: "إضافة وظيفة جديدة",
  currentJob: "الوظائف الحالية",
  terminatedJobs: "الوظائف المنتهية",
  jobDeleteMsg: "هل تريد حذف هذه الوظيفة؟",
  jobTerminateMsg: "هل تريد إنهاء هذه الوظيفة؟",
  terminate: "إنهاء",
  forCompany: "لصالح شركة",

  // content
  jobName: "اسم الوظيفة",
  jobNameRequired: "برجاء تسجيل اسم الوظيفة",
  jobNameError:
    "اسم الوظيفة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  jobNameLength: "اسم الوظيفة يجب ان لا يقل عن مقطع ولا يزيد عن 30 حرف",
  disType: "نوع الإعاقة",
  disTypeRequired: "برجاء تحديد نوع الإعاقة",
  disEnabledRequired: "برجاء تحديد نوع الإتاحة",
  jobCountry: "بلد الوظيفة",
  jobCountryRequired: "برجاء إختيار بلد الوظيفة",
  minExp: "أقل سنين خبرة",
  minExpError: "الرقم غير صحيح",
  maxExp: "أعلى سنين خبرة",
  maxExpError: "الرقم غير صحيح",
  minSalary: "الحد الأدنى للراتب",
  minSalaryError: "الرقم غير صحيح",
  maxSalary: "الحد الأعلى للراتب",
  maxSalaryError: "الرقم غير صحيح",
  jobField: "مجال الوظيفة",
  jobFieldRequired: "برجاء إختيار مجال الوظيفة",
  jobType: "نوع الوظيفة",
  jobTypeRequired: "برجاء إدخال نوع الوظيفة ",
  avType: "نوع الإتاحة الموجودة بالمكان",
  adAv: "أضف نوع إتاحة جديد",
  responsibilities: "المسئوليات",
  modalInputRequired: "برجاء إدخال محتوى",
  modalInputError:
    "يجب ان لا تقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  reqSkills: "المهارات المطلوبة",
  reqSkillsRequired: "برجاء تحديد المهارات المطلوبة",
  addSkills: "أضف مهارة جديدة",
  appSteps: "خطوات التقديم",
  addStep: "أضف خطوة جديدة",
  bigVideoSize: "اقصى حجم 500MP",
  jobN: "تقدم للوظيفة ",
  termateJob: "إنهاء الوظيفة",
  show: "عرض",
  editJob: "تعديل بيانات وظيفة",

  // communication
  textChat: "المحادثات النصية",
  calls: "الإتصال المرئي",
  calling: "جار الإتصال...",
  pwdVWaiting: "إنتظار الباحث عن الوظيفة...",
  acceptCallMsg: "مكالمة مرئية من صاحب العمل.",
  acceptCall: "الرد",
  rejectCall: "رفض",

  chatSearch: "البحث عن محادثة",
  writeMessage: "اكتب رسالتك هنا",
  MsgError: "تم منع هذه الرسالة",

  // content
  addArticle: "إضافة محتوى كتابي",
  addMedia: "إضافة محتوى مرئي",
  contents: "المحتوى المضاف",
  editPost: "تعديل محتوى",
  postDeleteMsg: "هل تريد حذف هذا المقال؟",
  articleTitle: "عنوان المقال",
  articleTitleRequired: "قم بإضافة عنوان المقال",
  articleTitleError:
    "عنوان المقال يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  articleTitleLength:
    "عنوان المقال يجب ان لا يقل عن مقطع وان لا يزيد عن 100 حرف",
  imageDescription: "وصف صورة المقال",
  imageDescriptionRequired: "قم بإضافة وصف صورة المقال",
  imageDescriptionError:
    "وصف صورة المقال يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  imageDescriptionLength:
    "وصف صورة المقال يجب ان لا يقل عن مقطع وان لا يزيد عن 100 حرف",
  articl: "المقال",
  articleAboutRequired: "قم بإضافة تفاصيل المقال",
  articleAboutError:
    "المقال يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  articleAboutLength: "المقال يجب ان لا يقل عن مقطع وان لا يزيد عن 3000 حرف",
  addArtBtn: "إضافة المقال",
  articleImgRequired: "قم بإضافة صورة المقال  ",
  youtubeLink: "قم بإضافة رابط الفيديو من يوتيوب",
  youtubeLinkRequired: "قم بإضافة رابط الفيديو من يوتيوب",
  uploadVideo: "قم برفع الفيديو",
  videoTitle: "عنوان الفيديو",
  videoTitleRequired: "قم بإضافة عنوان الفيديو",
  videoTitleError:
    "عنوان الفيديو يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  videoTitleLength:
    "عنوان الفيديو يجب ان لا يقل عن مقطع وان لا يزيد عن 100 حرف",
  videoDesc: "وصف الفيديو",
  videoDescRequired: "قم بإضافة وصف الفيديو",
  videoDescError:
    "وصف الفيديو يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  videoDescLength: "وصف الفيديو يجب ان لا يقل عن مقطع وان لا يزيد عن 3000 حرف",
  addVideo: "إضافة الفيديو",
  time: "الساعة",
  article: "مقال",
  video: "فيديو",

  // updates
  sendNewUp: "إرسال تحديث جديد",
  savedUpdates: "تحديثات محفوظة لوقت لاحق",
  sendedUpdates: "التحديثات التي تم إرسالها",
  updateTitle: "عنوان التحديث",
  updateTitleRequired: "برجاء إدخال عنوان التحديث",
  updateTitleError:
    "عنوان التحديث يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  updateTitleLength:
    "عنوان التحديث يجب ان لا يقل عن مقطع وان لا يزيد عن 100 حرف",
  updateDesctiptionRequired: "برجاء إدخال تفاصيل التحديث",
  updateDesctiptionError:
    "تفاصيل التحديث يجب ان لا تقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  updateDesctiptionLength:
    "تفاصيل التحديث يجب ان لا تقل عن 4 حروف وان لا يزيد عن 500 حرف",
  sendTo: "مرسل إلى",
  addTo: "إضافة للمرسل إليهم",
  recipients: "المرسل إليهم", // notes
  serviceDesc: "وصف الخدمة",
  saveTo: "حفظ لوقت لاحق",
  sendUpdate: "إرسال التحديث",
  updateSeen: "تم رؤية التحديث من",
  user: "مستخدم",
  userNote: "حيث لم يتم إرسالة بعد",
  editUpdate: "تعديل بيانات تحديث",
  saveUpdate: "اعتماد التعديل وإرسال التحديث",
  NotificationDeleteMsg: "هل تريد حذف هذا التحديث؟",

  // Notifications
  managements: "الإدارة",
  applicantsS: "الباحثين عن عمل",
  // settings
  currentPass: "كلمة السر الحالية",
  newPass: "كلمة السر الجديدة",
  passConfirm: "تأكيد كلمة السر الجديدة",
  changePass: "تغيير كلمة السر",
  passwordHasBeenReset: "لقد تم تحديث كلمة المرور بنجاح",
  clickToLoginWithNewCred:
    "إضغط هنا لتسجيل الدخول باستخدام البيانات الجديدة الخاصة بك",
  orgUniqueCode: "الرقم المرسل من جانب الجمعية / المؤسسة إن وجد",
  orgUniqueCodeLength: "كود المؤسسة يحتوي علي 13 حرف",
  orgUniqueCodeError: "الكود المدخل لا يطابق المعايير المطلوبة",
  username: "اسم المستخدم",
  email: "البريد الإلكتروني",
  emailSentConfirmLabel: "تأكيد الرسالة",
  emailSentConfirmMsg: "لقد تم إرسال رابط الطلب على بريدك الإلكتروني",

  password: "كلمة السر ",
  passwordConfirm: "تأكيد كلمة السر",
  companyName: "اسم الشركة",
  companyNameRequired: "برجاء اختيار اسم الشركة",
  phone: "رقم الهاتف",
  phoneRequired: "برجاء إدخال رقم الهاتف",
  website: "الموقع الإلكتروني",
  addressInput: "العنوان",
  acceptTerms: "أوافق على",
  terms: "شروط الاستخدام",
  termsDesc: "الخاصة بالموقع واتعهد بتنفيذها",
  acceptTermsRequired: "برجاء الموافقة عل شروط الإستخدام",
  haveAccount: "لديك حساب ؟",
  signin: "سجل دخول",
  applicant: "باحث عن وظيفة",
  organization: "مؤسسة",

  site: "الموقع",
  cvNotes: "مسموح بصيغ (Pdf,Doc) فقط",
  error: "خطأ في الاتصال",
  rememberMe: "تذكرني",
  notRegister: "ليس لديك حساب",
  registerBtn: "أنشئ حساب",
  loading: "برجاء الانتظار...",
  uploading: "جاري الرفع ",
  saveBtn: "حفظ",
  saveDone: "تم الحفظ",
  notAllowed: "غير مسموح لك بالوصول لهذا الملف",

  choose: "إختر",
  noData: " لا يوجد بيانات!",
  passNotMatch: "تأكيد كلمة المرور غير مطابق",
  netwrorkError: "تعذر الاتصال بالخادم!",

  // validations
  usernameError:
    "اسم المستخدم يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  usernameLength: "اسم المستخدم يجب ان لا يقل عن 4 حروف ولا يزيد عن 20 حرف",
  usernameRequired: "برجاء إدخال اسم المستخدم",
  emailError: "البريد الإلكتروني غير صحيح",
  emailRequired: "برجاء إدخال البريد الإلكتروني",
  phoneError: "رقم غير صحيح",
  faxError: "رقم غير صحيح",
  passError: "كلمة المرور غير مطابقة",
  passwordError: "كلمة المرور يجب ان تحتوي على حروف وارقام و رموز",
  passwordLength:
    " كلمة المرور يجب ان لا تقل عددها عن 8 حروف ولا تزيد عن 20 حرف",
  passwordRequired: "برجاء إدخال كلمة السر",
  idNumberRequired: "برجاء إضافة الرقم القومي",
  idNumberError: "الرقم القومي غير صحيح.",
  // .................................................... //

  // pwd //
  //profile
  pwdJobs: "طلبات التوظيف",
  pwdServices: "طلبات الخدمات",
  informations: "المعلومات",
  relatives: "الأقرباء",
  disabilities: "الإعاقات",
  education: "التعليم",
  proExp: "الخبرة المهنية",
  moreInfo: "معلومات إضافية",
  //
  mainInfo: "الأساسية",
  fullName: "الاسم بالكامل",
  id: "الرقم القومي",
  idFront: "الوجه الأمامي",
  idBack: "الوجه الخلفي",
  idFrontBtn: "رفع الوجه الأمامي",
  idBackBtn: "رفع الوجه الخلفي",
  contactEmail: "بريد التواصل",
  mobileNumber: "رقم الموبايل",
  mobileNumberRequired: "برجاء إضافة رقم المحمول",
  mobileError: "الرقم غير صحيح",
  dob: "تاريخ الميلاد",
  dobRequired: "برجاء إدخال تاريخ الميلاد",
  maritalStatus: "الحالة الاجتماعية",
  martialStatusRequired: "برجاء إختيار الحالة الإجتماعية",
  sex: "النوع",
  sexRequired: "برجاء إختيار النوع",
  resCountry: "بلد الإقامة",
  resCountryRequired: "برجاء إختيار بلد الإقامة",
  pwdState: "المركز",
  pwdStateError:
    "اسم المركز يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdStateLength: "اسم المركز يجب ان لا يقل عن 4 حروف وان لا يزيد عن 20 حرف",
  fullAddress: "العنوان بالتفصيل",
  fullAddressRequired: "برجاء إدخال العنوان بالتفصيل",
  fullAddressError:
    "العنوان يجب ان لا يقل عن 4 حروف و لا يحتوى على اي رموز مثل (#$@% الخ)",
  fullAddressLength: "العنوان يجب ان لا يقل عن 4 حروف ولا يزيد عن 200 حرف",
  nationality: "الجنسية",
  cv: "السيرة الذاتية",

  qualifications: "التأهيلية",
  qualificationCert: "شهادة التأهيل",
  qualificationCertError:
    "شهادة التأهيل يجب ان لا تقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  qualificationCertLength:
    "شهادة التأهيل يجب ان لا تقل عن 4 حروف وان لا يزيد عن 20 حرف",
  qualificationBtn: "قم برفع صورة شهادة التأهيل",
  integratedSN: "بطاقة الخدمات",
  integratedBtn: "قم برفع صورة شهادة الخدمات المتكاملة",
  integratedSNError:
    "بطاقة الخدمات يجب ان لا تقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  integratedSNELength:
    "بطاقة الخدمات يجب ان لا تقل عن 4 حروف وان لا يزيد عن 20 حرف",
  martialAndInsurance: "التجنيدية والتأمينية",
  martialStatus: "الموقف التجنيدي",
  martialEndDate: "تاريخ الإنهاء",
  socialInsuranceStatus: "الموقف التأميني",
  addContact: "إضافة قريب جديد",
  editContact: "تعديل بيانات قريب",
  pwdContacts: "الأقرباء المضافون",
  pwdContactRelation: "صلة القرابة",
  pwdContactRelationRequired: "برجاء إختيار صلة القرابة",
  // pwd contacts
  pwdRemoveContact: "هل تريد حذف ",
  pwdRemoveContact2: "من قائمة أقربائك؟",
  // pwd disabilites
  pwdAddDisability: "إضافة إعاقة جديدة",
  pwdEditDisability: "تعديل إعاقة",
  pwdDisabilityTools: "الأدوات المساعدة والأطراف",
  pwdDisabilityStick: "عصا مساعدة",
  pwdDisabilityChair: "كرسي متحرك",
  pwdHearingaid: "سماعات أذن",
  pwdGlasses: "نظارات مساعدة",
  pwdParalizedTool: "جهاز شلل أطفال",
  pwdArtificialLimp: "طرف صناعي",
  pwdOtherAssistiveTools: "أدوات مساعدة أخرى، يرجى ذكرها وكتابتها",
  pwdLimpPart: "نوع الطرف",
  pwdDisabilites: "الإعاقات المضافة",
  pwdDisabilityType: "النوع التفصيلي",
  pwdDisabilityTypeRequired: "برجاء تحديد النوع التفصيلي",
  pwdDisabilityReason: "سبب الإعاقة",
  pwdDisabilityReasonRequired: "برجاء تحديد سبب الإعاقة",
  pwdDisabilityReasonError:
    "سبب الإعاقة يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdDisabilityReasonLength:
    "سبب الإعاقة يجب ان لا يقل عن 4 حروف وان لا يزيد عن 30 حرف",
  pwdReasonDescription: "وصف السبب",
  pwdReasonDescriptionRequired: "برجاء كتابة شرح سبب الإعاقة",
  pwdReasonDescriptionError:
    "سبب الإعاقة يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdReasonDescriptionLength:
    "سبب الإعاقة يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  pwdRemoveDisability: "هل تريد حذف هذه الإعاقة؟",
  disabilites: "الإعاقات",
  jobTargeting: "الفئات المستهدفة",
  moreInfos: "المعلومات الإضافية",
  notFound: "لا يوجد",
  found: "يوجد",
  pwdProfileMsg: "يرجى إكمال ملفك الشخصي لتصلك الوظائف والخدمات المناسبة لك.",
  pwdComProfileMsg: "ملفك الشخصي مكتمل.",
  // pwd Education
  pwdEdu: "مستوى التعليم",
  pwdEduRequired: "برجاء تحديد مستوى التعليم",
  pwdDegree: "الشهادة",
  pwdDegreeRequired: "برجاء تحديد الشهادة",
  pwdGradDate: "تاريخ التخرج",
  pwdGradDateRequired: "برجاء تحديد تاريخ التخرج",
  pwdEduPlace: "مكان التخرج",
  pwdEduPlaceRequired: "برجاء تسجيل مكان التخرج",
  pwdEduPlaceError:
    "مكان التخرج يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdEduPlaceLength:
    "مكان التخرج يجب ان لا يقل عن 4 حروف وان لا يزيد عن 30 حرف",
  pwdRemoveEdu: "هل تريد حذف هذه الشهادة؟",
  addEducations: "الشهادات المضافة",
  addEdu: "إضافة شهادة",
  editEdu: "تعديل شهادة",
  chooseToSee: "إختر المحادثة لعرض الرسائل",
  // pwd Trainings
  pwdAddTraining: "إضافة تدريب جديد",
  pwdEditTraining: "تعديل التدريب",
  pwdTrainings: "التدريبات المضافة",
  pwdTrainingName: "اسم التدريب",
  pwdTrainingNameRequired: "برجاء تسجيل اسم التدريب",
  pwdTrainingNameError:
    "اسم التدريب يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdTrainingNameLength:
    "اسم التدريب يجب ان لا يقل عن مقطع وان لا يزيد عن 30 حرف",
  pwdTrainingSector: "مجال التدريب",
  pwdTrainingSectorRequired: "برجاء تحديد مجال التدريب ",
  pwdTrainingSpeciality: "التخصص",
  pwdTrainingSpecialityRequired: "برجاء تحديد التخصص ",
  pwdTrainingCode: "الكود",
  pwdTrainingCodeError:
    "الكود يجب ان لا يقل عن  مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdTrainingCodeLength: "الكود يجب ان لا يقل عن  مقطع وان لا يزيد عن 20 حرف",
  pwdTrainingCertType: "نوع الشهادة",
  pwdTrainingCertTypeRequired: "برجاء تحديد نوع الشهادة",
  pwdTrainingDate: "تاريخ الحصول",
  pwdTrainingDateRequired: "برجاء تسجيل تاريخ الحصول",
  pwdRemoveTraining: "هل تريد حذف التدريب؟",
  call: "طلب اتصال من ",
  answer: "رد",

  // pwd ٍُExp
  pwdAddExp: "إضافة خبرة جديدة",
  pwdEditExp: "تعديل خبرة",
  pwdExp: "الخبرات المضافة",
  pwdExpName: "مسمى الوظيفة",
  pwdExpNameRequired: "برجاء تحديد مسمى الوظيفة",
  pwdExpCompany: "جهة العمل",
  pwdExpCompanyRequired: "برجاء تسجيل جهة العمل",
  pwdExpCompanyError:
    "جهة العمل يجب ان لا تقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdExpCompanyLength:
    "جهة العمل يجب ان لا تقل عن 4 حروف وان لا يزيد عن 30 حرف",
  pwdExpStartDate: "تاريخ البدء",
  pwdExpStartDateRequired: "برجاء تسجيل تاريخ البدأ",
  pwdExpEndDate: "تاريخ الانتهاء",
  pwdExpEndDateRequired: "برجاء تسجيل تاريخ الإنتهاء",
  toDateError: "تأكد من التواريخ المدخلة",
  pwdExpDescription: "التوصيف الرئيسى",
  pwdExpDescriptionRequired: "برجاء تسجيل التوصيف الرئيسي",
  pwdExpDescriptionError:
    "التوصيف الرئيسي يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  pwdExpDescriptionLength:
    "التوصيف الرئيسي يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  pwdExpSalary: "المرتب",
  pwdExpSalaryError: "الرقم غير صحيح",
  pwdSkills: "المهارات",
  pwdSkillsRequired: "برجاء تحديد المهارات الخاصة بك",
  pwdShowSalary: "إظهار المرتب",
  pwdExpDesc: "الوصف",
  pwdRemoveExp: "هل تريد حذف هذه الخبرة؟",

  // pwd job applications
  pwdCurrentApplications: "طلبات التوظيف الحالية",
  pwdOldApplications: "طلبات التوظيف المنتهية",
  pwdProfileSeen: "تم رؤية ملفك الشخصي",
  pwdProfileNotSeen: "لم يتم رؤية ملفك الشخصي",
  pwdJobSuitableStatus: "متطلبات الوظيفة",
  pwdJobSuitable: "مناسب",
  pwdJobSuitableBtn: "مناسب للوظيفة",
  pwdJobNotSuitable: "غير مناسب",
  pwdJobPending: "لم يحدد الموقف",
  pwdJobAcceptanceStatus: "قبول / رفض",
  pwdJobAccepted: "تم قبولك",
  pwdJobRejected: "لم يتم قبولك",
  pwdJobHired: "تم توظيفك",
  pwdJobNotHired: "لم يتم توظيفك",
  pwdShowJob: "عرض الوظيفة",
  pound: "ج.م",
  pwdAccept: "توظيف",
  pwdReject: "رفض",

  // pwd services
  pwdGoToServices: "تصفح مقدمو الخدمات",
  pwdShowServiceBtn: "عرض الخدمة",
  pwdServiceMatch: "تصلح لك",
  pwdServiceNotMatch: "لا تصلح لك",
  pwdServiceAccepted: "تم قبولك",
  pwdServiceRejected: "لم يتم قبولك",
  pwdServicePending: "لم يحدد موقفك من الخدمة",
  pwdServiceSuitableStatus: "متطلبات الخدمة",

  // jobs page
  jobSort: "الوظائف حسب التصنيف",
  locationSort: "الوظائف حسب الموقع",
  skillsSort: "الوظائف حسب المهارة",
  salaryMinSort: "الوظائف حد أدنى للمرتب",
  salaryMaxSort: "الوظائف حد أقصى للمرتب",
  jobView: "انت الآن تشاهد",
  jobView2: "من الوظائف",
  jobTypeSort: "الوظائف حسب النوع",
  jobTitle: "معلومات سريعة",
  noJobs: "لا يوجد وظائف متاحة!",
  jobProfileCompletion:
    " برجاء استكمال ملفك الشخصى والجزء الخاص بالتعليم والأعاقة لتتمكن من التقدم علي الوظائف ", //added by Heba
  loginToApply: "يجب ان يتم تسجيل الدخول اولاً لتتمكن من التقديم!",

  zeroJobs: "لا يوجد وظائف", //new

  experince: "خبرة من",
  to: "إلى",
  years: "سنوات",
  aboutJob: "عن الوظيفة",
  registerOrLogin: "تسجيل الدخول",
  notRegisterd: "يجب ان يتم تسجيل الدخول اولاً!",
  jobApplied: "تم التقديم",
  applied: "تم التقديم بالوظيفة",
  pwdJobStatus: "الموقف من الوظيفة",
  pwdProfile: "الملف الشخصي للباحث عن الوظيفة",
  cvBtn: "تحميل السيرة الذاتية",
  pwdContact: "التواصل مع الباحث عن العمل",
  pwdPersonalData: "المعلومات الشخصية",
  makeCvMandatory: "يجب على المتقدم إدخال السيرة الذاتية الخاصة به",

  // services page
  noServices: "لا يوجد خدمات متاحة!",
  zeroServices: "لا يوجد خدمات", //neww
  aboutService: "عن الخدمة",
  allServices: "كل الخدمات",
  pwdServiceStatus: "الموقف من الخدمة",
  pwdSerProfile: "الملف الشخصي للباحث عن الخدمة",
  serviceSalarySort: "الخدمات حسب المرتب",
  serviceTypeSort: "الخدمات حسب النوع",
  serviceSearchBtn: "ابحث عن خدمة الآن",
  serviceNameSearch: "عن اي خدمة تبحث؟",
  serviceError:
    "اسم الخدمة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  serviceLength: "اسم الخدمة يجب ان لا يقل عن مقطع وان لا يزيد عن 30 حرف",
  serviceTypeSearch: "ما تصنيف الخدمة التي تبحث عنها؟",
  serviceAmountSearch: "ما هو المقابل المتوقع والمجزي لك؟",
  servicesApplied: "تم التقديم بالخدمة",
  serviceProfileCompletion:
    " برجاء استكمال ملفك الشخصى والجزء الخاص بالتعليم والأعاقة لتتمكن من التقدم علي الخدمات ", //added by Heba

  // home page
  homeT1: "الشبكة القومية لخدمات ",
  homeT1N: "تأهيل ",
  homeTC: "ذوي الإعاقة",
  homeT2: "دعم الأشخاص ذوي الإعاقة للوصول إلى فرص أفضل للتطوير الوظيفي",
  homeDesc:
    "عن طريق دعم أصحاب الأعمال لتوظيف الأشخاص ذوي الإعاقة ودعم مقدمو الخدمات للنفاذ إلى الأشخاص ذوي الإعاقة",
  homeFeatures: "دعم ذوي الإعاقة بمميزات عدة!",
  homeF1: "دليل مقدمو الخدمات والخدمات المتاحة.",
  homeF2: "محتوى مرئي للمساهمة في التطوير الوظيفي.",
  homeF3: "محتوى مقروء لإثراء وإثقال مهاراتهم.",
  homeF4: "تسجيل السيرة الذاتية وتوصيات عن التحسينات الممكنة.",
  homeF5: "البحث عن فرص التدريب والتوظيف في شتى المجالات.",
  homeOrgCo: "شركة أو مؤسسة؟",
  homeOrgCoDes:
    "يمكنك إثراء المحتوى عن طريق إضافة المحتوى المرئي والكتابي لدعم ذوي الإعاقة",
  homeOrgCoDes2: "يمكنك نشر التحديثات الهامة لمجموعة معينة من المستخدمين",
  homeOrgCoDes3: "يمكنك أيضاً نشر فرص التوظيف لديك",
  andMore: "والمزيد!",

  // organizations page
  orgProfile: "عرض المؤسسة",
  available: "متاحة",
  service: "خدمة",
  orgJobs: "وظيفة متاحة",
  aboutOrg: "عن المؤسسة",
  aboutOrgRequired: "برجاء تسجيل معلومات عن المؤسسة",
  aboutOrgError:
    "يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  aboutOrgLength: "يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  orgAddress: "عنوان المؤسسة",
  socialMedia: "وسائل التواصل",
  allOrgs: "كل المؤسسات",
  showJobs: "عرض الوظائف",
  showServices: "عرض الخدمات",
  goBack: "متابعة",
  linked: "تم الربط",
  description: "وصف الوظيفة",
  descriptionRequired: "برجاء إدخال وصف تفصيلي للوظيفة",
  descriptionError:
    "وصف الوظيفة يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  descriptionLength:
    "وصف الوظيفة يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  serviceDescriptionRequired: "برجاء إدخال وصف تفصيلي للخدمة",
  serviceDescriptionError:
    "وصف الخدمة يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  serviceDescriptionLength:
    "وصف الخدمة يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  // companies page
  coName: "اسم الشركة",
  coNameRequired: "برجاء إدخال اسم الشركة",
  coNameError:
    "اسم الشركة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  coNameLength: "اسم الشركة يجب ان لا يقل عن مقطع ولا يزيد عن 50 حرف",
  coFullLocationDefined:
    "برجاء وضع رابط الإحداثيات الخاص بموقع الشركة من موقع خرائط جوجل Google Maps في المكان المخصص له",
  test: "",
  // contact us page
  contactUs: "تواصل معنا",
  contactUsTitle: "تواصل معنا على مدار الساعة طوال أيام الأسبوع",
  contactUsNumber: "اتصل بنا على",
  contactUsEmail: "أو راسلنا على البريد الإلكتروني",
  contactUsSendMsg: "اترك رسالة",
  name: "الاسم",
  nameRequired: "برجاء إدخال الاسم",
  nameError:
    "اسم المستخدم يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  nameLength: "اسم المستخدم يجب ان لا يقل عن مقطع و لا يزيد عن 30 حرف",
  subject: "موضوع الرسالة",
  subjectRequired: "برجاء تسجيل موضوع الرسالة",
  subjectError:
    "موضوع الرسالة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  subjectLength: "موضوع الرسالة يجب ان لا يقل عن مقطع و لا يزيد عن 100 حرف",
  msg: "نص الرسالة",
  msgRequired: "برجاء تسجيل الرسالة",
  msgError:
    "موضوع الرسالة يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  msgLength: "الرسالة يجب أن لا تقل عن مقطع ولا تزيد عن 500 حرف",
  contactInfo: "بيانات التواصل",
  send: "إرسال",
  // technical problems and suggestions page
  techProblemsAndSuggestionsMessages: "الدعم الفني",
  techMsg: "لتلقي والابلاغ عن الشكاوى والمقترحات التقنية",
  //  companies-------
  // profile
  aboutCo: "عن الشركة",
  aboutCoRequired: "برجاء تسجيل معلومات عن الشركة",
  aboutCoError:
    "يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  aboutCoLength: "يجب ان لا يقل عن 4 حروف وان لا يزيد عن 500 حرف",
  commercialRegister: "السجل التجاري",
  commercialRegisterBtn: "قم برفع صورة السجل التجاري",
  wheelchairElevator: "هل يسمح المصعد باستخدام كرسي متحرك على اختلاف حجمة؟",
  wheelchairBathroom: "مساحة الحمام تكفي لدخول كرسي متحرك وغلق الباب بسهولة؟ ",
  wheelchairOffice: "المساحات بين المكاتب تكفي لتحرك مستخدم الكرسي بسهولة؟",
  entrance: "يرجى ذكر ما هو نوع مدخل الشركة ( مثال: رصيف / سلالم )",
  entranceError:
    "يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  transportation: "المواصلات",
  transportationError:
    "يجب ان لا يقل عن 4 حروف وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  transportationAvability: "حالة التوفر",
  transportationType: "نوع المواصلة",
  siteAvailability: "الإتاحة الموجودة بالمكان",
  chairElevator: "يسمح المصعد باستخدام كرسي متحرك على اختلاف حجمة.",
  chairBathroom: "مساحة الحمام تكفي لدخول كرسي متحرك وغلق الباب بسهولة.",
  chairOffice: "المساحة بين المكاتب تكفي لتحرك مستخدم الكرسي بسهولة",
  companyEntrance: "مدخل الشركة",
  noTransportation: "لا يوجد مواصلات",
  coProfile: "عرض الشركة",
  allCompanies: "كل الشركات",

  // home articles
  articles: "المقالات",
  articleName: "اسم المقال",
  admin: "الإدارة",
  comments: "التعليقات",
  addComment: "اترك تعليقا",
  commentError:
    "التعليق يجب ان لا يقل عن مقطع وان لا يحتوى على اي رموز مثل (#$@% الخ)",
  commentLength: "التعليق يجب ان لا يقل عن مقطع ولا يزيد عن 500 حرف",
  commentRequired: "برجاء ترك تعليق",

  // forgetpassword
  forgetPass: "هل نسيت كلمة السر؟ اضغط هنا لتحديثها!",
  email_username_mobile_required:
    "يجب إدخال البريد الإلكتروني أو اسم المستخدم أو رقم الموبايل",
  email_username_mobile_error:
    "تأكد من البريد الإلكتروني أو اسم المستخدم أو رقم الموبايل",
  email_username_mobile: "ادخل بريدك الإلكتروني أو اسم المسخدم أو رقم الموبايل",
  enterYourEmail: "ادخل بريدك الإلكتروني",
  resetLinkExpired:
    "تم إنتهاء صلاحية رابط التحديث, أدخل بريدك الألكتروني مرة أخرى",

  searchByCategory: "بحث حسب القسم",
  askForHelp: "لطلب الدعم تواص معنا على",
  ourFacebookPage: "صفحتنا على فيسبوك",
};

export const en = {
  baseUrl: baseUrl,
  login: "Login",
  register: "Register",
};

import * as yup from "yup";
import { ar } from "../Assets/env";

export const loginValidationSchema = yup.object({
  input: yup
    .string()
    .required(ar.email_username_mobile_required)
    .test("test", ar.email_username_mobile_error, function(value) {
      const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const usernameRegex = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9.]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9.]$/;
      const phoneRegex = /^01[0125][0-9]{8}$/;
      let isValidEmail = emailRegex.test(value);
      let isValidUsername = usernameRegex.test(value);
      let isValidPhone = phoneRegex.test(value);
      if (!isValidEmail && !isValidUsername && !isValidPhone) {
        return false;
      } else {
        return true;
      }
    }),
  password: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordLength)
    .max(20, ar.passwordLength)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
});
export const registerValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.nameRequired)
    .min(1, ar.nameLength)
    .max(30, ar.nameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.nameError
    ),
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  password: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordLength)
    .max(20, ar.passwordLength)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
  passConfirm: yup
    .string()
    .required(ar.passwordRequired)
    .oneOf([yup.ref("password")], ar.passError),
  acceptTerms: yup
    .boolean()
    .required(ar.acceptTermsRequired)
    .oneOf([true], ar.acceptTermsRequired),
});

export const pwdRegisterValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.nameRequired)
    .min(1, ar.nameLength)
    .max(30, ar.nameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.nameError
    ),
  orgUniqueCode: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null)) // nullable makes the value to accept null also. transform callback returns the value as null if value is empty.
    .min(13, ar.orgUniqueCodeLength)
    .max(13, ar.orgUniqueCodeLength)
    .matches(/^npd-\d{4}-\w{4}$/, ar.orgUniqueCodeError),
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  password: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordLength)
    .max(20, ar.passwordLength)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
  passConfirm: yup
    .string()
    .required(ar.passwordRequired)
    .oneOf([yup.ref("password")], ar.passError),
  acceptTerms: yup
    .boolean()
    .required(ar.acceptTermsRequired)
    .oneOf([true], ar.acceptTermsRequired),
});

export const subscribtionValidationSchema = yup.object({
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
});
export const pwdMainInfoValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.nameRequired)
    .min(1, ar.nameLength)
    .max(30, ar.nameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.nameError
    ),
  orgUniqueCode: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null)) // nullable makes the value to accept null also. transform callback returns the value as null if value is empty.
    .min(13, ar.orgUniqueCodeLength)
    .max(13, ar.orgUniqueCodeLength)
    .matches(/^npd-\d{4}-\w{4}$/, ar.orgUniqueCodeError),
  username: yup
    .string()
    .required(ar.usernameRequired)
    .min(4, ar.usernameLength)
    .max(20, ar.usernameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9.]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9.]$/,
      ar.usernameError
    ),
  idNumber: yup
    .number()
    .required(ar.idNumberRequired)
    .typeError(ar.idNumberError)
    .test("len", ar.idNumberError, (val) => {
      if (val) return val.toString().length === 14;
    }),
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  mobile: yup
    .string()
    .required(ar.mobileNumberRequired)
    .matches(/^01[0125][0-9]{8}$/, ar.mobileError),
  dob: yup
    .date()
    .typeError(ar.dobRequired)
    .required(ar.dobRequired),
  maritalStatus: yup.string().required(ar.martialStatusRequired),
  gender: yup.string().required(ar.sexRequired),
  country: yup.string().required(ar.resCountryRequired),
  province: yup.string().required(ar.provinceRequired),
  city: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null)) // nullable makes the value to accept null also. transform callback returns the value as null if value is empty.
    .min(4, ar.cityLength)
    .max(20, ar.cityLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.cityError
    ),
  state: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.pwdStateLength)
    .max(20, ar.pwdStateLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.pwdStateError
    ),
  address: yup
    .string()
    .required(ar.fullAddressRequired)
    .min(4, ar.fullAddressLength)
    .max(200, ar.fullAddressLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 \n . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 \n . , ]$/,
      ar.fullAddressError
    ),
  qualificationCert: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.qualificationCertLength)
    .max(20, ar.qualificationCertLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.qualificationCertError
    ),
  integratedSN: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.integratedSNELength)
    .max(20, ar.integratedSNELength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.integratedSNError
    ),
  martialEndDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .typeError("Invalid Date"),
});

export const pwdAddEditContactSchema = yup.object({
  contactName: yup
    .string()
    .required(ar.nameRequired)
    .min(1, ar.nameLength)
    .max(30, ar.nameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.nameError
    ),
  contactRelation: yup.string().required(ar.pwdContactRelationRequired),
  contactMobile: yup
    .string()
    .required(ar.mobileNumberRequired)
    .matches(/^01[0125][0-9]{8}$/, ar.mobileError),
  contactEmail: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
});

export const pwdAddEditDisabilitySchema = yup.object({
  disabilityType: yup.string().required(ar.disTypeRequired),
  disabilityDescription: yup.string().required(ar.pwdDisabilityTypeRequired),
  disabilityReason: yup
    .string()
    .required(ar.pwdDisabilityReasonRequired)
    .min(4, ar.pwdDisabilityReasonLength)
    .max(30, ar.pwdDisabilityReasonLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.pwdDisabilityReasonError
    ),
  disabilityReasonDescription: yup
    .string()
    .required(ar.pwdReasonDescriptionRequired)
    .min(4, ar.pwdReasonDescriptionLength)
    .max(500, ar.pwdReasonDescriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9\n . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9\n . , ]$/,
      ar.pwdReasonDescriptionError
    ),
});

export const pwdAddEditEducationSchema = yup.object({
  educationLevel: yup.string().required(ar.pwdEduRequired),
  educationCertType: yup.string().required(ar.pwdTrainingCertTypeRequired),
  educationCertificate: yup.string().required(ar.pwdDegreeRequired),
  educationPlace: yup
    .string()
    .required(ar.pwdEduPlaceRequired)
    .min(4, ar.pwdEduPlaceLength)
    .max(30, ar.pwdEduPlaceLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.pwdEduPlaceError
    ),
  educationDate: yup
    .date()
    .nullable()
    .typeError(ar.pwdGradDateRequired)
    .required(ar.pwdGradDateRequired),
});

export const pwdAddEditTrainingSchema = yup.object({
  trainingName: yup
    .string()
    .required(ar.pwdTrainingNameRequired)
    .min(1, ar.pwdTrainingNameLength)
    .max(30, ar.pwdTrainingNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.pwdTrainingNameError
    ),
  trainingCode: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.pwdTrainingCodeLength)
    .max(20, ar.pwdTrainingCodeLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.pwdTrainingCodeError
    ),
  trainingSector: yup.string().required(ar.pwdTrainingSectorRequired),
  speciality: yup.string().required(ar.pwdTrainingSpecialityRequired),
  certificateType: yup.string().required(ar.pwdTrainingCertTypeRequired),
  certificateDate: yup
    .date()
    .nullable()
    .typeError(ar.pwdTrainingDateRequired)
    .required(ar.pwdTrainingDateRequired),
});

export const pwdAddEditExperienceSchema = yup.object({
  jobTitle: yup.string().required(ar.pwdExpNameRequired),
  companyName: yup
    .string()
    .required(ar.pwdExpCompanyRequired)
    .min(4, ar.pwdExpCompanyLength)
    .max(30, ar.pwdExpCompanyLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.pwdExpCompanyError
    ),
  startDate: yup
    .date()
    .nullable()
    .typeError(ar.pwdExpStartDateRequired)
    .required(ar.pwdExpStartDateRequired),
  endDate: yup
    .date()
    .nullable()
    .typeError(ar.pwdExpEndDateRequired)
    .required(ar.pwdExpEndDateRequired)
    .min(yup.ref("startDate"), ar.toDateError),
  jobDescription: yup
    .string()
    .required(ar.pwdExpDescriptionRequired)
    .min(4, ar.pwdExpDescriptionLength)
    .max(500, ar.pwdExpDescriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . \n , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . \n , ]$/,
      ar.pwdExpDescriptionError
    ),
  paidSalary: yup
    .number()
    .nullable()
    .min(0, ar.pwdExpSalaryError)
    .typeError(ar.pwdExpSalaryError)
    .transform((value) => {
      if (value === "") return null;
      return parseFloat(value);
    })
    .default(null),
});

export const editSettingsSchema = yup.object({
  currentPass: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordError)
    .max(20, ar.passwordError)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
  password: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordLength)
    .max(20, ar.passwordLength)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
  passConfirm: yup
    .string()
    .required(ar.passwordRequired)
    .oneOf([yup.ref("password")], ar.passError),
});

export const coMainInfoValidationSchema = yup.object({
  coName: yup
    .string()
    .required(ar.coNameRequired)
    .min(1, ar.coNameLength)
    .max(50, ar.coNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.coNameError
    ),
  username: yup
    .string()
    // .required(ar.usernameRequired) //Heba
    .min(4, ar.usernameError)
    .max(20, ar.usernameError)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9.]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9.]$/,
      ar.usernameError
    ),
  country: yup.string().required(ar.countryRequired),
  province: yup.string().required(ar.provinceRequired),
  city: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.cityLength)
    .max(20, ar.cityLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 ]$/,
      ar.cityError
    ),
  state: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.stateLength)
    .max(20, ar.stateLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.stateError
    ),
  address: yup
    .string()
    .required(ar.fullAddressRequired)
    .min(4, ar.fullAddressLength)
    .max(200, ar.fullAddressLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.fullAddressError
    ),
  location: yup
    .string()
    .required(ar.fullLocationRequired)
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  industry: yup.string().required(ar.fieldRequired),
  brief: yup
    .string()
    .required(ar.aboutCoRequired)
    .min(4, ar.aboutCoLength)
    .max(500, ar.aboutCoLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.aboutCoError
    ),
  chairElevator: yup
    .boolean()
    .nullable()
    .transform((value) => (!!value ? value : null)),
  chairBathroom: yup
    .boolean()
    .nullable()
    .transform((value) => (!!value ? value : null)),
  chairOffice: yup
    .boolean()
    .nullable()
    .transform((value) => (!!value ? value : null)),
  companyEntrance: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.entranceError
    ),
  transportation: yup
    .boolean()
    .nullable()
    .transform((value) => (!!value ? value : null)),
  transportationType: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.transportationError
    ),
  phone: yup
    .string()
    .required(ar.phoneRequired)
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, ar.phoneError),
  mobile: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^01[0125][0-9]{8}$/, ar.phoneError),
  email: yup
    .string()
    // .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  website: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  linkedin: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  facebook: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/, ar.urlError),
  twitter: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?twitter.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  youtube: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?youtube.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  instagram: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
      ar.urlError
    ),
});

export const jobAddEditValidationSchema = yup.object({
  jobTitle: yup
    .string()
    .required(ar.jobNameRequired)
    .min(1, ar.jobNameLength)
    .max(30, ar.jobNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.jobNameError
    ),
  workType: yup.string().required(ar.jobTypeRequired),
  about: yup
    .string()
    .required(ar.descriptionRequired)
    .min(4, ar.descriptionLength)
    .max(500, ar.descriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.descriptionError
    ),
  country: yup.string().required(ar.jobCountryRequired),
  province: yup.string().required(ar.provinceRequired),
  workField: yup.string().required(ar.jobFieldRequired),
  workFieldType: yup.string().required(ar.jobfRequired),
  trainingSector: yup.string().required(ar.pwdTrainingSectorRequired),
  speciality: yup.string().required(ar.pwdTrainingSpecialityRequired),
  educationLevel: yup.string().required(ar.pwdEduRequired),
  educationCertType: yup.string().required(ar.pwdTrainingCertTypeRequired),
  educationCertificate: yup.string().required(ar.pwdDegreeRequired),
  minExperience: yup
    .number()
    .nullable()
    .min(0, ar.minExpError)
    .typeError(ar.minExpError)
    .transform((value) => {
      if (value === "") return null;
      return parseFloat(value);
    })
    .default(null),
  maxExperience: yup
    .number()
    .nullable()
    .min(0, ar.maxExpError)
    .typeError(ar.maxExpError)
    .transform((value) => {
      if (value === "") return null;
      return parseFloat(value);
    })
    .default(null),
  minSalary: yup
    .number()
    .nullable()
    .min(0, ar.minSalaryError)
    .typeError(ar.minSalaryError)
    .transform((value) => {
      if (value === "") return null;
      return parseFloat(value);
    })
    .default(null),
  maxSalary: yup
    .number()
    .nullable()
    .min(0, ar.maxSalaryError)
    .typeError(ar.maxSalaryError)
    .transform((value) => {
      if (value === "") return null;
      return parseFloat(value);
    })
    .default(null),
  // title: yup.string()
  //     .required(ar.modalInputRequired)
  //     .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9]$/, ar.modalInputError),
});

export const sendMsgValidationSchema = yup.object({
  message: yup
    .string()
    .required(ar.MsgError)
    .min(1, ar.msgLength)
    .max(500, ar.msgLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.MsgError
    ),
});

export const addEditArticleValidationSchema = yup.object({
  title: yup
    .string()
    .required(ar.articleTitleRequired)
    .min(1, ar.articleTitleLength)
    .max(100, ar.articleTitleLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.articleTitleError
    ),
  imageDescription: yup
    .string()
    .required(ar.imageDescriptionRequired)
    .min(1, ar.imageDescriptionLength)
    .max(100, ar.imageDescriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.imageDescriptionError
    ),
  body: yup
    .string()
    .required(ar.articleAboutRequired)
    .min(4, ar.articleAboutLength)
    .max(3000, ar.articleAboutLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.articleAboutError
    ),
});

export const addEditVideosValidationSchema = yup.object({
  youtube: yup.string().required(ar.youtubeLinkRequired),
  title: yup
    .string()
    .required(ar.videoTitleRequired)
    .min(1, ar.videoTitleLength)
    .max(100, ar.videoTitleLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.videoTitleError
    ),
  imageDescription: yup
    .string()
    .required(ar.imageDescriptionRequired)
    .min(1, ar.imageDescriptionLength)
    .max(100, ar.imageDescriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.imageDescriptionError
    ),
  body: yup
    .string()
    .required(ar.videoDescRequired)
    .min(4, ar.videoDescLength)
    .max(3000, ar.videoDescLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.videoDescError
    ),
});

export const addCommentValidationSchema = yup.object({
  comment: yup
    .string()
    .required(ar.commentRequired)
    .min(1, ar.commentLength)
    .max(500, ar.commentLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.commentError
    ),
});

export const updatesAddEditValidationSchema = yup.object({
  country: yup.string().required(ar.countryRequired),
  title: yup
    .string()
    .required(ar.updateTitleRequired)
    .min(1, ar.updateTitleLength)
    .max(100, ar.updateTitleLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.updateTitleError
    ),
  description: yup
    .string()
    .required(ar.updateDesctiptionRequired)
    .min(4, ar.updateDesctiptionLength)
    .max(500, ar.updateDesctiptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.updateDesctiptionError
    ),
});

export const orgMainInfoValidationSchema = yup.object({
  orgName: yup
    .string()
    .required(ar.orgNameRequired)
    .min(1, ar.orgNameLength)
    .max(50, ar.orgNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . ]$/,
      ar.orgNameError
    ),
  username: yup
    .string()
    // .required(ar.usernameRequired) //Heba
    .min(4, ar.usernameError)
    .max(20, ar.usernameError)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9.]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9.]$/,
      ar.usernameError
    ),
  country: yup.string().required(ar.countryRequired),
  province: yup.string().required(ar.provinceRequired),
  city: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.cityLength)
    .max(20, ar.cityLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.cityError
    ),
  state: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.stateLength)
    .max(20, ar.stateLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.stateError
    ),
  address: yup
    .string()
    .required(ar.fullAddressRequired)
    .min(4, ar.fullAddressLength)
    .max(200, ar.fullAddressLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.fullAddressError
    ),
  location: yup
    .string()
    .required(ar.fullLocationRequired)
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  industry: yup.string().required(ar.fieldRequired),
  type: yup.string().required(ar.entityRequired),
  brief: yup
    .string()
    .required(ar.aboutOrgRequired)
    .min(4, ar.aboutOrgLength)
    .max(500, ar.aboutOrgLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.aboutOrgError
    ),
  phone: yup
    .string()
    .required(ar.phoneRequired)
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, ar.phoneError),
  mobile: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^01[0125][0-9]{8}$/, ar.phoneError),
  fax: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, ar.faxError),
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  website: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  linkedin: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  facebook: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/, ar.urlError),
  twitter: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?twitter.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  youtube: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /^(ftp|http|https):\/\/?(?:www\.)?youtube.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
  instagram: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .matches(
      /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
      ar.urlError
    ),
});

export const serviceAddEditValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.serviceNameRequired)
    .min(1, ar.serviceNameLength)
    .max(30, ar.serviceNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.serviceNameError
    ),
  amount: yup
    .number()
    .required(ar.serviceFeesRequired)
    .typeError(ar.serviceFeesError)
    .max(999999, ar.serviceFeesSizeError),
  type: yup.string().required(ar.serviceTypeRequired),
  disabilityEnabled: yup.string().required(ar.disEnabledRequired),
  description: yup
    .string()
    .required(ar.serviceDescriptionRequired)
    .min(4, ar.serviceDescriptionLength)
    .max(500, ar.serviceDescriptionLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.serviceDescriptionError
    ),
  // title: yup.string()
  //     .required(ar.modalInputRequired)
  //     .matches(/^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9]$/, ar.modalInputError),
});

export const branchesValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.branchNameRequired)
    .min(1, ar.branchNameLength)
    .max(30, ar.branchNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.branchNameError
    ),
  country: yup.string().required(ar.countryRequired),
  province: yup.string().required(ar.provinceRequired),
  city: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.cityLength)
    .max(20, ar.cityLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.cityError
    ),
  state: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(4, ar.stateLength)
    .max(20, ar.stateLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.stateError
    ),
  address: yup
    .string()
    .required(ar.fullAddressRequired)
    .min(4, ar.fullAddressLength)
    .max(200, ar.fullAddressLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , \n ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , \n ]$/,
      ar.fullAddressError
    ),
  location: yup
    .string()
    .required(ar.fullLocationRequired)
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ar.urlError
    ),
});

export const companiesValidationSchema = yup.object({
  company: yup.string().required(ar.companyNameRequired),
});

export const jobsSearchValidationSchema = yup.object({
  jobTitle: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.jobNameLength)
    .max(30, ar.jobNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.jobNameError
    ),
});

export const servicesSearchValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.serviceLength)
    .max(30, ar.serviceLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.serviceError
    ),
});

export const organizationsSearchValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.orgNameLength)
    .max(50, ar.orgNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.orgNameError
    ),
});

export const companiesSearchValidationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.coNameLength)
    .max(50, ar.coNameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.coNameError
    ),
});

export const articlesSearchValidationSchema = yup.object({
  title: yup
    .string()
    .nullable()
    .transform((value) => (!!value ? value : null))
    .min(1, ar.articleTitleLength)
    .max(100, ar.articleTitleLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.articleTitleError
    ),
});

export const contactUsValidationSchema = yup.object({
  name: yup
    .string()
    .required(ar.nameRequired)
    .min(4, ar.nameLength)
    .max(30, ar.nameLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.nameError
    ),
  email: yup
    .string()
    .required(ar.emailRequired)
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ar.emailError
    ),
  subject: yup
    .string()
    .required(ar.subjectRequired)
    .min(1, ar.subjectLength)
    .max(100, ar.subjectLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.subjectError
    ),
  message: yup
    .string()
    .required(ar.msgRequired)
    .min(1, ar.msgLength)
    .max(500, ar.msgLength)
    .matches(
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9 . , ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9 . , ]$/,
      ar.msgError
    ),
});

export const requestLinkSchema = yup.object({
  input: yup
    .string()
    .required(ar.email_username_mobile_required)
    .test("test", ar.email_username_mobile_error, function(value) {
      const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const usernameRegex = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z0-9., ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_0-9., ]$/;
      const phoneRegex = /^01[0125][0-9]{8}$/;
      let isValidEmail = emailRegex.test(value);
      let isValidUsername = usernameRegex.test(value);
      let isValidPhone = phoneRegex.test(value);
      if (!isValidEmail && !isValidUsername && !isValidPhone) {
        return false;
      } else {
        return true;
      }
    }),
});
export const forgetAndResetPassSchema = yup.object({
  password: yup
    .string()
    .required(ar.passwordRequired)
    .min(8, ar.passwordLength)
    .max(20, ar.passwordLength)
    .matches(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/, ar.passwordError),
  passConfirm: yup
    .string()
    .required(ar.passwordRequired)
    .oneOf([yup.ref("password")], ar.passError),
});

import { useEffect, useState } from 'react'
import { Collapse, Col, Row, Card } from 'react-bootstrap'
import './css/orgCards.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle, faFilm, faMinus, faNewspaper, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TextToolbar, TextCardTitle, TextCardSubtitle, Button, pwdSuitables, pwdStatus, pwdHire, CSelect, TextRegSub } from './index'
import moment from 'moment'
import { applicantAcceptApi, applicantSuitableApi, applicantHireApi } from '../Api'
import user from '../Assets/imgs/user.webp' //neww 
import Job from '../Assets/imgs/jobs.webp' //neww

export function ServiceCard({ lang, data, terminated, remove, changeStatus, edit, pwd, service }) {
    const { img, name, type, applicants, _id } = data
    const [apOpen, setOpen] = useState(false)
    const ImgSrc = img && img.length > 0 ? `${lang.baseUrl}/org/media/imgs/${img && img}` : Job

    return (
        <Card className="h-100 pt-5 justify-content-center">
            <div className='org-card-label mx-0' >
                {type}
            </div>
            <Col lg="12">
                <Row className="w-100">
                    <Col lg="3">
                        <Card.Img alt={'صورة خلفية للخدمة'} className='org-card-img' src={ImgSrc} />
                    </Col>
                    <Col lg="4" >
                        <Col>
                            <Row className="pt-2 px-4">
                                <Card.Subtitle  >
                                    {name}
                                </Card.Subtitle>
                            </Row>
                            <Row className="pt-2 px-4">
                                <Card.Subtitle className='pt-3'>
                                    {lang.applicantsN}<span style={{ color: '#5fc0e9' }}>{applicants.length}</span> {lang.person}
                                </Card.Subtitle>
                            </Row>
                        </Col>
                    </Col>

                    <Col lg="5" className='pt-5 '>
                        <Row className='px-3'>
                            <Col xs='4.5'>
                                <Button onClick={() => changeStatus(data)} fullWidth size='sm' type={terminated ? "success" : "secondary"} text={terminated ? lang.actBtn : lang.tsBtn} />
                            </Col>
                            <Col xs='4'>
                                <Button onClick={() => edit(data)} fullWidth size='sm' text={lang.edit} />
                            </Col>
                            <Col xs="3.5">
                                <Button onClick={() => remove(data)} fullWidth size='sm' type="outline-primary" text={lang.delete} />
                            </Col>
                        </Row>
                    </Col>
                    <Row className='org-card-footer w-100 pt-4 '>
                        <div onClick={() => setOpen(!apOpen)} className={apOpen ? 'org-card-f-title-active' : 'org-card-f-title'}>
                            {apOpen ? lang.hiddeApplicants : lang.showApplicants}
                        </div>
                        <Collapse in={apOpen} className="w-100 mr-4 ml-0" >
                            <Row className='mr-4 ml-0'>
                                {applicants.length > 0 && applicants.map(applicant => {
                                    return (
                                        <Col key={applicant._id}>
                                            <ProfileCard service={service} terminated={terminated} pwd={pwd} lang={lang} applicant={applicant} id={_id} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Collapse>
                    </Row>
                </Row>
            </Col>
        </Card> 
    )
}

export function BranchesCard({ lang, data, edit, remove }) {
    const { name } = data

    return (
        <div className='org-branch-container'>
            <div className='org-branch-content'>
                <div className='org-branch-title'>
                    {name}
                </div>
                <div className='org-branch-controls'>
                    <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => edit(data)} fullWidth size='sm' text={lang.edit} />
                    </div>
                    <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => remove(data._id)} fullWidth size='sm' type="outline-secondary" text={lang.delete} />

                    </div>
                </div>
            </div>
        </div>
    )
}
export function CompaniesCard({ lang, data, remove, show, link }) {
    const { name, status } = data

    return (
        <div className='org-branch-container'>
            <div className='org-branch-content'>
                <div onClick={() => show(data)} className='org-branch-title'>
                    {name}
                </div>
                <div className='org-branch-controls'>
                    <div style={{ paddingRight: 15, width: '100%' }}>
                        <div className='org-company-status'>
                            {status === 'pending' && lang.companyPending}
                            {status === 'canceled' && lang.companyCanceld}
                            {status === 'rejected' && lang.companyRejected}
                            {status === 'accepted' && lang.companyAccepted}
                        </div>
                    </div>
                    {status === 'pending' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => remove(data)} size='sm' type="outline-secondary" text={lang.cancelCompany} />
                    </div>}
                    {status === 'accepted' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => remove(data)} size='sm' type="outline-secondary" text={lang.removeCompany} />
                    </div>}
                    {(status === 'rejected' || status === 'canceled') && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => link(data._id)} size='sm' type="secondary" text={lang.companyRequest} />
                    </div>}
                </div>
            </div>
        </div>
    )
}
export function OrganizationsCard({ lang, data, remove, show, link }) {
    const { organizationId, status } = data
    return (
        <div className='org-branch-container'>
            <div className='org-branch-content'>
                <div onClick={() => show(data)} className='org-branch-title'>
                    {organizationId && organizationId.name}

                </div>
                <div className='org-company-status'>
                    {status === 'pending' && lang.companyPending}
                    {status === 'canceled' && lang.companyCanceld}
                    {status === 'rejected' && lang.companyRejected}
                    {status === 'accepted' && lang.companyAccepted}
                </div>
                <div >
                    {status === 'pending' &&
                        <div>
                            <div style={{ paddingRight: 15, width: '100%' }}>
                                <Button onClick={() => remove(data)} size='sm' type="outline-secondary" text={lang.cancelCompany} />
                            </div>

                            <div style={{ paddingRight: 15, width: '100%', paddingTop: 10 }}>
                                <Button onClick={() => link(data.organizationId._id)} size='sm' type="outline-primary" text={lang.link} />
                            </div>
                        </div>
                    }
                    {status === 'accepted' && <div style={{ paddingRight: 15, width: '100%' }}>
                        <Button onClick={() => remove(data)} size='sm' type="outline-secondary" text={lang.removeCompany} />
                    </div>}
                </div>
            </div>
        </div>
    )
}


function ProfileCard({ lang, applicant, id, pwd, terminated, service, company }) {
    const { pwdId } = applicant
    const { name, avatar } = pwdId
    const [accepted, setAccept] = useState(pwdStatus.find(c => c.value === applicant.accepted))
    const [suitable, setSuitable] = useState(pwdSuitables.find(c => c.value === applicant.suitable))
    const [hired, setHire] = useState(pwdHire.find(c => c.value === applicant.hired))
    const setSellect = (setter, value) => (event) => {
        setter(event)
        value === 'suitable' && applicantSuitableApi({ id, pwdId: pwdId._id, suitable: event.value, service: service, company })
        value === 'accepted' && applicantAcceptApi({ id, pwdId: pwdId._id, accepted: event.value, service: service, company })
        value === 'hired' && applicantHireApi({ id, pwdId: pwdId._id, hired: event.value, service: service, company })
    }
    useEffect(() => {
        applicant && setAccept(pwdStatus.find(c => c.value === applicant.accepted))
        applicant && setSuitable(pwdSuitables.find(c => c.value === applicant.suitable))
        applicant && setHire(pwdHire.find(c => c.value === applicant.hired))
    }, [applicant])
    const userImage = avatar ? lang.baseUrl + '/pwd/media/imgs/' + avatar : user
    return (
        <Card className="h-100 pt-5 justify-content-center mr-4 ml-0">
            <Row className="w-100">
                <Col lg="6">
                    <Card.Img alt={'صورة شخصية'} className='org-profile-img' src={userImage} />
                </Col>
                <Col lg="6" >
                    <Row className="pt-2 px-4">
                        <Card.Subtitle  >
                            <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i>  {name}
                        </Card.Subtitle>
                    </Row>
                    <Row className="pt-2 px-4">
                        <Card.Subtitle className='pt-3'>
                            <Button onClick={() => pwd({ applicant, id, company })} type='dark' text={lang.profile} size='sm' />
                        </Card.Subtitle>
                    </Row>
                </Col>
            </Row>
            <Row className='w-100 pt-4'>
                <Col lg="6" className='pb-4 pr-4'>
                    {service ? lang.pwdServiceStatus : lang.pwdJobStatus}
                </Col>
                <Col>
                    <CSelect disabled={terminated} onChange={setSellect(setSuitable, 'suitable')} value={suitable} sm fullWidth options={pwdSuitables} placeholder={lang.choose} />
                </Col>
                <Col>
                    <CSelect disabled={terminated} name='d' value={accepted} onChange={setSellect(setAccept, 'accepted')} sm fullWidth options={pwdStatus} placeholder={lang.choose} />
                </Col>
                <Col>
                    {service
                            ? ''
                            : <CSelect disabled={terminated} name='k' value={hired} onChange={setSellect(setHire, 'hired')} sm fullWidth options={pwdHire} placeholder={lang.choose} />
                        }
                </Col>
            </Row>
        </Card>



        // <div className='org-profile-container'>
        //     <div className='org-profile-content'>
        //         <img loading="lazy" alt={'صورة شخصية'} className='org-profile-img' src={userImage} />

        //         <div className='org-profile-left-container'>

        //             {/* <div className='org-profile-subtitle'>
        //                 <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i> {job}
        //             </div> */}
        //             <div className='org-profile-title'>
        //                 <i style={{ paddingLeft: 6 }}><FontAwesomeIcon icon={faDotCircle} color='#ea6969' /></i>  {name}
        //             </div>
        //             <div >
        //                 <Button onClick={() => pwd({ applicant, id, company })} type='dark' text={lang.profile} size='sm' />
        //             </div>
        //         </div>
        //         <div style={{ flex: 1 }}>
        //             <TextRegSub>
        //                 {service ? lang.pwdServiceStatus : lang.pwdJobStatus}
        //             </TextRegSub>
        //             <div className='org-profile-btn'>

        //                 <div style={{ paddingRight: '10px', paddingLeft: '10px', width: '100%' }} >
        //                     <CSelect disabled={terminated} onChange={setSellect(setSuitable, 'suitable')} value={suitable} sm fullWidth options={pwdSuitables} placeholder={lang.choose} />
        //                 </div>
        //                 <div style={{ paddingRight: '10px', paddingLeft: '10px', width: '100%' }} >
        //                     <CSelect disabled={terminated} name='d' value={accepted} onChange={setSellect(setAccept, 'accepted')} sm fullWidth options={pwdStatus} placeholder={lang.choose} />
        //                 </div><br /><br /><br />
        //                 {service
        //                     ? ''
        //                     : <div style={{ paddingRight: '5px', paddingLeft: '5px', width: '100%' }} >
        //                         <CSelect disabled={terminated} name='k' value={hired} onChange={setSellect(setHire, 'hired')} sm fullWidth options={pwdHire} placeholder={lang.choose} />
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export function EmploymentCard({ lang, data, terminated, edit, terminate, remove, show, pwd, company, changeStatus }) {
    var { img, jobTitle, workType, applicants, _id , ref} = data
    const [apOpen, setOpen] = useState(false)
    const ImgSrc = ref === 'organization' && img && img.length > 0 ? `${lang.baseUrl}/org/media/imgs/${img && img}` : ref === 'company' && img && img.length > 0 ?`${lang.baseUrl}/co/media/imgs/${img && img}`: Job
   
    return (
        <Card className="h-100 pt-5 justify-content-center">
            <div className='org-card-label mx-0' >
                {workType}
            </div>
            {terminated ?
                <>
                    <Col lg="12">
                        <Row className="w-100">
                            <Col lg="3">
                                <Card.Img alt={'صورة خلفية للوظيفة'} className='org-card-img' src={ImgSrc} />
                            </Col>
                            <Col lg="4" >
                                <Col>
                                    <Row>
                                        <Card.Subtitle className='pt-3' >
                                            {jobTitle}
                                        </Card.Subtitle>
                                    </Row>
                                    <Row>
                                        <Card.Subtitle className='pt-3'>
                                            {lang.jobN}<span style={{ color: '#5fc0e9' }}>{applicants.length}</span> {lang.person}
                                        </Card.Subtitle>
                                    </Row>
                                </Col>
                            </Col>

                            <Col lg="5" className='pt-5'>
                                <Row>
                                    <Col xs='5'>
                                        <Button onClick={() => show(data)} fullWidth size='sm' type="dark" text={lang.show} />
                                    </Col>

                                    <Col xs='7'>
                                        <Button onClick={() => changeStatus(data)} fullWidth size='sm' type={terminated ? "success" : "secondary"} text={terminated ? lang.JobactBtn : lang.JobtsBtn} /> {/*///neww by shrouk */}
                                    </Col>
                                </Row>
                            </Col>
                            <Row className='org-card-footer w-100 pt-4 '>
                                <div onClick={() => setOpen(!apOpen)} className={apOpen ? 'org-card-f-title-active' : 'org-card-f-title'}>
                                    {apOpen ? lang.hiddeApplicants : lang.showApplicants}
                                </div>
                                <Collapse in={apOpen} className="w-100" >
                                    <Row className='w-100'>
                                        {applicants.length > 0 && applicants.map(applicant => {
                                            return (
                                                <Col xs='12' key={applicant._id}>
                                                    <ProfileCard company={company} terminated={terminated} pwd={pwd} id={_id} applicant={applicant} lang={lang} />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Collapse>
                            </Row>
                        </Row>
                    </Col>

                </>
                :
                <>
                    <Col lg="12">

                        <Row className="w-100">
                            <Col lg="3">
                                <Card.Img alt={'صورة خلفية للوظيفة'} className='org-card-img' src={ImgSrc} />
                            </Col>
                            <Col lg="4" >
                                <Col>
                                    <Row className="pt-2 px-4">
                                        <Card.Subtitle  >
                                            {jobTitle}
                                        </Card.Subtitle>
                                    </Row>
                                    <Row className="pt-2 px-4">
                                        <Card.Subtitle className='pt-3'>
                                            {lang.jobN}<span style={{ color: '#5fc0e9' }}>{applicants.length}</span> {lang.person}
                                        </Card.Subtitle>
                                    </Row>
                                </Col>
                            </Col>

                            <Col lg="5" className='pt-5 '>
                                <Row className='px-3'>
                                    <Col xs='4.5'>
                                    <Button onClick={() => terminate(data)} fullWidth size='sm' type="secondary" text={lang.termateJob} />
                                    </Col>

                                    <Col xs='4'>
                                    <Button onClick={() => edit(data)} fullWidth size='sm' text={lang.edit} />
                                    </Col>

                                    <Col xs="3.5">
                                    <Button onClick={() => remove(data)} fullWidth size='sm' type="outline-primary" text={lang.delete} />
                                    </Col>
                                </Row>
                            </Col>
                            <Row className='org-card-footer w-100 pt-4 '>
                                <div onClick={() => setOpen(!apOpen)} className={apOpen ? 'org-card-f-title-active' : 'org-card-f-title'}>
                                    {apOpen ? lang.hiddeApplicants : lang.showApplicants}
                                </div>
                                <Collapse in={apOpen} className="w-100" >
                                    <Row className='w-100'>
                                        {applicants.length > 0 && applicants.map(applicant => {
                                            return (
                                                <Col xs='12' key={applicant._id}>
                                                    <ProfileCard company={company} terminated={terminated} pwd={pwd} id={_id} applicant={applicant} lang={lang} />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Collapse>
                            </Row>
                        </Row>
                    </Col>

                </>}
        </Card> 
    )
}


export function ContentCard({ lang, data, edit, remove, show }) {
    const { title, type, createdAt } = data
    const Type = () => {
        switch (type) {
            case 'video': return { icon: faFilm, title: lang.video }
            case 'article': return { icon: faNewspaper, title: lang.article }
            default: return { icon: faFilm, title: lang.video }
        }
    }
    return (
        <div className='org-card-container'>
            <div className='content-card-label' >
                <i className='content-card-icon'>
                    <FontAwesomeIcon icon={Type().icon} />
                </i>
                {Type().title}
            </div>

            <div className='content-card-content'>
                <div className='content-card-date'>
                    {lang.publishDate} {moment(createdAt).format('YYYY/MM/DD')} {lang.time} {moment(createdAt).format('LT')}
                </div>

                <Row className='pt-4'>
                    <Col xs='7' style={{ paddingRight: '25px' }}>
                        <TextCardTitle align="right" >
                            {title}
                        </TextCardTitle>
                    </Col>
                    <Col >
                        <Row>
                            <Col  >
                                <Button onClick={() => show(data)} fullWidth size='sm' type="dark" text={lang.show} />
                            </Col>
                            <Col >
                                <Button onClick={() => edit(data)} fullWidth size='sm' text={lang.edit} />
                            </Col>
                            <Col >
                                <Button onClick={() => remove(data)} fullWidth size='sm' type="outline-secondary" text={lang.delete} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export function UpdatesCard({ lang, data, temp, edit, remove }) {
    const { title, seenIds } = data
    const [show, setShow] = useState(false)
    const getState = (value) => {
        return data.to.country && data.to.country.state.find(e => e._id === value).name
    }
    return (
        <div className='org-card-container'>
            <div className='content-card-content' onClick={() => setShow(!show)}>
                <Row >
                    <Col xs='10'>
                        <TextCardTitle align="right" >
                            {title}
                        </TextCardTitle>
                    </Col>
                    {temp && <Col >
                        <Button onClick={() => edit(data)} fullWidth size='sm' text={lang.edit} />
                    </Col>}
                </Row>
                <Row className='pt-3' >
                    <Col xs='10' >
                        <div className='update-card-subtitle' >
                            {lang.updateSeen} <span style={{ color: '#5fc0e9' }}>{seenIds.length}</span> {lang.user} {temp && lang.userNote}
                        </div>
                    </Col>
                    {temp && <Col >
                        <Button onClick={() => remove(data)} fullWidth size='sm' type="outline-secondary" text={lang.delete} />
                    </Col>}
                </Row>
                <Collapse in={show}>
                    <div>
                        <div className='divider' />

                        <div style={{ textAlign: 'center' }} className='update-card-subtitle' >
                            {data.body}
                        </div>
                        <div className='divider' />

                        <div className='update-card-subtitle' >
                            {lang.country + ':'} {data.to.country && data.to.country.name}
                        </div>
                        <div className='update-card-subtitle' >
                            {lang.province + ':'} {data.to.states && data.to.states.map(e => getState(e)).join(', ')}
                        </div>
                        <div className='update-card-subtitle' >
                            {lang.disType + ':'} {data.to.disabilityType && data.to.disabilityType.map(e => e.name).join(', ')}
                        </div>

                    </div>
                </Collapse>
            </div>
        </div>
    )
}

// component shows the notifications in pushNotifications model in pwd
export function NotificationCard({ data, SeenApi, update, }) {
    var { title, createdAt, body, source, seen, _id } = data
    const [show, setShow] = useState(false)
    const active = seen ? ' active' : ' '
    const Show = () => {
        if (!seen) {
            SeenApi(_id)
            update()
        }
        setShow(!show)
    }
    return (
        <div className='notifications-card-container' onClick={Show}>
            <div className={'notifications-card-content' + active}>
                <div className='notifications-card-header'  >
                    <div className='notifications-card-title'>
                        {title}
                    </div>
                    <div className='notifications-card-time' >
                        {moment(createdAt).format('yyyy-MM-DD hh:mm A')}
                    </div>
                </div>
                <Collapse in={show} >
                    <div className='notifications-card-footer'>
                        <div className='notifications-card-footer-title'>
                            {source}
                        </div>
                        <div className='notifications-card-footer-body'>
                            {body}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

// new // BY DALIA // component shows the personal notifications in notifications model in pwd
export function PerNotificationCard({ data, SeenApi, update }) {
    var { title, createdAt, seen, _id } = data
    const [show, setShow] = useState(false)
    const active = seen ? ' active' : ' '
    const Show = () => {
        if (!seen) {
            SeenApi(_id)
            update()
        }
        setShow(!show)
    }
    return (
        <div className='notifications-card-container' onClick={Show}>
            <div className={'notifications-card-content' + active}>
                <div className='notifications-card-header'  >
                    <div className='notifications-card-title'>
                        {title}
                    </div>
                    <div className='notifications-card-time' >
                        {moment(createdAt).format('yyyy-MM-DD hh:mm A')}
                    </div>
                </div>
                <Collapse in={show} >
                    <div className='notifications-card-footer'>
                        <div className='notifications-card-footer-title'>

                        </div>
                        <div className='notifications-card-footer-body'>

                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export function FavoriteCard({ lang, data }) {
    const { title } = data
    return (
        <div className='notifications-card-container'>
            <div className='notifications-card-content'>
                <Row >
                    <Col xs='10'>
                        <div className='notifications-card-title'>
                            {title}
                        </div>
                    </Col>
                    <Col>
                        <div className='favorite-card-link'>
                            {lang.show}
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}
export function EducationsCard({ data, lang }) {
    const [show, setShow] = useState(false)
    const { educationLevel, educationCertificate, educationCertType, educationDate, educationPlace } = data
    const level = educationLevel && educationLevel.type?.find(e => e._id === educationCertType)
    const cert = level && level.cert.find(e => e._id === educationCertificate).name
    return (
        <div className='training-card-container'>
            <div className='training-card-header' onClick={() => setShow(!show)}>
                <div className="training-card-title">
                    {cert}
                </div>
                <div className="training-card-icon" >
                    <FontAwesomeIcon icon={show ? faMinus : faPlus} />
                </div>
            </div>
            <Collapse in={show} >
                <div className="training-card-body">
                    <Row>
                        <Col  >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdEdu} {':'} </span>{educationLevel && educationLevel.name}
                            </TextRegSub>
                        </Col>
                        <Col  >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingCertType} {':'} </span>{level && level.name}
                            </TextRegSub>
                        </Col>

                    </Row>
                    <Row>
                        <Col >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdEduPlace} {':'} </span>{educationPlace && educationPlace}
                            </TextRegSub>
                        </Col>
                        <Col  >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdGradDate} {':'} </span>{moment(educationDate && educationDate).format('YYYY/MM/DD')}
                            </TextRegSub>
                        </Col>

                    </Row>
                </div>
            </Collapse>
        </div>
    )
}
export function TrainigsCard({ data, lang }) {
    const [show, setShow] = useState(false)
    const { trainingName, trainingCode, trainingSector, speciality, certificateType, certificateDate } = data
    console.log(trainingSector)
    return (
        <div className='training-card-container'>
            <div className='training-card-header' onClick={() => setShow(!show)}>
                <div className="training-card-title">
                    {trainingName && trainingName}
                </div>
                <div className="training-card-icon" >
                    <FontAwesomeIcon icon={show ? faMinus : faPlus} />
                </div>
            </div>
            <Collapse in={show} >
                <div className="training-card-body">
                    <Row>
                        <Col xs='4' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingSector} {':'} </span>{trainingSector && trainingSector.name}
                            </TextRegSub>
                        </Col>
                        <Col xs='4' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingSpeciality} {':'} </span>{trainingSector && trainingSector.type?.find(e => e._id === speciality).name}
                            </TextRegSub>
                        </Col>
                        <Col xs='4' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingCode} {':'} </span>{trainingCode && trainingCode}
                            </TextRegSub>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingCertType} {':'} </span>{certificateType && certificateType}
                            </TextRegSub>
                        </Col>
                        <Col xs='4' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdTrainingDate} {':'} </span>{moment(certificateDate && certificateDate).format('YYYY/MM/DD')}
                            </TextRegSub>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    )
}
export function ExperincesCard({ data, lang }) {
    const [show, setShow] = useState(false)
    const { jobTitle, companyName, startDate, endDate, jobDescription, paidSalary, skillsTags } = data

    return (
        <div className='training-card-container'>
            <div className='training-card-header' onClick={() => setShow(!show)}>
                <div className="training-card-title">
                    {jobTitle && jobTitle.name} ({companyName && companyName})
                </div>
                <div className="training-card-icon" >
                    <FontAwesomeIcon icon={show ? faMinus : faPlus} />
                </div>
            </div>
            <Collapse in={show} >
                <div className="training-card-body">
                    <TextRegSub margin right >
                        {jobDescription && jobDescription}
                    </TextRegSub>
                    <Row>

                        <Col xs='6' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdExpStartDate}{':'} </span>{moment(startDate && startDate).format('YYYY/MM/DD')}
                            </TextRegSub>
                        </Col>
                        <Col xs='6' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdExpEndDate}{':'} </span>{moment(endDate && endDate).format('YYYY/MM/DD')}
                            </TextRegSub>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs='6' >
                            <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdSkills}{':'} </span>{skillsTags.length > 0 && skillsTags.map(e => e.skillId.name).join(', ')}
                            </TextRegSub>
                        </Col>
                        <Col xs='6' >
                            {paidSalary && <TextRegSub margin right >
                                <span style={{ color: 'black' }}>{lang.pwdExpSalary}{':'} </span>{paidSalary} {lang.pound}
                            </TextRegSub>
                            }
                        </Col>
                    </Row>
                </div>
            </Collapse>
        </div>
    )
}

export function DisabilitiesCard({ data, lang }) {
    const [show, setShow] = useState(false)
    const { artificialLimpPart, disabilityChair, disabilityStick, glasses, hearingaid, otherAssistiveTools, paralizedTool, disabilites } = data

    return(
        <div className='training-card-container'>
             <Row>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdArtificialLimp}{' :'} </span>{artificialLimpPart && artificialLimpPart.length > 0 ? artificialLimpPart : lang.notFound }
                    </TextRegSub>
                </Col>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdDisabilityChair}{' :'} </span>{disabilityChair && disabilityChair ? lang.found : lang.notFound}
                    </TextRegSub>
                </Col>
            </Row>
            <Row>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdDisabilityStick}{' :'} </span>{disabilityStick && disabilityStick === true ? lang.found : lang.notFound }
                    </TextRegSub>
                </Col>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdGlasses}{' :'} </span>{glasses && glasses === true ? lang.found : lang.notFound}
                    </TextRegSub>
                </Col>
            </Row>
            <Row>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdHearingaid}{' :'} </span>{hearingaid && hearingaid === true ? lang.found : lang.notFound }
                    </TextRegSub>
                </Col>
                <Col xs='6' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdParalizedTool}{' :'} </span>{paralizedTool && paralizedTool === true ? lang.found : lang.notFound}
                    </TextRegSub>
                </Col>
            </Row>
            <Row>
                <Col xs='12' >
                    <TextRegSub margin right >
                        <span style={{ color: 'black' }}>{lang.pwdOtherAssistiveTools}{' :'} </span>{otherAssistiveTools && otherAssistiveTools.length > 0 ? otherAssistiveTools : lang.notFound }
                    </TextRegSub>
                </Col>
            </Row>
            <TextToolbar margin black>
                {lang.disabilities}
            </TextToolbar>
            {
                disabilites && disabilites.length > 0 &&
                    disabilites.map(d => 
                        <>
                            <div className='training-card-header' onClick={() => setShow(!show)}>
                                <div className="training-card-title">
                                    <span>{d.disabilityType.name}</span>  
                                </div>
                                <div className="training-card-icon" >
                                    <FontAwesomeIcon icon={show ? faMinus : faPlus} />
                                </div>
                            </div>
                            <Collapse in={show} >
                                <div className="training-card-body">
                                    <Row>
                                        <Col xs='12' >
                                        { d.disabilityType.type.map(type => (
                                            <TextRegSub margin right >
                                                <span style={{ color: 'black' }}>{lang.pwdDisabilityType}{' :'} </span>{ type.name }
                                            </TextRegSub>
                                        ))}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs='6' >
                                            <TextRegSub margin right >
                                                <span style={{ color: 'black' }}>{lang.pwdDisabilityReason}{' :'} </span>{d.disabilityReason && d.disabilityReason}
                                            </TextRegSub>
                                        </Col>
                                        <Col xs='6' >
                                            {<TextRegSub margin right >
                                                <span style={{ color: 'black' }}>{lang.pwdReasonDescription}{' :'} </span>{d.disabilityReasonDescription && d.disabilityReasonDescription}
                                            </TextRegSub>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </>
                    )
            }
            
        </div>
    )
}    
import React from 'react'
import { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import './css/inputs.css'
import Select from 'react-select'
import { Form, Overlay, Tooltip, InputGroup, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from 'primereact/multiselect';
import '../Components/css/multiSelect.css'
import 'primereact/resources/themes/lara-light-blue/theme.css'
import "primereact/resources/primereact.min.css";//core css
import "primeicons/primeicons.css"; 
import PrimeReact from 'primereact/api'  
PrimeReact.zIndex = {
    modal: -100,    // dialog, sidebar
    overlay: 2000,  // dropdown, overlaypanel
    menu: 2000,     // overlay menus
    tooltip: 2000,   // tooltip
    toast: 2000     // toast
}
PrimeReact.autoZIndex = true;

const styles = {
    valueContainer: (provided, state) => {
        return {
            ...provided,
            marginRight: state.selectProps.icon ? '40px' : '5px'
        }
    },

    menu: (provided, state) => ({
        ...provided,
        zIndex: 2,
        textAlign: 'right'
        // backgroundColor:'#'
    }),


    control: (provided, state) => {
        return {
            ...provided,
            borderRadius: 16,
            minHeight: state.selectProps.sm ? '30px' : '48px',
            border: state.selectProps.menu ? 'none' : 'solid 1px ' + (state.selectProps.value ? state.selectProps.value.color : '#0e132726'),
            color: '#797979',
            fontSize: "14px",
            '&:hover': { borderColor: '#23c0e9' },
            '&:focus': { borderColor: '#23c0e9' },
            boxShadow: state.selectProps.menu ? '0 3px 6px 0 #00000029' : 'none',
            backgroundColor: state.selectProps.isDisabled ? '#79797937' : '#ffff'


        }
    },
    indicatorSeparator: (provided, state) => {
        return {
            ...provided,
            display: state.selectProps.isClearable ? (state.selectProps.value ? '' : 'none') : 'none',
        };
    },
    option: (provided, state) => ({
        ...provided,
        '&:hover': { backgroundColor: '#23c0e940' },
        color: state.isDisabled ? "#0e132780" : 'black',
        backgroundColor: state.isSelected ? '#23c0e9' : '',
        fontSize: state.selectProps.sm ? '12px' : '14px',


    }),
    singleValue: (provided, state) => {
        return {
            ...provided,
            color: state.selectProps.menu ? '#5fc0e9' : (state.selectProps.value.color ? state.selectProps.value.color : ''),
            fontWeight: state.selectProps.menu ? 'bold' : 'normal',
            fontFamily: state.selectProps.menu ? 'Cairo' : '',
            //was ArabicBold
        }
    },
    multiValue: (provided, state) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#23c0e940',
        paddingRight: 5,

    }),
}

// react-hook inputs with validation
export const NPDInput = React.forwardRef(({ accept, type, controlId, labelStyle, labelName, disabled, onKeyDown, onFocus, name, onChange, value, className, placeholder, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}> 
            {
                labelName && <Form.Label className={ labelStyle}>{labelName}</Form.Label>
            }
            <Form.Control spellCheck={true} autoCorrect="true" accept={accept} name={name} disabled={disabled} onChange={onChange} ref={ref} value={value} type={type}
                        className={className} placeholder={placeholder} {...rest} onKeyDown={onKeyDown} onFocus={onFocus}/>                                          
            <Form.Control.Feedback type="invalid"> 
                {errorMessage}                                                    
            </Form.Control.Feedback>
        </Form.Group>
    )
})

export const NPDWithIconInput = React.forwardRef(({ icon, type, controlId, disabled, onKeyDown, name, onChange, defaultValue, value, className, placeholder, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group as={Col} controlId={controlId}> 
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <FontAwesomeIcon color='#ffffff' style={{color: '#23c0e9', fontSize: "1.5em"}} icon={icon} />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control spellCheck={true} autoCorrect="true" name={name} disabled={disabled} onChange={onChange} ref={ref} value={value} defaultValue={defaultValue} type={type}
                    className={className} placeholder={placeholder} {...rest} onKeyDown={onKeyDown} />
            </InputGroup>
            { check &&
                <Form.Control.Feedback type="invalid" style={{display:'block'}}> 
                    {errorMessage}                                                    
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
})

export const NPDInputArea = React.forwardRef(({ type, controlId, labelStyle, labelName, name, onChange, onKeyDown, value, className, placeholder, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}> 
            <Form.Label className={ labelStyle}>{labelName}</Form.Label>
            <Form.Control spellCheck={true} autoCorrect="true" autoComplete="true" name={name} onChange={onChange} ref={ref} value={value} as={type}
                        className={className} rows={5} placeholder={placeholder} onKeyDown={onKeyDown} {...rest}/>                                          
            <Form.Control.Feedback type="invalid"> 
                {errorMessage}                                                    
            </Form.Control.Feedback>
        </Form.Group>
    )
})

export const NPDCheckBox = React.forwardRef(({ id, forId, controlId, labelStyle, labelName, name, onChange, checked, className, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Check controlId={controlId}>
            <Form.Check.Input tabIndex={0} id={id} role="checkbox" name={name} onChange={onChange} ref={ref} checked={checked} className={className} {...rest}/>
            <Form.Check.Label for={forId} className={ labelStyle} >{labelName}</Form.Check.Label>
            { check && 
                <Form.Control.Feedback type="invalid" style={{display:'block'}}> 
                    {errorMessage}  
                </Form.Control.Feedback>
            }
        </Form.Check>
    )
})

export const NPDSelect = React.forwardRef(({ type, disabled, controlId, labelStyle, labelName, name, onChange, options, value, defaultValue, className, placeholder, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}> 
            <Form.Label className={labelStyle}>{labelName}</Form.Label>
            <Form.Control disabled={disabled} name={name} as={type} onChange={onChange} ref={ref} value={value}
                className={className} defaultValue={defaultValue} {...rest}>
                    <option value="">{placeholder}</option>
                    {options && options.map(val => 
                        <option key={val._id} value={val._id}>{val.name}</option>
                    )}
            </Form.Control>      
            { check && 
                <Form.Control.Feedback type="invalid"> 
                    {errorMessage}  
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
})

export const NPDWithIconSelect = React.forwardRef(({ icon, type, disabled, controlId, name, onChange, options, value, defaultValue, className, placeholder, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}>
            <Col>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <FontAwesomeIcon color='#ffffff' style={{color: '#23c0e9', fontSize: "1.5em"}} icon={icon} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control disabled={disabled} name={name} as={type} onChange={onChange} ref={ref} value={value}
                        className={className} defaultValue={defaultValue} {...rest}>
                        <option value="">{placeholder}</option>
                        {options && options.map(val => 
                            <option key={val._id} value={val._id}>{val.name}</option>
                        )}
                     </Form.Control> 
                </InputGroup>
            </Col> 
            { check && 
                <Form.Control.Feedback type="invalid" style={{display:'block'}}> 
                    {errorMessage}  
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
})

export const NPDWithIconStaticSelect = React.forwardRef(({ icon, type, disabled, controlId, name, onChange, options, value, defaultValue, className, placeholder, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}>
            <Col>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <FontAwesomeIcon color='#ffffff' style={{color: '#23c0e9', fontSize: "1.5em"}} icon={icon} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control disabled={disabled} name={name} as={type} onChange={onChange} ref={ref} value={value}
                        className={className} defaultValue={defaultValue} {...rest}>
                        <option value="">{placeholder}</option>
                        {options && options.map(val => 
                            <option key={val.value} value={val.value}>{val.name}</option>
                        )}
                     </Form.Control> 
                </InputGroup>
            </Col> 
            { check && 
                <Form.Control.Feedback type="invalid" style={{display:'block'}}> 
                    {errorMessage}  
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
})

export const NPDBooleanSelect = React.forwardRef(({ type, disabled, controlId, labelStyle, labelName, name, onChange, options, value, defaultValue, className, placeholder, check, errorMessage, ...rest }, ref) => {
    return (
        <Form.Group controlId={controlId}> 
            <Form.Label className={labelStyle}>{labelName}</Form.Label>
            <Form.Control disabled={disabled} name={name} as={type} onChange={onChange} ref={ref} value={value}
                className={className} defaultValue={defaultValue} {...rest}>
                    <option value="">{placeholder}</option>
                    {options && options.map(val => 
                        <option key={val.value} value={val.value}>{val.name}</option>
                    )}
            </Form.Control>      
            { check && 
                <Form.Control.Feedback type="invalid"> 
                    {errorMessage}  
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
})

export const NPDMultiSelect = React.forwardRef(({controlId, labelStyle, labelName, onChange, options, value, disabled, optionLabel, optionValue, placeholder, errorMessage, check, ...rest}, ref) => {
    return (
        <Form.Group controlId={controlId}> 
            <Form.Label className={labelStyle}>{labelName}</Form.Label> 
            <MultiSelect 
                tabIndex={0} role="listbox"
                value={value} options={options} disabled={disabled}
                onChange={onChange} 
                optionLabel={optionLabel} optionValue={optionValue} 
                placeholder={placeholder} display="chip"
                style={{'width':'100%'}} filter />
            {
                check ?
                    <Form.Control.Feedback type="invalid" style={{display:'block'}}>   
                        {errorMessage}
                    </Form.Control.Feedback> 
                : ''
            }    
        </Form.Group>
    )
})

// export const NPDDateField = React.forwardRef(({controlId, labelStyle, labelName, name, defaultValue, onChange, value, className, placeholder, check, errorMessage, ...rest}, ref) => {
//     return (
//         <Form.Group controlId={controlId}> 
//             <Form.Label className={labelStyle}>{labelName}</Form.Label>
//             <Form.Control type="date" name={name} defaultValue={defaultValue} onChange={onChange} selected={value} className={className} 
//                 placeholder={placeholder} ref={ref} {...rest} />
//             { check && 
//                 <Form.Control.Feedback type="invalid" style={{display:'block'}}> 
//                     {errorMessage}                                                    
//                 </Form.Control.Feedback>
//             }
//         </Form.Group>    
//     )
// })

export function CSelect(props) {
    const { required, icon, isClearable, isSearchable, options, title, margin, marginHome, placeholder, multi, fullWidth, sm, btn, defaultValue, onChange, value, disabled, isOptionDisabled, menu, } = props
    const width = fullWidth ? '100%' : ''
    const searchable = typeof (isSearchable) === 'undefined' ? true : isSearchable
    const Title = title + (required ? '*' : '')
    const titleCss = 'input-title' + (required ? (value ? '' : '-required') : '')
    const m = margin ? '10px' : (marginHome ? '20px' : '0')
    return (
        <div style={{ marginTop: m, marginBottom: m, width: width, }} className="input-container">
            <div>
                {title && <div aria-label={title} className={titleCss}>
                    {Title}
                </div>}
                <Select
                    aria-label={placeholder} isSearchable={searchable} isClearable={isClearable} menu={menu}
                    isOptionDisabled={isOptionDisabled} isDisabled={disabled} value={multi ? value : value && { value: value._id, label: value.name }}
                    onChange={onChange} sm={sm} defaultValue={defaultValue} btn={btn} isMulti={multi} icon={icon} placeholder={placeholder}
                    styles={styles} options={options && options.map(value => { return { value: value._id, label: value.name, ...value } })} />
                <i className="input-icon" >
                    {icon && <FontAwesomeIcon size='sm' icon={icon} />}
                </i>
            </div>
        </div>

    )
}
export function Input(props) {
    const { placeholder, icon, title, margin, type, onChange, value, error, name, errorMessage, disabled, OnClickEnter, required, onFocus } = props
    const err = error ? '-error' : ''
    const cs = icon ? 'input-with-icon' + err : 'input' + err
    const m = margin ? '10px' : '0'
    const place = type === 'password' ? (placeholder ? placeholder : '* * * * * * * * * *') : placeholder
    const target = useRef(null);
    const Title = title + (required ? '*' : '')
    const titleCss = 'input-title' + (required ? (value ? '' : '-required') : '')
    return (
        <div style={{ marginTop: m, marginBottom: m }} className="input-container">
            <div aria-label={title} >
                {title && <div className={titleCss}>
                    {Title}
                </div>}
                <input onFocus={onFocus} maxLength='350' aria-label={placeholder} onKeyDown={(e) => e.key === 'Enter' ? OnClickEnter && OnClickEnter() : ''} disabled={disabled} ref={target} name={name} value={value == null ? '' : value} onChange={onChange} type={type} className={cs} placeholder={place} />
                <i className="input-icon" >
                    {icon && <FontAwesomeIcon size='sm' icon={icon} />}
                </i>
                <Overlay target={target.current} show={error} placement="top">
                    <Tooltip aria-label={errorMessage}  >
                        {errorMessage}
                    </Tooltip>
                </Overlay>
            </div>
        </div>
    )
}
export function InputArea(props) {
    const { placeholder, title, margin, value, onChange, rows, required } = props
    const m = margin ? '10px' : '0'
    const Title = title + (required ? '*' : '')
    const titleCss = 'input-title' + (required ? (value ? '' : '-required') : '')
    return (
        <div style={{ marginTop: m, marginBottom: m }} className="input-container">
            <div   >
                {title && <div aria-label={title} className={titleCss}>
                    {Title}
                </div>}
                <textarea maxLength='500' aria-label={placeholder} rows={rows ? rows : "10"} value={value} onChange={onChange} className='input-textarea' placeholder={placeholder} />

            </div>
        </div>
    )
}
export function InputDate(props) {
    const { placeholder, icon, title, margin, type, onChange, value, error, name, errorMessage, disabled, required } = props
    const err = error ? '-error' : ''
    const cs = icon ? 'input-with-icon' + err : 'input' + err
    const m = margin ? '10px' : '0'
    const place = type === 'password' ? (placeholder ? placeholder : '* * * * * * * * * *') : placeholder
    const target = useRef(null);
    const Title = title + (required ? '*' : '')
    const titleCss = 'input-title' + (required ? (value ? '' : '-required') : '')
    return (
        <div style={{ marginTop: m, marginBottom: m }} className="input-container">
            <div   >
                {title && <div aria-label={title} className={titleCss}>
                    {Title}
                </div>}
                <DatePicker
                    aria-label={place}
                    placeholderText={place}
                    className={cs}
                    name={name}
                    ref={target}
                    disabled={disabled}
                    selected={value ? new Date(value) : ''}
                    onChange={(date) => onChange(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={new Date('1950/01/01')}
                    maxDate={new Date()}
                    dateFormat="yyyy/MM/dd"
                    popperModifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [5, 10],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                            },
                        },
                    ]}

                />
                <i className="input-icon" >
                    {icon && <FontAwesomeIcon size='sm' icon={icon} />}
                </i>
                <Overlay target={target.current} show={error} placement="left">
                    <Tooltip  >
                        {errorMessage}
                    </Tooltip>
                </Overlay>
            </div>
        </div>
    )
}
export function CheckBox(props) {
    const { onClick, title, margin, active } = props
    const m = margin ? '10px' : '0'
    const style = active ? 'check-box-active' : 'check-box'
    return (
        <div style={{ marginTop: m, marginBottom: m, marginRight: m }} onClick={onClick} className="check-box-container">
            <div className={style} >
                {active && <FontAwesomeIcon size='sm' icon={faCheck} />}
            </div>
            {title && <div aria-label={title} className='check-box-title'>
                {title}
            </div>}
        </div>
    )
}
export function CheckBoxR(props) {
    const { onClick, title } = props
    return (
        <div style={{ marginTop: '10px' }} className="check-box-r-container">
            <div className='check-box-recipients' onClick={onClick} >
                <FontAwesomeIcon size='sm' icon={faMinus} />
            </div>
            {title && <div aria-label={title} className='check-box-title'>
                {title}
            </div>}
        </div>
    )
}
export function Br() {
    return (
        <div style={{ padding: '30px' }}>

        </div>
    )
}

export function CatCheckBox(props) {
    const { onClick, children, active } = props
    const style = active ? 'category-check-box-active' : 'category-check-box'
    return (
        <div style={{ margin: '10px' }} onClick={onClick} className="check-box-container">

            <div className={style} style={{ width: '24px', height: '24px' }} >
                {active && <FontAwesomeIcon icon={faCheck} />}
            </div>
            {children && <div aria-label={children} className='category-check-box-title'>
                {children}
            </div>}
        </div>
    )
}
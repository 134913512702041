
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as Buttons } from 'react-bootstrap'

export function Button(props) {
    const { text, type, onClick, size, icon, fullWidth, margin, disabled, title, xl } = props
     const style = { width: xl ? '280px' : (fullWidth ? '100%' : '194px'), height: xl ? '80px' : '' }
    const m = margin ? '20px' : ''
    return (
        <div style={{ marginTop: m, marginBottom: m }} >
            {title && <div aria-label={title} className='input-title'>
                {title}
            </div>}
            <Buttons variant={type} disabled={disabled} onClick={onClick} size={size} aria-label={text} style={style}   >
                {icon && <FontAwesomeIcon icon={icon} />} {text}
            </Buttons>
        </div>
    )
}
import { useState } from 'react';
import './css/footer.css'
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faBell, faCaretLeft, faChevronUp, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { TextRegSub } from './index'
import { NPDWithIconInput } from './index';
import { EmailSubscribeApi } from '../Api';
// react-hook-form && @hookform/resolvers/yup && react-bootstrap-icons
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { subscribtionValidationSchema } from './validations';
import Logo from '../Assets/imgs/newlogo.webp'
import undp from '../Assets/imgs/undp.webp'
import solidarity from '../Assets/imgs/solidarity.webp'
import ilo from '../Assets/imgs/ilologo.webp'
import minstryMP from '../Assets/imgs/minstryMP.webp'
import './css/style.css'

export function Footer({ lang, mainInfo }) {
    const { handleSubmit, control, formState: {errors}, reset, register} = useForm({
        mode: "all",
        reValidateMode: "onSubmit",
        resolver: yupResolver(subscribtionValidationSchema)
    });
    const [email, setEmail] = useState('')

    // const linksApp = [
    //     { name: lang.footerAppliers },
    //     { name: lang.footerAppliers },
    //     { name: lang.footerAppliers },
    //     { name: lang.footerAppliers },
    //     { name: lang.footerAppliers },
    // ]
    // const linksMore = [
    //     { name: lang.footerMore },
    //     { name: lang.footerMore },
    //     { name: lang.footerMore },
    //     { name: lang.footerMore },
    //     { name: lang.footerMore },
    // ]
    // const linksPart = [
    //     { name: lang.footerPart },
    //     { name: lang.footerPart },
    //     { name: lang.footerPart },
    //     { name: lang.footerPart },
    //     { name: lang.footerPart },
    // ]
    // const Links = (data) => {
    //     return (
    //         data.map((link, index) => (
    //             <div key={index} style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', padding: 10, }}>
    //                 <FontAwesomeIcon style={{ marginLeft: 7 }} size='2x' icon={faCaretLeft} color='#ea6969' />
    //                 <TextRegSub right>
    //                     {link.name}
    //                 </TextRegSub>
    //             </div>
    //         ))
    //     )
    // }
    const Top = () => {
        var elmnt = document.getElementById("top");
        elmnt.scrollTo({ top: 0, behavior: 'smooth', })
    }
    const subscribe = () => {
        const data = { email }
        EmailSubscribeApi(data)
        setEmail('')
          
    }

    const checkKeyDown = (e) => {
        if(e.target.value !== '') {
            if (e.code === 'Enter') subscribe();
        }
    };

    return (
        <div >
            <div style={{ backgroundColor: '#0e1327e6', padding: '3%' }}>
                <Container>
                    <Row className='d-flex align-items-center '>
                        <Col xl='1' className='p-2'>
                            <FontAwesomeIcon color='#ffffff' style={{ fontSize: 80, width: '100%' }} icon={faBell} />
                        </Col>
                        <Col xl='4' className='p-2'>
                            <div className='text-footer-emeil'>
                                {lang.footerEmail}
                            </div>
                        </Col>
                        <Col xl='7' className='p-2'>
                            <Form onSubmit={handleSubmit(subscribe)} className="form"  onReset={reset} onKeyDown={(e) => checkKeyDown(e)}>                                                                    
                                <Row >
                                    <Col xl='7' className='p-2'>
                                        <NPDWithIconInput type="email" controlId="email" name="email" 
                                            control={control} {...register('email')} onChange={(e)=> setEmail(e.target.value)} value={email} 
                                            className={`input-lg form-control inputWithIcon ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder={lang.footerEmailInput}
                                            onKeyDown={(e) => e.key === 'Enter' ? subscribe : ''}
                                            check={errors && errors.email} errorMessage={errors && errors.email?.message} icon={faEnvelope}/>
                                    </Col>
                                    <Col xl='5' className='p-2'>
                                        <Button variant="secondary" type="submit" className='button' aria-label={lang.footerEmailBtn}>
                                            {lang.footerEmailBtn}
                                        </Button>                        
                                    </Col>
                                </Row>
                            </Form>
                        </Col>            
                    </Row>
                </Container>
            </div>
            <div style={{ backgroundColor: '#0e1327d9', padding: '3%' }}>
                <Container>
                    <div className='footer-logo-container'  >
                        <img style={{
                            width: '194px',
                            height: '60px',
                            backgroundColor: '#323647',
                            marginTop: '30px'
                        }} src={Logo} alt={'شعار وزارة الإتصالات و تكنولوجيا المعلومات'} loading="lazy"/>
                        <img style={{
                            width: '194px',
                            height: '60px',
                            backgroundColor: '#323647',
                            marginTop: '30px'
                        }} src={solidarity} alt={'شعار وزارة التضامن الإجتماعي'} loading="lazy"/>
                        <img style={{
                            width: '120px',
                            height: '120px',
                            backgroundColor: '#323647',
                        }} src={minstryMP} alt={'شعار وزارة القوى العاملة'} loading="lazy"/>
                          <img style={{
                            width: '180px',
                            height: '60px',
                            backgroundColor: '#323647',
                            marginTop: '30px'
                        }} src={undp} alt={'شعار برنامج الأمم المتحدة الإنمائي'} loading="lazy"/>
                          <img style={{
                           padding:'10px',
                            width: '194px',
                            height: '60px',
                            backgroundColor: '#323647',
                            marginTop: '30px'
                        }} src={ilo} alt={'شعار منظمة العمل الدولية'} loading="lazy"/>
                    </div>
                    <div className='divider' />

                    <div className='footer-container'>
                        <div className="footer-right">
                            <div className="footer-right-r">
                                <TextRegSub size='14px'  >
                                    {mainInfo && mainInfo.copyright}
                                </TextRegSub>
                            </div>

                            <div className="footer-right-l">
                                <button onClick={Top} className="control-btn" aria-label={lang.goTop}>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </button>
                            </div>
                        </div>
                        <div className="footer-left">
                            <a aria-label={lang.linkedin} alt={lang.linkedin} target="_blank" href={mainInfo && mainInfo.linkedin} title="لينكدان" rel="noopener noreferrer">
                                <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='lg' color='#f9f9f980' icon={faLinkedin} />
                            </a>
                            <a aria-label={lang.youtube} alt={lang.youtube} target="_blank" href={mainInfo && mainInfo.youtube} title="يوتيوب" rel="noopener noreferrer">
                                <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='lg' color='#f9f9f980' icon={faYoutube} />
                            </a>
                            <a aria-label={lang.twitter} alt={lang.twitter} target="_blank" href={mainInfo && mainInfo.twitter} title="تويتر" rel="noopener noreferrer">
                                <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='lg' color='#f9f9f980' icon={faTwitter} />
                            </a>
                            <a aria-label={lang.facebook} alt={lang.facebook} target="_blank" href={mainInfo && mainInfo.facebook} title="فيسبوك" rel="noopener noreferrer">
                                <FontAwesomeIcon style={{ marginRight: 10, marginLeft: 10 }} size='lg' color='#f9f9f980' icon={faFacebookF} />
                            </a>
                        </div>
                    </div>

                </Container>
            </div>

        </div>
    )
}
import './css/modal.css'
import { Col, Modal as MD, Row } from 'react-bootstrap'
import { Input, Button } from './index'
import { useState } from 'react'
import Swal from 'sweetalert2'

export function Modal({ show, onHide, title, Body, xl, sub }) {
    return (
        <MD
            show={show}
            size={xl ? 'xl' : "lg"}
            centered
            onHide={onHide}
            scrollable


        >
            <MD.Header style={{ paddingTop: '25px', paddingBottom: '25px' }} closeButton >
                <div className='modal-title'>
                    {title}
                </div>
            </MD.Header>
            <MD.Body style={{ background: sub ? '#79797930' : '' }} >
                {Body && <Body />}
            </MD.Body>
        </MD>
    )
}

export function CreatModal({ show, onHide, title, create, lang }) {
    const [value, setValue] = useState('')

    const onChange = (event) => {
        setValue(event.target.value)
    }
    const click = () => {
        create(value)
        setValue('')
    }
    return (
        <MD
            show={show}
            size="md"
            centered
            onHide={onHide}
            scrollable
        >
            <MD.Header >
                <div className='modal-title'>
                    {title}
                </div>
            </MD.Header>
            <MD.Body >
                <Row>
                    <Col>
                        <Input onChange={onChange} value={value} margin />
                        <Button onClick={click} fullWidth type='outline-primary' text={lang.add} />
                    </Col>
                </Row>

            </MD.Body>

        </MD>
    )
}

export async function Confirm({ msg, confirmText, active, remove, lang, cancelText }) {
    const result = await Swal.fire({
        customClass: {
            confirmButton: active ? 'confirm-btn-active' : 'confirm-btn',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        icon: 'question',
        iconHtml: '؟',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText ? cancelText : lang.cancel,
        focusConfirm: false,
        showCancelButton: true,
        title: msg,
    })
    if (result.isConfirmed) {
        remove()
    }
}
export async function VCallModal({ msg, confirmText, confirm, cancelText, }) {
    const result = await Swal.fire({
        customClass: {
            confirmButton: 'confirm-btn-active',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        icon: 'info',
        buttonsStyling: false,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        focusConfirm: true,
        showCancelButton: true,
        title: msg,
        position: 'top-end',
        timer: 60000,
        timerProgressBar: true,


    })
    if (result.isConfirmed) {
        confirm()
    }
}

export function LoginAs({ lang, navigate }) {
    return Swal.fire({
        customClass: {
            confirmButton: 'confirm-btn-active',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        iconHtml: '؟',
        icon: 'question',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: lang.pwd,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: lang.coAndOrg,
        title: lang.login
    }).then((result) => {
        if (result.isConfirmed) {
            navigate('/PwdLogin')
        }
        if (result.dismiss === 'cancel') {
            navigate('/login')

        }
    })
}

export function RegisterAs({ lang, navigate }) {
    return Swal.fire({
        customClass: {
            confirmButton: 'confirm-btn-active',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        iconHtml: '؟',
        icon: 'question',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: lang.pwd,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: lang.coAndOrg,
        title: lang.register
    }).then((result) => {
        if (result.isConfirmed) {
            navigate('/PwdRegister')
        }
        if (result.dismiss === 'cancel') {
            navigate('/Register')

        }
    })
}

export async function LoginRegister({ lang, navigate }) {
    const result = await Swal.fire({
        customClass: {
            confirmButton: 'confirm-btn-active',
            cancelButton: 'cancel-btn',
            title: 'confirm-title'
        },
        icon: 'info',
        buttonsStyling: false,
        position: 'center',
        confirmButtonText: lang.login,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: lang.register,
        title: lang.notRegisterd,
    })
    if (result.isConfirmed) {
        navigate('/PwdLogin')
    }
    if (result.dismiss === 'cancel') {
        navigate('/PwdRegister')
    }
}
import { request } from './index'
// helpers

export async function CoUploadImgsApi({ file, }, onUploadProgress) {
    let data = new FormData();
    data.append("img", file);
    return await request({ data, url: '/co/api/upload-img', method: 'post', onUploadProgress })
}

export async function CoAvatarApi(file, onUploadProgress) {
    let data = new FormData();
    data.append("img", file.file);
    return await request({ data, url: '/co/api/upload-avatar', method: 'post', onUploadProgress })
}
export function CoProfileUpdate({ data }) {
    request({ data, url: '/co/api/edit-profile', method: 'post' })
}
export async function CoFileUpdateApi({ file, type }) {
    const url = type === 'remove' ? '/co/api/remove-doc' : '/co/api/upload-doc'
    if (type === 'remove') {
        var data = { name: file }
    } else {
        data = new FormData();
        data.append("doc", file);
    }
    return await request({ data, url: url, method: 'post' })
}
export async function CoUploadVideoApi({ file }, onUploadProgress) {
    let data = new FormData();
    data.append("video", file);
    return await request({ data, url: '/co/api/upload-video', method: 'post', onUploadProgress })
}
// organizations 

export function CoOrganizationsApi() {
    return request({ url: '/co/api/organizations', method: 'get' })
}
export function CoLinkOrgApi({ id, initData }) {
    const data = { organizationId: id }
    return request({ url: '/co/api/organizations/link', data, method: 'post', next: initData })
}
export function CoUnLinkOrgApi({ id, initData }) {
    const data = { organizationId: id }
    return request({ url: '/co/api/organizations/unlink', data, method: 'post', next: initData })
}
export function CoRejectOrgApi({ id, initData }) {
    const data = { organizationId: id }
    return request({ url: '/co/api/organizations/reject', data, method: 'post', next: initData })
}

// jobs 
export function AddCoJobApi({ data, tab }) {
    request({ data, url: '/co/api/add-job', method: 'post', next: tab(2) })
}
export function RemoveCoJobApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/co/api/remove-job', method: 'post', next: initData })
}
export function CoJobsApi(type) {
    const url = type === 'terminated' ? '/co/api/terminated-jobs' : '/co/api/jobs'
    return request({ url: url, method: 'get' })
}
export function EditCoJobApi({ data }) {
    request({ data, url: '/co/api/edit-job', method: 'post' })
}
export function TerminateCoJobApi({ id, initData ,terminate}) {
    const data = { id: id }
    const url = terminate ? '/co/api/terminate-job' : 'co/api/activate-job'
    request({ data, url: url, method: 'post', next: initData })
} ///added condition to activate job after termination 

export function ApplicantAcceptApi(data) {
    const url = data.service ? '/co/api/service/accepted' : '/co/api/job/accepted'
    request({ data, url: url, method: 'post' })
}
export function ApplicantSuitableApi(data) {
    const url = data.service ? '/co/api/service/suitable' : '/co/api/job/suitable'

    request({ data, url: url, method: 'post' })
}
export function ApplicantProfileSeenApi(data) {
    const url = data.service ? '/co/api/service/profile-seen' : '/co/api/job/profile-seen'

    return request({ data, url: url, method: 'post', mute: true })
}
// articles
export function AddCoArticleApi({ data, tab }) {
    request({ data, url: '/co/api/add-article', method: 'post', next: tab(3) })
}
export function AddCoVideoApi({ data, tab }) {
    request({ data, url: '/co/api/add-article-video', method: 'post', next: tab(3) })
}
export function EditCoArticleApi({ data, initData }) {
    request({ data, url: '/co/api/edit-article', method: 'post', next: initData })
}

export function RemoveCoArticleApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/co/api/remove-article', method: 'post', next: initData })
}

export function GetCoArticlesApi(id) {
    return request({ url: '/co/api/articles/' + id, method: 'get' })
}
export function coArticleCommentApi(data) {
    return request({ url: '/co/api/article/comment', method: 'post', data, mute: true })
}

// notifications
export function CoNotificationsApi() {
    return request({ url: '/co/api/notifications', method: 'get' })
}
export function CoNSeenApi(id) {
    const data = { notificatonId: id }
    return request({ url: '/co/api/notification-seen', method: 'post', data, mute: true })
}
// settings
export function ResetCoPassowrdApi(data) {
    return request({ url: '/co/api/reset-password', method: 'post', data })
}
// pwd contact 
export function CoSendMessageApi(data) {
    return request({ url: '/co/api/messages/send', method: 'post', data, mute: true })
}
export function CoReplayMessageApi(data) {
    return request({ url: '/co/api/messages/replay', method: 'post', data, mute: true })
}
export function CoMessageApi(data) {
    return request({ url: '/co/api/messages', method: 'post', data, mute: true })
}
export function CoVCallApi(data) {
    return request({ url: '/co/api/messages/call', method: 'post', data, muteSuccess: true })
}
export function CoMsgsContactsApi() {
    return request({ url: '/co/api/messages/contacts', method: 'get', mute: true })
}
export function CoMsgSeenApi(data) {
    return request({ url: '/co/api/message/seen', method: 'post', data, mute: true })
}
export function GetCoMessagesApi() {
    return request({ url: '/co/api/messages', method: 'get', mute: true })
}
// ----------------------------------------------------------------------------


// push notifications
export function coAddNotificaionApi({ data, tab }) {
    const t = data.active ? 3 : 2
    request({ data, url: '/co/api/add-notification', method: 'post', next: tab(t) })
}
export function coGetNotificationsApi(type) {
    const url = type === 'hold' ? '/co/api/hold-notifications' : '/co/api/push-notifications'
    return request({ url: url, method: 'get' })
}
export function coRemoveNotificationApi({ id, initData }) {
    const data = { id: id }
    request({ data, url: '/co/api/remove-notification', method: 'post', next: initData })

}
export function coEditNotificationApi({ data, closeModal }) {
    request({ data, url: '/co/api/edit-notification', method: 'post', next: closeModal })
}
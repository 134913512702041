import { request } from './index'
// helpers
export async function PwdFileUpdateApi({ file, name }) {
    let data = new FormData();
    data.append("file", file);
    data.append('name', name)
    return await request({ data, url: '/pwd/api/upload-file', method: 'post' })
}
export async function PwdUploadImgsApi({ file }, onUploadProgress) {
    let data = new FormData();
    data.append("img", file);
    return await request({ data, url: '/pwd/api/upload-img', method: 'post', onUploadProgress })

}


// profile 

export function PwdProfileApi() {
    return request({ url: '/pwd/api/profile', method: 'get' })
}
export async function pwdAvatarApi(image, onUploadProgress) {
    let data = new FormData();
    data.append("img", image);
    return await request({ data, url: '/pwd/api/upload-avatar', method: 'post', onUploadProgress })
}
export function PwdProfileUpdateApi(data) {
    request({ data, url: '/pwd/api/edit-profile', method: 'post' })
}
// contacts
export function PwdAddContactApi({ data, initData }) {
    request({ data, url: '/pwd/api/add-contact', method: 'post', next: initData })
}
export function PwdEditContactApi({ data, initData }) {
    request({ data, url: '/pwd/api/edit-contact', method: 'post', next: initData })
}
export function PwdRemoveContactApi({ contactId, initData }) {
    request({ data: { contactId }, url: '/pwd/api/remove-contact', method: 'post', next: initData })
}
export function PwdContactsApi() {
    return request({ url: '/pwd/api/contacts', method: 'get' })
}

// disabilites 
export function PwdDisabilitesApi() {
    return request({ url: '/pwd/api/disabilites', method: 'get' })
}
export function PwdAddDisabilitesApi({ data, initData }) {
    request({ data, url: '/pwd/api/add-disability', method: 'post', next: initData })
}
export function PwdEditDisToolsApi(data) {
    request({ data, url: '/pwd/api/edit-disability-tools', method: 'post' })
}
export function PwdEditDisabilityApi({ data, initData }) {
    request({ data, url: '/pwd/api/edit-disability', method: 'post', next: initData })
}
export function PwdRemoveDisabilityApi({ disabilityId, initData }) {
    request({ data: { disabilityId }, url: '/pwd/api/remove-disability', method: 'post', next: initData })
}

// education 
export function PwdEduApi() {
    return request({ url: '/pwd/api/education', method: 'get' })
}
export function PwdAddEduApi({ data, initData }) {
    request({ data, url: '/pwd/api/add-education', method: 'post', next: initData })
}
export function PwdEditEduApi({ data, initData }) {
    request({ data, url: '/pwd/api/edit-education', method: 'post', next: initData })
}

export function PwdRemoveEduApi({ eduId, initData }) {
    request({ data: { eduId }, url: '/pwd/api/remove-education', method: 'post', next: initData })
}

export function PwdPrfileApi(id) {
    const data = { pwdId: id }
    return request({ data, url: '/pwd/profile', method: 'post', mute: true })
}


// tranings
export function PwdAddTrainingApi({ data, initData }) {
    request({ data, url: '/pwd/api/add-training', method: 'post', next: initData })
}
export function PwdEditTrainingApi({ data, initData }) {
    request({ data, url: '/pwd/api/edit-training', method: 'post', next: initData })
}
export function PwdRemoveTrainingApi({ trainingId, initData }) {
    request({ data: { trainingId }, url: '/pwd/api/remove-training', method: 'post', next: initData })
}
export function PwdTrainingsApi() {
    return request({ url: '/pwd/api/trainings', method: 'get' })
}

// Experiences 
export function PwdAddExperienceApi({ data, initData }) {
    request({ data, url: '/pwd/api/add-experience', method: 'post', next: initData })
}
export function PwdEditExperienceApi({ data, initData }) {
    request({ data, url: '/pwd/api/edit-experience', method: 'post', next: initData })
}
export function PwdRemoveExperienceApi({ experienceId, initData }) {
    request({ data: { experienceId }, url: '/pwd/api/remove-experience', method: 'post', next: initData })
}
export function PwdExperiencesApi() {
    return request({ url: '/pwd/api/experiences', method: 'get' })
}

// notifications
export function PwdGetNotificationsApi() { // new // BY DALIA
    return request({ url: '/pwd/api/get-notifications', method: 'get' })
}
export function PwdNotificationsApi() {
    return request({ url: '/pwd/api/notifications', method: 'get' })
}
export function notificationSeenApi(id) {
    const data = { notificatonId: id }
    return request({ url: '/pwd/api/notification-seen', method: 'post', data, mute: true })
}
export function PwdPerNotificationSeenApi(id) { // new // BY DALIA
    const data = { notificatonId: id }
    return request({ url: '/pwd/api/per-notification-seen', method: 'post', data, mute: true})
}

//  jobs

export function PwdJobsApi(status) {
    return request({ url: '/pwd/api/jobs/' + status, method: 'get' })
}
// services
export function PwdServicesApi() {
    return request({ url: '/pwd/api/services', method: 'get' })
}
// settings
export function PwdResetPasswordApi(data) {
    return request({ url: '/pwd/api/reset-password', method: 'post', data })
}

// jobs page
export function JobsApi() {
    return request({ url: '/jobs', method: 'get' })
}

// new by dalia // job detail page
export function JobApi(id) {
    return request({ url: '/jobs/' + id, method: 'get' })
}

// search
export function JobsSearchApi(data) {
    return request({ url: '/jobs/search', method: 'post', data, mute: true })
}
// apply job
export function PwdApplyJobApi(id) {
    const data = { jobId: id }
    return request({ url: '/pwd/api/job-apply', method: 'post', data })
}
// ----------------------------------------------------------------------------

// services  page
export function ServicesApi() {
    return request({ url: '/services', method: 'get' })
}

// new by dalia // service detail page
export function ServiceApi(id) {
    return request({ url: '/services/' + id, method: 'get' })
}

// search
export function ServicesSearchApi(data) {
    return request({ url: '/services/search', method: 'post', data, mute: true })
}

export function PwdApplyServiceApi(id) {
    const data = { ServiceId: id }
    return request({ url: '/pwd/api/service-apply', method: 'post', data })
}
// ----------------------------------------------------------------------------

// organizations page
export function OrganizationsApi() {
    return request({ url: '/organizations', method: 'get' })
}

// new by dalia // organization detail page
export function OrganizationApi(id) {
    return request({ url: '/organizations/' + id, method: 'get' })
}

export function organizationsSearchApi(data) {
    return request({ url: '/organizations/search', method: 'post', data, mute: true })
}
export function OrganizationsJobsApi(id) {
    const data = { id: id }
    return request({ url: '/organizations/jobs', data, method: 'post', mute: true })
}
export function OrganizationsServicesApi(id) {
    const data = { id: id }
    return request({ url: '/organizations/services', data, method: 'post', mute: true })
}
// ----------------------------------------------------------------------------

// companies page
export function CompaniesApi() {
    return request({ url: '/companies', method: 'get' })
}

// new by dalia // company detail page
export function CompanyApi(id) {
    return request({ url: '/companies/' + id, method: 'get' })
}

export function CompaniesSearchApi(data) {
    return request({ url: '/companies/search', method: 'post', data, mute: true })
}
export function CompaniesJobsApi(id) {
    const data = { id: id }
    return request({ url: '/companies/jobs', data, method: 'post', mute: true })
}

// pwd messages 
export function PwdReplayMessageApi(data) {
    return request({ url: '/pwd/api/message/replay', method: 'post', data, mute: true })
}
export function PwdMsgSeenApi(data) {
    return request({ url: '/pwd/api/message/seen', method: 'post', data, mute: true })
}
export function GetPwdMessagesApi() {
    return request({ url: '/pwd/api/messages', method: 'get', mute: true })
}
export function PwdMessagesApi(data) {
    return request({ url: '/pwd/api/messages', method: 'post', data, mute: true })
}
export function PwdMackCallApi(data) {
    return request({ url: '/pwd/api/messages/call', method: 'post', data, muteSuccess: true })
}
// ----------------------------------------------------------------------------


import { useEffect, useState } from "react";
import { faCrosshairs, faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { ArticlesApi, ArticleSearchApi } from "../Api/index";
import { goTop } from ".";
import Article from '../Assets/imgs/default_article.webp'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { NPDWithIconInput } from '../Components';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { articlesSearchValidationSchema } from '../Components/validations';

export function ArticlesSideNav({lang}) {
    const { handleSubmit, control, formState: {errors}, reset, register} = useForm({
        mode: 'all',
        reValidateMode: "onSubmit",
        resolver: yupResolver(articlesSearchValidationSchema)
    });
    const [loading, setLoading] = useState(true)
    const [articles, setArticles] = useState()
    const [title, setTitle] = useState()
    const router = useLocation()
    const navigate = useNavigate()

    async function initData() {
        const values = await ArticlesApi()
        !router.state && setArticles(values)
        setLoading(false)
    }
    useEffect(() => {
        goTop()
        initData()
    }, [])
    
    const Search = async () => {
        setLoading(true)
        setArticles([])
        const data = {
            title
        }
        const results = await ArticleSearchApi(data)
        navigate('/home/articles', { state: { search: results } })
        setLoading(false)
        setTitle('')
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter') Search();
    };

    // update the styles in the form
    const customStyles = {
        form: {
            width: '100%', 
            textAlign:"right"
        },      
        input: {
            height: '45px',
            fontSize: '16px',
            borderBottomRightRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '15px',
            borderTopLeftRadius: '15px'
        },
        inputArea: {
            height: '200px'
        },
        label: {
            color: '#797979',
        },
        button: {
            width: '100%'
        }
    }

    return (
        <Container  >
            <Row className="pt-5" >
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div className="jp_rightside_job_categories_wrapper jp_blog_right_box_search">
                            <div className="jp_rightside_career_heading">
                                <h4>البحث</h4>
                            </div>
                            <div className="jp_blog_right_search_wrapper">
                                <Form onSubmit={handleSubmit(Search)} style={customStyles.form}  onReset={reset} onKeyDown={(e) => checkKeyDown(e)}>
                                    <NPDWithIconInput type="text" controlId="title" inputStyle={customStyles.input} name="title" 
                                        control={control} {...register('title', { required: true })} onChange={(e)=> setTitle(e.target.value)} value={title} 
                                        className={`input-lg form-control ${errors.title ? 'is-invalid' : ''}`}
                                        placeholder={lang.articleName} onKeyDown={(e) => e.key === 'Enter' ? Search : ''}
                                        check={errors && errors.title} errorMessage={errors && errors.title?.message} icon={faCrosshairs}/>
                                    <Button type="submit" disabled={loading} icon={faSearch} style={customStyles.button}> 
                                        <FontAwesomeIcon icon={faSearch} />
                                        {lang.search}
                                    </Button>
                                </Form>    
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div className="jp_rightside_career_wrapper jp_blog_right_box border">
                            <div className="jp_rightside_career_heading">
                                <h4>احدث المقالات</h4>
                            </div>
                                {articles && articles.length > 0 && articles.slice(0, 4).map(article => {
                                    const { ref, img, youtube, video, title, createdAt } = article
                                    const SrcImg = img && img.length > 0 && ref === 'admin' ? `${lang.baseUrl}/admin/media/imgs/${img}` : img && img.length > 0 && ref === 'organization' ? `${lang.baseUrl}/org/media/imgs/${img}` : img && img.length > 0 && ref === 'company' ? `${lang.baseUrl}/co/media/imgs/${img}` : Article
                                
                                return (
                                    <div className="jp_rightside_career_main_content" key={article._id}>
                                        <div className="jp_rightside_career_content_wrapper pb-3 ">
                                            <div className="jp_rightside_career_img px-3" >
                                                <Link to={`/home/articles/${article._id}/${article.title}`} className="text-decoration-none" aria-label={title}>  
                                                    {!youtube && img && img.length > 0 ?  <img loading="lazy" width="80" height="80" src={SrcImg} alt="صورة المقالة"/> : !youtube && <img loading="lazy" width="80" height="80" src={Article} alt="صورة المقالة"/> }
                                                    {!youtube && video && <video src={lang.baseUrl + '/media/videos/' + video} title={title} />}
                                                    {youtube &&
                                                        <iframe loading='lazy' width="80" height="80" alt={'فيديو'} src={youtube} /> 
                                                    }
                                                </Link>
                                            </div>
                                            <div className="jp_rightside_career_img_cont pr-4">
                                                <Link to={`/home/articles/${article._id}/${article.title}`} className="text-decoration-none" aria-label={title}>
                                                    <p className='articles-sidenav-text  text-dark font-weight-bold'>{title}</p>
                                                </Link>
                                                <FontAwesomeIcon icon={faCalendarWeek} color='#ea6969' size="sm" /><span>{moment(createdAt).format('DD-MM-YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="px-3 align-items-center">
                                <Link to="/home/articles" className=" text-decoration-none jp_rightside_career_btn pb-5 font-weight-bold" aria-label={lang.ViewAll}>
                                    <FontAwesomeIcon className="text-primary" icon={faPlusCircle} /> {lang.ViewAll}
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}


///added style in div to center the articles in articles page 
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import { SplachPage, VCallModal } from "../Components";
import {
  OrgLoginApi,
  OrgRegisterApi,
  CoLoginApi,
  CoRegisterApi,
  getOrgUser,
  getPwdUser,
  PwdLoginApi,
  PwdRegisterApi,
  getCoUser,
} from "./index";
import {
  getStatisticsApi,
  MainInfoApi,
  PwdNotificationsApi,
  PwdGetNotificationsApi,
  CoNotificationsApi,
  OrgNotificationsApi,
  getPartnersApi,
} from "../Api";
import io from "socket.io-client";
import {
  CountriesApi,
  DisabilitiesApi,
  EducationsApi,
  JobsInfoApi,
  OrganizationFieldsApi,
  OrganizationTypesApi,
  SkillsApi,
  TrainingsApi,
  WorkfieldsApi,
  CompanyFieldsApi,
} from "./home";
import { GetPwdMessagesApi } from "./pwd";
import { GetOrgMessagesApi } from "./organizations";
import { GetCoMessagesApi } from "./companies";

const AuthContext = createContext();

export function AuthProvider(props) {
  const { children, lang } = props;
  const [user, setUser] = useState(false);

  const [userStatus, setUserStatus] = useState(false); // Initializing state to store the user's profile status //Heba
  const [userType, setUserType] = useState(""); // Initializing state to store the user's profile status //Heba

  const [token, setToken] = useState(false);
  const [error, setError] = useState(false);
  const [socket, setSocket] = useState();
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({
    jobs: 0,
    pwd: 0,
    org: 0,
    co: 0,
  });
  const [mainInfo, setMainInfo] = useState();
  const [partners, setPartners] = useState();
  const [countries, setCountries] = useState([]);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [perNotifications, setPerNotifications] = useState([]); // new // BY DALIA

  // pwd
  const [disTypes, setDisabilites] = useState([]);
  const [pwdEducation, setEdu] = useState([]);
  const [jobsInfo, setJobs] = useState([]);
  const [trainingSectors, setSectors] = useState([]);
  const [skills, setSkills] = useState([]);
  const [orgFields, setFields] = useState([]);
  const [coFields, setCoFields] = useState([]);
  const [messages, setMessages] = useState([]);

  const [orgTypes, setTypes] = useState([]);
  const [workFields, setWorkFields] = useState([]);

  const navigate = useNavigate();
  const NoLoginData = async () => {
    const works = await WorkfieldsApi();
    works.error && navigate("/network-error");
    const sts = await getStatisticsApi();
    const pt = await getPartnersApi();
    const mi = await MainInfoApi();
    const cts = await CountriesApi();
    !sts.error && setStatistics(sts);
    !pt.error && setPartners(pt);
    !mi.error && setMainInfo(mi);
    !cts.error && setCountries(cts);
    !works.error && setWorkFields(works);
    setLoadingInitial(false);
  };
  const LogedInData = async (type) => {
    const cts = await CountriesApi();
    !cts.error && setCountries(cts);
    setDisabilites(await DisabilitiesApi());
    setEdu(await EducationsApi());
    setJobs(await JobsInfoApi());
    setSectors(await TrainingsApi());
    setSkills(await SkillsApi());
    setFields(await OrganizationFieldsApi());
    setTypes(await OrganizationTypesApi());
    setLoadingInitial(false);
    setCoFields(await CompanyFieldsApi());
    updateMsgs(type);
  };
  useEffect(() => {
    if (error) setError(error);
  }, [location.pathname, error]);
  async function updateMsgs(type) {
    type === "pwd" && setMessages(await GetPwdMessagesApi());
    type === "org" && setMessages(await GetOrgMessagesApi());
    type === "co" && setMessages(await GetCoMessagesApi());
  }
  const initOrg = async () => {
    const SavedToken = localStorage.getItem("token") || false;
    if (SavedToken) {
      return await getOrgUser(SavedToken)
        .then((userProfile) => {
          if (userProfile) {
            LogedInData("org");
            setUser(userProfile);
            GetNotifications();
            connectSocket({
              id: userProfile._id,
              token: SavedToken,
              baseUrl: lang.baseUrl,
              type: "org",
            });
            // navigate('/org')
          }
          if (!userProfile) logout();
        })
        .catch((error) => setError(error))
        .finally(() => NoLoginData());
    } else {
      NoLoginData();
      // return navigate('/home', { replace: true })
    }
  };
  const initCo = async () => {
    const SavedToken = localStorage.getItem("token") || false;
    if (SavedToken) {
      return await getCoUser(SavedToken)
        .then((userProfile) => {
          if (userProfile) {
            setUser(userProfile);
            LogedInData("co");
            GetNotifications();
            connectSocket({
              id: userProfile._id,
              token: SavedToken,
              baseUrl: lang.baseUrl,
              type: "co",
            });
            // navigate('/co')
          }
          if (!userProfile) logout();
        })
        .catch((error) => setError(error))
        .finally(() => NoLoginData());
    } else {
      NoLoginData();
      // return navigate('/home', { replace: true })
    }
  };
  const initPwd = async () => {
    const SavedToken = localStorage.getItem("token") || false;
    if (SavedToken) {
      return await getPwdUser(SavedToken)
        .then((userProfile) => {
          if (userProfile) {
            setUser(userProfile);
            LogedInData("pwd");
            connectSocket({
              id: userProfile._id,
              token: SavedToken,
              baseUrl: lang.baseUrl,
              type: "pwd",
            });
            GetNotifications();
            GetPerNotifications(); // new // BY DALIA // returns notifications in pwd from notifications model
            // navigate('/pwd')
          }
          if (!userProfile) logout();
        })
        .catch((error) => setError(error))
        .finally(() => NoLoginData());
    } else {
      NoLoginData();
      // return navigate('/home')
    }
  };

  const GetNotifications = async () => {
    const userType = localStorage.getItem("userType") || false;
    if (userType === "org") setNotifications(await OrgNotificationsApi());
    else if (userType === "co") setNotifications(await CoNotificationsApi());
    else setNotifications(await PwdNotificationsApi());
  };

  // new BY DALIA
  const GetPerNotifications = async () => {
    const userType = localStorage.getItem("userType") || false;
    if (userType === "pwd") setPerNotifications(await PwdGetNotificationsApi());
  };

  useEffect(() => {
    const userType = localStorage.getItem("userType") || false;
    if (userType === "org") initOrg();
    else if (userType === "co") initCo();
    else initPwd();
  }, [token]);
  // This useEffect hook is responsible for fetching and updating the user profile completion status. // Heba

  useEffect(() => {
    // Extracting user id from the user object
    const id = user?._id;
    // Retrieve userType from localStorage
    const userType = localStorage.getItem("userType");
    // console.log(userType);
    // Function to fetch user profile completion status
    const userProfileStatus = async () => {
      // Fetch data only if userType is "pwd"
      if (userType === "pwd") {
        // Sending a POST request to check user profile completeness
        if (id) {
          const { data } = await axios.post(`/jobs/check-profile-complete`, id);
          const { complete } = data;
          setUserType("pwd");
          // Updating userStatus state based on the fetched completion status
          setUserStatus(complete);
        } else {
          setUserStatus(false);
        }
      }
    };
    // Calling userProfileStatus function when the component mounts or when userStatus changes
    userProfileStatus();
  }, [userStatus, navigate, user]); // Dependency array ensuring the effect runs when userStatus and with each navigate  changes

  // sockets
  const connectSocket = ({ token, baseUrl, type, id }) => {
    const socket = io(baseUrl, {
      auth: { token, type },
      path: "/socket",
    });
    setSocket(socket);
    socket.on("notifications", () => {
      GetNotifications();
    });
    // new BY DALIA
    socket.on("perNotifications", () => {
      GetPerNotifications();
    });

    socket.on(id, (data) => {
      data.msg && updateMsgs(type);
      data.call &&
        VCallModal({
          msg: lang.call + data.from,
          cancelText: lang.pwdReject,
          confirmText: lang.answer,
          confirm: () =>
            navigate("/pwd", {
              state: {
                tab: lang.contact,
                call: { roomName: data.roomName, vToken: data.vToken },
              },
            }),
        });
    });
  };
  const orgLogin = useCallback((data) => {
    setLoading(true);
    OrgLoginApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "org" });
          window.location.href = "/org"; ////newww
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const orgSignUp = useCallback((data) => {
    setLoading(true);
    OrgRegisterApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "org" });
          window.location.href = "/org"; ////newww
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const coLogin = useCallback((data) => {
    setLoading(true);
    CoLoginApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "co" });
          window.location.href = "/co"; ///newww
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const coSignUp = useCallback((data) => {
    setLoading(true);
    CoRegisterApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "co" });
          window.location.href = "/co"; ///newww
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const pwdLogin = useCallback((data) => {
    setLoading(true);
    PwdLoginApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "pwd" });
          window.location.href = "/pwd";
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const pwdSignUp = useCallback((data) => {
    setLoading(true);
    PwdRegisterApi(data)
      .then((res) => {
        if (res.success) {
          Login({ token: res.token, type: "pwd" });
          window.location.href = "/pwd"; ///newww
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  function Login({ token, type }) {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("userType", type);
    setLoading(false);
  }

  function logout() {
    setUser(undefined);
    setMessages([]);
    setNotifications([]);
    localStorage.clear();
    navigate("/home");
  }
  function update() {
    initOrg();
  }
  function updateCo() {
    initCo();
  }
  const memoedValue = useMemo(
    () => ({
      user,
      userStatus,
      userType,
      loading,
      error,
      orgLogin,
      orgSignUp,
      coLogin,
      coSignUp,
      pwdLogin,
      pwdSignUp,
      update,
      logout,
      updateCo,
      statistics,
      partners,
      mainInfo,
      notifications,
      GetNotifications,
      perNotifications,
      GetPerNotifications,
      socket,
      countries,
      disTypes,
      pwdEducation,
      jobsInfo,
      trainingSectors,
      skills,
      orgFields,
      orgTypes,
      workFields,
      coFields,
      messages,
      updateMsgs,
    }),
    [
      user,
      userStatus,
      userType,
      loading,
      error,
      update,
      updateCo,
      statistics,
      partners,
      mainInfo,
      logout,
      notifications,
      GetNotifications,
      perNotifications,
      GetPerNotifications,
      socket,
      countries,
      disTypes,
      pwdEducation,
      jobsInfo,
      trainingSectors,
      skills,
      orgFields,
      orgTypes,
      workFields,
      coFields,
      messages,
      updateMsgs,
    ]
  );
  return (
    <AuthContext.Provider value={memoedValue}>
      {loadingInitial ? <SplachPage /> : children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}

import { Pagination, Button } from "react-bootstrap";

export function Pages(props) {
    const { pageSize, maxPages, total, currentPage, setPage, start, end } = props

    let totalPages = Math.ceil(total / pageSize);
    let startPage, endPage
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    start((currentPage - 1) * pageSize)
    end(((currentPage - 1) * pageSize) + pageSize)

    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    const First = () => {
        setPage(1)
    }
    const Prev = () => {
        setPage(currentPage - 1)
    }
    const Next = () => {
        setPage(currentPage + 1)
    }
    const Last = () => {
        setPage(totalPages)
    }
    const selectPage = (page) => {
        setPage(page)
    }
    return (
        <Pagination className='border'   >
            <Pagination.Item className="border-left bg-white" disabled={currentPage === 1} onClick={Prev}><span>السابق</span></Pagination.Item>
            
            {pages.map((page, index) => (
                <Pagination.Item className=" border-left bg-white" key={index} onClick={() => selectPage(page)} active={page === currentPage}>{page}</Pagination.Item>
            ))}
            <Pagination.Ellipsis disabled className=" border-left bg-white" />
            <Pagination.Item active={currentPage === totalPages} className=" border-left bg-white" onClick={Last} >{totalPages}</Pagination.Item>
            <Pagination.Item className="bg-white" disabled={currentPage === totalPages} onClick={Next}><span>التالي</span></Pagination.Item>
        </Pagination>
    )
}
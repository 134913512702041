import { faCheck, faMicrophone, faMicrophoneSlash, faTimes, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './css/messagebox.css'
import VideoCall from '../Assets/vCall'
import { TextRegSub } from '.'
import moment from 'moment'
moment.locale('ar-sa')
export function MessageBox({ data }) {
    const { msg, owner, time, seen } = data
    return (
        owner ? <div className='msg-box-container'>
            <div className='msg-box-body'>
                <div >
                    {msg}
                </div>
                <TextRegSub>
                    {moment(time).format('YYYY/MM/DD - hh:mm a')} <FontAwesomeIcon style={{ marginBottom: '-1' }} size='sm' color={seen ? "#63d09c" : ''} icon={faCheck} /> {seen}
                </TextRegSub>
            </div>
        </div> :
            <div className='msg-box-container'>
                <div className='msg-box-body-sender'>
                    <div >
                        {msg}
                    </div>
                    <TextRegSub>
                        {moment(time).format('YYYY/MM/DD - hh:mm a')}
                    </TextRegSub>
                </div>
            </div>
    )
}

export function CallBox({ pwdVWaiting, room, participants, muteVideo, muteAudio, disconnect, mute }) {
    return (
        <div className='call-box-container'>
            <VideoCall
                key={room.localParticipant.sid}
                participant={room.localParticipant}
            />
            {participants.length < 1 &&
                <div className='centerd-div'>
                    {pwdVWaiting}
                </div>
            }
            {participants.map(pwd =>
                <VideoCall
                    pwd key={pwd.sid} participant={pwd} />
            )}
            <div className='call-controls'>
                <div className='call-mute-s' onClick={muteAudio}>
                    <FontAwesomeIcon size='2x' icon={mute.audio ? faMicrophoneSlash : faMicrophone} />
                </div>
                <div className='call-mute-v' onClick={muteVideo}>
                    <FontAwesomeIcon size='2x' icon={mute.video ? faVideoSlash : faVideo} />
                </div>
                <div className='call-end' onClick={disconnect}>
                    <FontAwesomeIcon size='2x' icon={faTimes} />

                </div>
            </div>
        </div>
    )
}
import './css/text.css'



export function TextHeader(props) {
    const { text, color, align } = props
    const c = color ? color : '#ffffff'
    const Align = align ? align : 'right'
    return (
        <div aria-label={text}  style={{ color: c, textAlign: Align }} className='text-header'>
            {text}
        </div>
    )
}
export function TextLinks(props) {
    const { text, align, active, disabled } = props
    const Align = align ? align : 'right'
    const d = disabled ? '-disabled' : ''
    const Active = active ? 'text-links-active' : 'text-links' + d

    return (
        <div aria-label={text} style={{ textAlign: Align }} className={Active}>
            {text}
        </div>
    )
}
export function TextTitle(props) {
    const { children, align, black, bold } = props
    const a = align ? align : ''
    const c = black ? 'black' : ''
    return (
        <div aria-label={children} style={{ textAlign: a, fontFamily: 'Cairo', fontWeight: bold ? 'bold' : 'normal', color: c }} className='text-title'>
            {children}
        </div>
    )
        //was fontFamily:'Arabic arial,sans-serif'

}
export function TextSubtitle(props) {
    const { children, black, right, color, bold, center } = props
    return (
        <div aria-label={children} className='text-subtitle' style={{ color: black ? 'black' : (color ? color : ''), textAlign: right ? 'right' : (center? 'center':''), fontWeight: bold ? 'bold' : '' }} >
            {children}
        </div>
    )
}
export function TextBody(props) {
    const { children } = props
    return (
        <div aria-label={children} className='text-Body'>
            {children}
        </div>
    )
}
export function TextToolbar(props) {
    const { children, black, center, margin } = props
    const m = margin ? '20px' : ''

    const color = black ? 'black' : 'white'
    const Class = center ? 'text-toolbar-center' : 'text-toolbar'
    const align = center ? 'center' : 'right'
    return (
        <div aria-label={children} style={{ color: color, textAlign: align, marginTop: m, marginBottom: m, }} className={Class}>
            {children}
        </div>
    )
}
export function TextRF(props) {
    const { children } = props
    return (
        <div aria-label={children} className='text-register-footer'>
            {children}
        </div>
    )
}
export function TextRFSub(props) {
    const { children } = props
    return (
        <div aria-label={children} className='text-register-footer-sub'>
            {children}
        </div>
    )
}
export function DashTitle(props) {
    const { children } = props
    return (
        <div aria-label={children} className='text-dashboard-title'>
            {children}
        </div>
    )
}
export function DashSub(props) {
    const { children, sub } = props
    const color = sub ? '#5fc0e9' : '#797979'
    return (
        <div aria-label={children} style={{ color: color }} className='text-dashboard-sub'>
            {children}
        </div>
    )
}
export function TextRegSub(props) {
    const { children, right, size, margin, color, bold ,padding} = props
    const align = right ? 'right' : 'center'
    const m = margin ? '7px' : ''

    return (
        <div aria-label={children} style={{ textAlign: align, fontFamily: 'Cairo',fontSize: size, paddingTop: m, paddingBottom: m, color: color ? color : '', fontWeight: bold ? 'bold' : '',padding:padding }} className='text-register-subtitle'>
            {children}
        </div>
    )
    //was fontFamily:'Arabic arial,sans-serif'
}
export function TextComment(props) {
    const { children } = props
    return (
        <div aria-label={children} className='text-clients-comment'>
            {children}
        </div>
    )
}
export function TextBtn(props) {
    const { children, black } = props

    const Color = black ? 'black' : 'black'
    return (
        <div aria-label={children} style={{ color: Color, }} className='text-btn'>
            {children}
        </div>
    )
}

export function TextCardTitle(props) {
    const { children, color, align, lineHeight, margin,size,padding } = props
    const m = margin ? '7px' : ''

    return (
        <div aria-label={children} style={{ color: color,fontSize: size, textAlign: align, lineHeight: lineHeight, paddingTop: m, paddingBottom: m , padding:padding}} className='text-card-title'>
            {children}
        </div>
    )
}

export function TextCardSubtitle(props) {
    const { children, color, align, margin,padding } = props
    const m = margin ? '3px' : ''
    return (
        <div aria-label={children} style={{ color: color, textAlign: align, paddingTop: m, paddingBottom: m,padding:padding}} className='text-card-subtitle'>
            {children}
        </div>
    )
}

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { DashTitle, DashSub } from './index'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import Org from '../Assets/imgs/organization.webp'
import Company from '../Assets/imgs/company.webp'
import '../Components/css/header.css'

export function Dashboard({ dashboard, lang, }) {
    const location = useLocation();
    const OrgImgSrc = dashboard.avatar ? `${lang.baseUrl}/org/media/imgs/${dashboard.avatar}` :(Org)
    const CoImgSrc =  dashboard.avatar ? `${lang.baseUrl}/co/media/imgs/${dashboard.avatar}` : (Company)

    return (
        dashboard &&
        <Container fluid className='dashboard-container'>
            <Container >
                <Row>
                    <Col lg='12'>
                        <DashTitle>
                            {dashboard.title}
                        </DashTitle>
                    </Col>
                    {dashboard.sub === lang.contactUs || lang.techProblemsAndSuggestionsMessages || dashboard.job || dashboard.service ?
                        <Row className='p-3 '>
                            <Col lg="12">
                                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                    <a href={dashboard.nav} >
                                        <DashSub >
                                            {lang.home}
                                        </DashSub>
                                    </a>
                                    <div className="mx-2 p-0">
                                        <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                                    </div>
                                    <div onClick={dashboard.goBack}>
                                        <DashSub sub>
                                            {dashboard.service ? lang.services : 
                                            dashboard.job ? lang.jobs: 
                                            dashboard.sub === lang.contactUs ? lang.contactUs : lang.techProblemsAndSuggestionsMessages}
                                        </DashSub>
                                    </div>
                                    <div style={{ padding: 5 }}>
                                        <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                                    </div>
                                    <div style={{ padding: 5 }}>
                                        <DashSub sub>
                                            {dashboard.service || dashboard.job}
                                        </DashSub>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        :
                        <>
                            <Col lg="12">
                                <Row className='align-items-center'>
                                    <div>
                                        <a href={dashboard.nav} className="text-decoration-none" >
                                            <h4>
                                                {lang.home}
                                            </h4>
                                        </a>
                                    </div>
                                    <div className="mx-2">
                                        <FontAwesomeIcon color='#5fc0e9' size='1x' icon={faChevronLeft} />
                                    </div>
                                </Row>
                            </Col>

                            <Col lg="12">
                                <div className="overlay-dashboard ">
                                    <Row>

                                        <Col lg="2" className='px-3'>
                                            {location && location.pathname.includes('/companies/')  ?
                                                <img loading="lazy" src={CoImgSrc} className=" h-100 dashboard-image" alt="صورة خلفية للشركة"/>
                                                :
                                                <img loading="lazy" src={OrgImgSrc} className="h-100 dashboard-image" alt="صورة خلفية للمؤسسة"/>
                                            }
                                        </Col>

                                        <Col lg="10" className='px-0'>
                                            <h4 className='pt-3'>
                                                {dashboard.sub}
                                            </h4>
                                            {dashboard.location ?
                                                <p className='pt-3'>
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} size='sm' color='#ff4136' />   {dashboard.location}
                                                </p>
                                                :
                                                <FontAwesomeIcon display="none" />

                                            }
                                        </Col>
                                    </Row>

                                </div>

                            </Col>

                        </>
                    }

                </Row>
            </Container>

        </Container>

    )
}


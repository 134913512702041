import { Row, Col, Container } from 'react-bootstrap'
import './css/tabbar.css'
import { Link } from 'react-router-dom';

export function TabBar({ options, active, tab }) {
    return (
        <div className='tabbar-container'>
            <Container>
                <Row >
                    {options && options.map((data, index) => (
                        <Col key={index} onClick={() => !data.disabled && tab(data.tab)} className={data.tab === active ? 'tabbar-box-active' : 'tabbar-box' + (data.disabled ? '-disabled' : '')}>
                            <div className='tabbar-title'>
                                <Link to="" className='text-muted text-link' aria-label={data.name}>
                                    {data.name}
                                </Link>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}